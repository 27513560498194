import {useState, useEffect} from 'react'
import {useLocation, useHistory} from 'react-router-dom'
import axios from '../../helpers/request'
import './SignUp.css'
import logo from '../images/ProntobyKaptea.svg'
import graphic from '../images/signupgraphic.png'
import showeye from '../images/eyehide.svg'
import vectorc from '../images/eyehide.svg'
import {Link} from 'react-router-dom'
import Select from 'react-select'
import {useAppState} from '../../state'
import or from '../images/or.svg'
import {useGoogleLogin} from '@moeindana/google-oauth'
import Cookies from 'universal-cookie'
import Swal from 'sweetalert2'

const cookies = new Cookies()

function SignUp() {
  const history = useHistory()
  // const navigate = useNavigate();
  const location = useLocation()
  const subscribe = cookies.get('cs_subscribe')

  const validEmail = new RegExp(/\S+@\S+\.\S+/)
  const validPassword = new RegExp(
    // /^([@#](?=[^aeiou]{7,13}$)(?=[[:alnum:]]{7,13}$)(?=.*[A-Z]{1,}.*$).+)$/
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,20}$/
  )

  const services = [
    {label: 'SMS Channel', value: 'sms-channel'},
    {label: 'Email Channel', value: 'email-channel'},
    {label: 'Chat', value: 'chat'},
    {label: 'WhatsApp', value: 'whatsapp'},
    {label: 'Video', value: 'video'}
  ]
  const [values, setvalues] = useState([services[0]])
  const {signIn, isLoggedIn} = useAppState()
  let [user, setUser] = useState({
    fullName: '',
    email: '',
    password: '',
    cpassword: '',
    companyName: '',
    chosenServices: values
  })

  const [error, setError] = useState({
    fullName: '',
    email: '',
    password: '',
    cpassword: '',
    companyName: '',
    chosenServices: ''
  })

  const [shown, setShown] = useState(false)
  const [showncPassword, setShowncPassword] = useState(false)
  const [adding, setAdding] = useState(true)
  const [addError, setaddError] = useState({})
  const [Guser, setGUser] = useState()

  const onchangeselect = e => {
    user.chosenServices = e
    setvalues(e)
  }
  const handleChange = event => {
    const {name, value} = event.target

    setUser(prevUser => ({
      ...prevUser,
      [name]: value
    }))
    isValidData(event)
  }
  const isValidData = event => {
    let value = event.target.value
    let name = event.target.name

    setError(prev => {
      const stateObj = {...prev, [name]: ''}

      switch (name) {
        case 'fullName':
          if (!value) {
            stateObj[name] = 'Please enter Name.'
          }
          break

        case 'email':
          if (!value) {
            stateObj[name] = 'Please enter a valid email address.'
          } else if (!validEmail.test(value)) {
            stateObj[name] = 'Please enter valid email address.'
          }
          break

        case 'companyName':
          if (!value) {
            stateObj[name] = 'Please enter company name.'
          }
          break

        case 'chosenServices':
          if (!values) {
            stateObj[name] = 'Please select your services.'
          }
          break

        case 'password':
          if (!value) {
            stateObj[name] = 'Please enter a password.'
          } else if (!validPassword.test(value)) {
            stateObj[name] =
              'Password should contain atleast uppercase, lowercase, special character,number and limit 8-20 must be required..'
          } else if (user.cpassword && value !== user.cpassword) {
            stateObj['cpassword'] = 'Password and Confirm Password does not match.'
          }

          break

        case 'cpassword':
          if (!value) {
            stateObj[name] = 'Please enter Confirm Password.'
          } else if (user.password && value !== user.password) {
            stateObj[name] = 'Password and Confirm Password does not match.'
          }

          break

        default:
          break
      }
      var a = stateObj
      setaddError(a)
      if (
        addError.cpassword === '' &&
        addError.fullName === '' &&
        addError.email === '' &&
        addError.password === '' &&
        addError.companyName === '' &&
        addError.chosenServices === ''
      ) {
        setAdding(false)
      }
      return stateObj
    })
  }

  const handleSubmit = event => {
    event.preventDefault()
    if (
      addError.cpassword === '' &&
      addError.fullName === '' &&
      addError.email === '' &&
      addError.password === '' &&
      addError.companyName === '' &&
      addError.chosenServices === ''
    ) {
      user = {...user, trial: subscribe?.trial}
      axios
        .post(process.env.REACT_APP_API_BASEURL + '/signup/', {user})
        .then(function (response) {
          setAdding(false)
          setUser('')
          history.push(`/`)
          Swal.fire({
            title: 'Thanks for signing up, please check your email to verify your account and complete registration',
            confirmButtonColor: '#3085d6'
          })
        })
        .catch(function (error) {
          setAdding(false)
          // already exists in our records
          if (error?.response?.data?.message.email && error?.response?.data?.message.companyName) {
            if (
              error?.response?.data?.message.email === user.email &&
              error?.response?.data?.message.companyName === user.companyName
            ) {
              Swal.fire({
                title: ` Email and CompanyName already exists in our records` || 'Unable to process this request',
                confirmButtonColor: '#3085d6'
              })
              return
            } else if (error?.response?.data?.message.email === user.email) {
              Swal.fire({
                title: ` Email already exists in our records` || 'Unable to process this request',
                confirmButtonColor: '#3085d6'
              })
              return
            } else if (Guser) {
              Swal.fire({
                title: ` Please enter CompanyName and select your services `,
                confirmButtonColor: '#3085d6'
              })
              return
            } else {
              Swal.fire({
                title: ` CompanyName already exists in our records ` || 'Unable to process this request',
                confirmButtonColor: '#3085d6'
              })
              return
            }
          } else {
            Swal.fire({
              title: 'Unable to process this request',
              confirmButtonColor: '#3085d6'
            })
            setGUser(null)
          }
        })
    } else {
      history.push(`/plans`)
      // console.log("err");
    }
  }

  const handleCredentialResponse = response => {
    signIn(response).then(response => {
      if (response.success) {
        history.replace(location?.state?.from || {pathname: `/`})
      }
    })
  }

  useEffect(() => {
    /* global google */
    // google.accounts.id.initialize({
    //   client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
    //   callback: handleCredentialResponse,
    // });
    // google.accounts.id.renderButton(document.getElementById("gSigninButton"), {
    //   theme: "outline",
    //   size: "large",
    // });
  }, [])
  const Glogin = useGoogleLogin({
    onSuccess: codeResponse => {
      setUser({
        ...user,
        email: codeResponse.email,
        fullName: codeResponse.name,
        password: codeResponse.id,
        cpassword: codeResponse.id
      })
      setGUser(codeResponse)
    },
    onError: error => console.log('Login Failed:', error)
  })
  // console.log(user, "mydata");
  return (
    <div className="usersignup">
      <div className="userSignupContainerMain">
        <div className="usersignupLeft">
          <span className="logoadmin">
            <div className="logoadminbottom">
              <img src={logo} alt="" className="logo" />
              <img src={graphic} alt="" />
            </div>
          </span>
        </div>
        <div className="userSignupRightMain">
          <div className="pageHeaderSignup">
            <div className="pageHeaderText">
              <span className="pageHeaderTitle">Sign Up</span>
              <span className="pageHeaderDescription">
                To start your trial, create a free account by filling in the form below.
              </span>
            </div>
          </div>
          <form className="signupFormMain" onSubmit={e => handleSubmit(e)}>
            {!Guser && (
              <>
                <div className="signupItem">
                  <label>Full Name</label>

                  <input
                    value={user.fullName}
                    onChange={handleChange}
                    name="fullName"
                    type="text"
                    placeholder="Enter your full name"
                  />
                </div>
                {error.fullName && <span className="err">{error.fullName}</span>}

                <div className="signupItem">
                  <label>Work Email Address</label>
                  <input
                    value={user.email}
                    onChange={handleChange}
                    name="email"
                    type="email"
                    placeholder="Enter your work email address"
                  />
                </div>
                {error.email && <span className="err">{error.email}</span>}
              </>
            )}
            <div className="signupItem">
              <label>Company Name</label>

              <input
                value={user.companyName}
                onChange={handleChange}
                name="companyName"
                type="text"
                placeholder="Enter your company name"
              />
            </div>
            {error.companyName && <span className="err">{error.companyName}</span>}

            <div className="signupItem">
              <label>Select Your Services</label>

              <Select
                defaultValue={values}
                value={values}
                isMulti
                name="chosenServices"
                options={services}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={onchangeselect}
              />
            </div>
            {error.chosenServices && <span className="err">{error.chosenServices}</span>}
            {!Guser && (
              <>
                <div className="signupItem">
                  <label>Password</label>

                  <input
                    value={user.password}
                    onChange={handleChange}
                    name="password"
                    type={shown ? 'text' : 'password'}
                    placeholder="Set your password"
                  />
                  <img className="showpasswordImg" src={showeye} alt="showpassword" onClick={() => setShown(!shown)} />
                </div>
                {error.password && <span className="err">{error.password}</span>}

                <div className="signupItem">
                  <label>Verify Password</label>
                  <input
                    value={user.cpassword}
                    onChange={handleChange}
                    name="cpassword"
                    type={showncPassword ? 'text' : 'password'}
                    placeholder="Retype password"
                  />
                  <img
                    className="verifypasswordImg"
                    src={vectorc}
                    alt="verifypassword"
                    onClick={() => setShowncPassword(!showncPassword)}
                  />
                </div>
                {error.cpassword && <span className="err">{error.cpassword}</span>}
              </>
            )}
            <button disabled={adding} className="signupButtonMain" type="submit">
              Sign Up
            </button>

            <div className="divider">
              <img src={or} alt="" />
            </div>
            {!Guser && (
              <div class="google-btn">
                <div class="google-icon-wrapper">
                  <img class="google-icon" src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg" />
                </div>
                <p class="btn-text" onClick={() => Glogin()}>
                  Sign Up with Google
                </p>
              </div>
            )}

            <Link className="resetoptionsRight" to={'/login'}>
              <button type="button" className="needsanaccount">
                Already have an account? <span>Login</span>
              </button>
            </Link>
          </form>
        </div>
      </div>
    </div>
  )
}

export default SignUp
