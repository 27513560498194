import React, {useState, useRef} from 'react'
import {Input} from 'reactstrap'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
const defaultDateFilterOptions = {
  selectTimeInterval: {
    localIdentifier: 'Select time interval',
    type: '',
    name: '',
    visible: true
  },
  allTime: {
    localIdentifier: 'All Time',
    type: 'All Time',
    name: '',
    visible: true
  },
  thisYear: {
    localIdentifier: 'This Year',
    type: 'thisYear',
    name: '',
    visible: true
  },
  lastYear: {
    localIdentifier: 'Last Year',
    type: 'lastYear',
    name: '',
    visible: true
  },
  thisQuarter: {
    localIdentifier: 'This Quarter',
    type: 'thisQuarter',
    name: '',
    visible: true
  },
  lastQuarter: {
    localIdentifier: 'Last Quarter',
    type: 'lastQuarter',
    name: '',
    visible: true
  },
  last4Quarter: {
    localIdentifier: 'Last 4 Quarter',
    type: 'last4Quarter',
    name: '',
    visible: true
  },
  thisMonth: {
    localIdentifier: 'This Month',
    type: 'thisMonth',
    name: '',
    visible: true
  },
  lastMonth: {
    localIdentifier: 'Last Month',
    type: 'lastMonth',
    name: '',
    visible: true
  },
  last12Months: {
    localIdentifier: 'Last 12 Months',
    type: 'last12Months',
    name: '',
    visible: true
  },
  last7Days: {
    localIdentifier: 'Last 7 Days',
    type: 'last7Days',
    name: '',
    visible: true
  },
  last30Days: {
    localIdentifier: 'Last 30 Days',
    type: 'last30Days',
    name: '',
    visible: true
  },
  last90Days: {
    localIdentifier: 'Last 90 Days',
    type: 'last90Days',
    name: '',
    visible: true
  }
}

export const DateFilterComponent = ({onDateSelect, setStartDate, setEndDate}) => {
  const [fromDate, setFromDate] = useState('')
  const [toDate, setToDate] = useState('')
  const [defaultSelect, setDefaultSelect] = useState('Select time interval')
  const selectRef = useRef(null)

  const handleOtherDateSelect = option => {
    let from,
      to = null
    const today = new Date()
    switch (option.type) {
      case 'All Time':
        from = null
        to = null
        break
      case 'thisYear':
        from = new Date(today.getFullYear(), 0, 1).toISOString().substr(0, 10)
        to = new Date().toISOString().substr(0, 10)
        break
      case 'lastYear':
        from = new Date(today.getFullYear() - 1, 0, 1).toISOString().substr(0, 10)
        to = new Date(today.getFullYear() - 1, 11, 31).toISOString().substr(0, 10)
        break
      case 'thisQuarter':
        const quarterStartMonth = Math.floor(today.getMonth() / 3) * 3
        from = new Date(today.getFullYear(), quarterStartMonth, 1).toISOString().substr(0, 10)
        to = new Date(today.getFullYear(), quarterStartMonth + 3, 0).toISOString().substr(0, 10)
        break
      case 'lastQuarter':
        const lastQuarterStartMonth = (Math.floor(today.getMonth() / 3) - 1) * 3
        from = new Date(today.getFullYear(), lastQuarterStartMonth, 1).toISOString().substr(0, 10)
        to = new Date(today.getFullYear(), lastQuarterStartMonth + 3, 0).toISOString().substr(0, 10)
        break
      case 'last4Quarter':
        const last4QuarterStartMonth = (Math.floor(today.getMonth() / 3) - 3) * 3
        from = new Date(today.getFullYear(), last4QuarterStartMonth, 1).toISOString().substr(0, 10)
        to = new Date(today.getFullYear(), last4QuarterStartMonth + 11, 0).toISOString().substr(0, 10)
        break
      case 'thisMonth':
        from = new Date(today.getFullYear(), today.getMonth(), 1).toISOString().substr(0, 10)
        to = new Date().toISOString().substr(0, 10)
        break
      case 'lastMonth':
        const lastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1)
        from = new Date(lastMonth.getFullYear(), lastMonth.getMonth(), 1).toISOString().substr(0, 10)
        to = new Date(lastMonth.getFullYear(), lastMonth.getMonth() + 1, 0).toISOString().substr(0, 10)
        break
      case 'last12Months':
        from = new Date(today.getFullYear() - 1, today.getMonth() + 1, 1).toISOString().substr(0, 10)
        to = new Date().toISOString().substr(0, 10)
        break
      case 'last7Days':
        from = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000).toISOString().substr(0, 10)
        to = new Date().toISOString().substr(0, 10)
        break
      case 'last30Days':
        from = new Date(today.getTime() - 30 * 24 * 60 * 60 * 1000).toISOString().substr(0, 10)
        to = new Date().toISOString().substr(0, 10)
        break
      case 'last90Days':
        from = new Date(today.getTime() - 90 * 24 * 60 * 60 * 1000).toISOString().substr(0, 10)
        to = new Date().toISOString().substr(0, 10)
        break
      default:
        break
    }
    setStartDate(from)
    setEndDate(to)
    setFromDate(from)
    setToDate(to)
  }

  const handleDateSelect = () => {
    // Apply the date selection when the Apply button is clicked
    onDateSelect(fromDate, toDate)
    setStartDate(fromDate)
    setEndDate(toDate)
    setFromDate('')
    setToDate('')
  }

  return (
    <div className="d-flex justify-content-between align-items-center mb-4">
      <div className="d-flex gap-1 align-items-center">
        <label className="fw-bold w-100">Select Date Range:</label>
        <label className="">From:</label>
        <Input
          className=""
          type="date"
          value={fromDate}
          onChange={e => {
            setDefaultSelect('Select time interval')
            setFromDate(e.target.value)
            setStartDate(e.target.value)
          }}
        />
        <label className="">To:</label>
        <Input
          className=""
          type="date"
          value={toDate}
          onChange={e => {
            setDefaultSelect('Select time interval')
            setToDate(e.target.value)
            setEndDate(e.target.value)
          }}
        />

        <select
          ref={selectRef}
          className="form-select rounded"
          onChange={e => {
            handleOtherDateSelect(defaultDateFilterOptions[e.target.value])
            setDefaultSelect(e.target.value)
          }}
          value={defaultSelect}
        >
          {Object.keys(defaultDateFilterOptions).map(key => (
            <option key={key} value={key}>
              {defaultDateFilterOptions[key].localIdentifier}
            </option>
          ))}
        </select>
        <button
          className="rounded btn btn-primary ms-2"
          style={{color: 'white', height: 'unset', alignSelf: 'stretch'}}
          onClick={handleDateSelect} // Apply the date selection on button click
        >
          Apply
        </button>
      </div>
      <div style={{position: 'relative', width: '300px', marginInline: '10px', borderRadius: '50px', marginRight: '20px'}}>
        <Input type="search" style={{width: '100%', margin: '0'}} placeholder="Search...." />
        <button
          style={{
            position: 'absolute',
            right: '2px',
            top: '25%',
            border: 'none',
            backgroundColor: 'white',
            color: 'gray',
            width: '80px'
          }}
          aria-label="search"
        >
          <SearchOutlinedIcon />
        </button>
      </div>
    </div>
  )
}

export default DateFilterComponent
