import '../../NewContact/newcontact.css'
import React, {useState} from 'react'
import {useLocation} from 'react-router-dom'

import List from './list'
import CreateEdit from './createEdit'

export default function CreateContactListClone() {
  const location = useLocation()
  const isParentUpdatePage = location.pathname.split('/').includes('edit-contactlist-parent')
  const isViewList = location.pathname.split('/').includes('view-contact-list')
  const isEditable = location.pathname.split('/').includes('edit-contact-list')
  const formPage = isEditable ? 'edit-list' : 'create-list'
  let defPage = !isParentUpdatePage ? (!isViewList ? formPage : 'view-list') : 'edit-list-parent'
  const [currentPage, setCurrentPage] = useState(defPage)

  let viewPage = <></>
  switch (currentPage) {
    case 'create-list':
      viewPage = <CreateEdit currentPage={currentPage} setCurrentPage={setCurrentPage} />
      break
    case 'edit-list':
      viewPage = <CreateEdit currentPage={currentPage} setCurrentPage={setCurrentPage} />
      break
    case 'view-list':
      viewPage = <List currentPage={currentPage} setCurrentPage={setCurrentPage} />
      break
    case 'edit-list-parent':
      viewPage = <CreateEdit currentPage={currentPage} setCurrentPage={setCurrentPage} />
      break
    default:
      viewPage = <List currentPage={currentPage} setCurrentPage={setCurrentPage} />
      break
  }

  return <>{viewPage}</>
}
