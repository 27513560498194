import Cookies from 'universal-cookie'

class MyUploadAdapter {
  constructor(loader, user) {
    this.loader = loader
  }

  // Starts the upload process.
  upload() {
    return this.loader.file.then(
      file =>
        new Promise((resolve, reject) => {
          this._initRequest()
          this._initListeners(resolve, reject, file)
          this._sendRequest(file)
        })
    )
  }

  // Aborts the upload process.
  abort() {
    if (this.xhr) {
      this.xhr.abort()
    }
  }

  // Initializes the XMLHttpRequest object using the URL passed to the constructor.
  _initRequest() {
    const cookies = new Cookies()
    const userToken = cookies.get('cs_token')

    const xhr = (this.xhr = new XMLHttpRequest())
    xhr.open('POST', process.env.REACT_APP_API_BASEURL + '/email-image-upload', true)
    xhr.setRequestHeader('Authorization', 'Bearer ' + userToken)
    xhr.setRequestHeader('Accept', 'application/json')
    xhr.responseType = 'json'
  }

  // Initializes XMLHttpRequest listeners.
  _initListeners(resolve, reject, file) {
    const xhr = this.xhr
    const loader = this.loader
    const genericErrorText = `Couldn't upload file: ${file.name}.`

    xhr.addEventListener('error', () => reject(genericErrorText))
    xhr.addEventListener('abort', () => reject())
    xhr.addEventListener('load', () => {
      const response = xhr.response

      if (!response || response.error) {
        return reject(response && response.error ? response.error.message : genericErrorText)
      }

      resolve({
        default: response.url
      })
    })

    if (xhr.upload) {
      xhr.upload.addEventListener('progress', evt => {
        evt.preventDefault()
        if (evt.lengthComputable) {
          loader.uploadTotal = evt.total
          loader.uploaded = evt.loaded
        }
      })
    }
  }

  // Prepares the data and sends the request.
  _sendRequest(file) {
    // Prepare the form data.
    const data = new FormData()
    data.append('upload', file)

    // Send the request.
    this.xhr.send(data)
  }
}

export default function CustomImageUploadAdapter(editor) {
  editor.plugins.get('FileRepository').createUploadAdapter = loader => {
    return new MyUploadAdapter(loader)
  }
}
