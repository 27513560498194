export const monthlyPaymentArray = [
  {
    id: 1,
    name: 'Inflation Buster',
    discount: 17,
    price: 4,
    description: 'Inflation Buster',
    priceId: 'price_1Mj0F6HXJ4qAJI8AN0jodmp6',
    discountColor: '#edf6d0',
    borderColor: '#a9c4c2',
    validity: 'monthly',
    includes: ['eCommernce Store', 'No transaction fees by Pronto'],
    features: ['1 Admin Users', 'Digital Payments', 'Custom Support', 'Business Dashboard', '3 Admin Users']
  },
  {
    id: 2, // subscription id
    name: 'Essential',
    discount: 20,
    price: 9,
    description: 'Start Smart',
    priceId: 'price_1MizgaHXJ4qAJI8AhKK3QKsx',
    discountColor: '#fde2c5',
    borderColor: '#d6ec8d',
    validity: 'monthly',
    includes: ['eCommernce Store', 'Standard chatbots', '1000 Messages Included', 'Additional Messages $0.022'],
    features: [
      '3 Admin Users',
      '1 Mobile Number',
      'Digital Payments',
      'appointments',
      'Messaging Chatbots',
      'Custom Support',
      'Business Dashboard',
      '3 Admin Users'
    ]
  },
  {
    id: 3,
    name: 'Growth',
    discount: 21,
    price: 49,
    description: 'Keep Growing',
    priceId: 'price_1Ml8JHHXJ4qAJI8Agu99Y6aP',
    discountColor: '#d1c9ff',
    borderColor: '#ea9c5e',
    validity: 'monthly',
    includes: ['eCommernce Store', 'Standard chatbots', '1000 Messages Included', 'Additional Messages $0.022'],
    features: [
      '3 Admin Users',
      '1 Mobile Number',
      'Digital Payments',
      'appointments',
      'Messaging Chatbots',
      'Custom Support',
      'Business Dashboard',
      '3 Admin Users'
    ]
  },
  {
    id: 4,
    name: 'Scale',
    discount: 20,
    price: 119,
    description: 'Go Pro',
    priceId: 'price_1Mj0FkHXJ4qAJI8An5LsxJyG',
    discountColor: '#cfebe7',
    borderColor: '#6a56c9',
    validity: 'monthly',
    includes: ['eCommernce Store', 'Standard chatbots', '1000 Messages Included', 'Additional Messages $0.022'],
    features: [
      '3 Admin Users',
      '1 Mobile Number',
      'Digital Payments',
      'appointments',
      'Messaging Chatbots',
      'Custom Support',
      'Business Dashboard',
      '3 Admin Users'
    ]
  },
  {
    id: 5,
    name: 'Inflation Buster',
    discount: 17,
    price: 4 * 12,
    description: 'Inflation Buster',
    priceId: 'price_1Mj0F6HXJ4qAJI8AN0jodmp6',
    discountColor: '#edf6d0',
    borderColor: '#a9c4c2',
    validity: 'yearly',
    includes: ['eCommernce Store', 'No transaction fees by Pronto'],
    features: ['1 Admin Users', 'Digital Payments', 'Custom Support', 'Business Dashboard']
  },
  {
    id: 6,
    name: 'Essential',
    discount: 20,
    price: 9 * 12,
    description: 'Start Smart',
    priceId: 'price_1MizgaHXJ4qAJI8AhKK3QKsx',
    discountColor: '#fde2c5',
    borderColor: '#d6ec8d',
    validity: 'yearly',
    includes: ['eCommernce Store', 'Standard chatbots', '1000 Messages Included', 'Additional Messages $0.022'],
    features: [
      '3 Admin Users',
      '1 Mobile Number',
      'Digital Payments',
      'appointments',
      'Messaging Chatbots',
      'Custom Support',
      'Business Dashboard',
      '3 Admin Users'
    ]
  },
  {
    id: 7,
    name: 'Growth',
    discount: 21,
    price: 49 * 12,
    description: 'Keep Growing',
    priceId: 'price_1Mj0FWHXJ4qAJI8A8tYOanXm',
    discountColor: '#d1c9ff',
    borderColor: '#ea9c5e',
    validity: 'yearly',
    includes: ['eCommernce Store', 'Standard chatbots', '1000 Messages Included', 'Additional Messages $0.022'],
    features: [
      '3 Admin Users',
      '1 Mobile Number',
      'Digital Payments',
      'appointments',
      'Messaging Chatbots',
      'Custom Support',
      'Business Dashboard',
      '3 Admin Users'
    ]
  },
  {
    id: 8,
    name: 'Scale',
    discount: 20,
    price: 119 * 12,
    description: 'Go Pro',
    priceId: 'price_1Mj0FkHXJ4qAJI8An5LsxJyG',
    discountColor: '#cfebe7',
    borderColor: '#6a56c9',
    validity: 'yearly',
    includes: ['eCommernce Store', 'Standard chatbots', '1000 Messages Included', 'Additional Messages $0.022'],
    features: [
      '3 Admin Users',
      '1 Mobile Number',
      'Digital Payments',
      'appointments',
      'Messaging Chatbots',
      'Custom Support',
      'Business Dashboard',
      '3 Admin Users'
    ]
  }
]
