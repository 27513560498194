import {LayersOutlined, KeyboardReturnOutlined} from '@material-ui/icons'
import './campaigns.css'
import {useState, useEffect} from 'react'
import {useParams, useLocation, Link} from 'react-router-dom'
import axios from '../../../helpers/request'
import {Chart as ChartJS, ArcElement, Tooltip, Legend} from 'chart.js'
import {Pie} from 'react-chartjs-2'
import ErrorTable from '../../errorTable/ErrorTable'
// import SentsTable from '../../sentsTable/SentsTable';
import moment from 'moment'
import SentsTable from '../../sentsTable/SentsTable'
import {Breadcrumbs, Typography} from '@material-ui/core'
import Swal from 'sweetalert2'

export default function Campaigns() {
  const {campaignId} = useParams()
  const location = useLocation()
  const [adding, setAdding] = useState(false)

  const [campaign, setCampaign] = useState({
    campaignname: '',
    sentby: '',
    datetimesent: '',
    totalmessages: '',
    totalsent: '',
    campaignid: '',
    totalfails: '',
    smsbody: ''
  })

  const getCampaigns = () => {
    axios.get('/analytic/' + campaignId).then(async response => {
      if (response.status === 200 && response.data) {
        const selectedCampaign = response.data.filter(campaign => campaign.campaignid == campaignId)

        if (selectedCampaign && selectedCampaign[0]) {
          // setTemplate(selectedTemplate[0]);
          setCampaign(prevCampaign => ({
            ...prevCampaign,
            campaignname: selectedCampaign[0].campaignname,
            sentby: selectedCampaign[0].sentby,
            datetimesent: selectedCampaign[0].datetimesent,
            totalmessages: selectedCampaign[0].totalmessages,
            totalsent: selectedCampaign[0].totalsent,
            totalfails: selectedCampaign[0].totalfails,
            campaignid: selectedCampaign[0].campaignid,
            errorCount: selectedCampaign[0].errorCount,
            sentCount: selectedCampaign[0].sentCount,
            totalCount: selectedCampaign[0].totalCount,
            smsbody: selectedCampaign[0].smsbody
          }))
        }
      } else {
        throw new Error(response?.error)
      }
    })
  }

  useEffect(() => {
    getCampaigns()
  }, [])

  const isValidData = () => {
    const {campaignname, sentby, datetimesent, totalmessages, totalsent, totalfails} = campaign

    let isValid = true
    let message = ''

    if (
      campaignname === '' ||
      sentby === '' ||
      datetimesent === '' ||
      totalmessages === '' ||
      totalsent === '' ||
      totalfails === ''
    ) {
      isValid = false
      message = 'All fields are mandatory'
    }
    return {isValid, message}
  }

  const handleSubmit = async event => {
    event.preventDefault()
    const {isValid, message} = isValidData()
    if (isValid) {
      setAdding(true)
      axios
        .put(process.env.REACT_APP_API_BASEURL + '/campaigns/' + campaignId, {
          data: campaign
        })
        .then(async function (response) {
          setAdding(false)
          getCampaigns(campaignId)
          await Swal.fire({title: 'Campaign updated successfully', confirmButtonColor: '#3085d6'})
        })
        .catch(async function (error) {
          setAdding(false)
          await Swal.fire({
            title: error?.response?.data?.message || 'Unable to process this request',
            confirmButtonColor: '#3085d6'
          })
        })
    } else {
      await Swal.fire({title: message, confirmButtonColor: '#3085d6'})
    }
  }

  const handleChange = event => {
    const {name, value} = event.target
    setCampaign(prevCampaign => ({
      ...prevCampaign,
      [name]: value
    }))
  }

  ChartJS.register(ArcElement, Tooltip, Legend)

  const data = {
    labels: ['Sent', 'Failed'],
    datasets: [
      {
        label: '# of Messages',
        data: [campaign.sentCount, campaign.errorCount],
        backgroundColor: ['rgba(97, 184, 91, 1)', 'rgba(220, 67, 76, 1)'],
        borderColor: ['rgba(97, 184, 91, 1)', 'rgba(220, 67, 76, 1)'],
        borderWidth: 1
      }
    ]
  }

  return (
    <div className="campaigns">
      <Breadcrumbs aria-label="breadcrumb">
        <Link to="/analytics">Analytics</Link>
        <Typography color="text.primary">SMS Campaign details</Typography>
      </Breadcrumbs>
      <div className="campaigndetailscontainer">
        <form className="campaignForm">
          <div className="campaignItem">
            <label>
              Campaign Name <span className="mandatory">*</span>
            </label>
            <span className="dataDescriptionSM">{campaign.campaignname}</span>
          </div>
          <div className="campaignItem">
            <label>
              Sent By <span className="mandatory">*</span>
            </label>
            <span className="dataDescriptionSM">{campaign.sentby}</span>
          </div>

          <div className="campaignItem">
            <label>
              Date Sent <span className="mandatory">*</span>
            </label>
            <span className="dataDescriptionSM">{moment(campaign.datetimesent).format('DD/MM/YYYY hh:mm')}</span>
          </div>

          <div className="campaignItem">
            <label>
              Total SMS<span className="mandatory">*</span>
            </label>
            <span className="dataDescriptionLG">{campaign.totalCount}</span>
          </div>

          <div className="campaignItem">
            <label>
              Total Sent<span className="mandatory">*</span>
            </label>
            <span className="dataDescriptionLG">{campaign.sentCount}</span>
          </div>

          <div className="campaignItem">
            <label>
              Total Failed <span className="mandatory">*</span>
            </label>
            <span className="dataDescriptionLG">{campaign.errorCount}</span>
          </div>
          {/* 
        <button disabled={adding} onClick={handleSubmit} className="newUserButton">Update</button>  */}

          <div className="bodycontainer">
            <div className="bodyItem">
              <label>
                SMS Body <span className="mandatory">*</span>
              </label>
              <span className="dataDescriptionSM">{campaign.smsbody}</span>
            </div>
          </div>
        </form>

        <div className="campaigndetailschart">
          <Pie data={data} />
        </div>
      </div>

      <div className="userTables">
        <div className="userTablesTop">
          <ErrorTable campaignid={campaign.campaignid} />
        </div>
        <div className="userTablesBottom">
          <SentsTable campaignid={campaign.campaignid} />
        </div>
      </div>
    </div>
  )
}
