import './channel.css'
import React, {useState, useEffect} from 'react'
import {useParams, useLocation, Link, useHistory} from 'react-router-dom'
import {ScheduleOutlined, KeyboardReturnOutlined} from '@material-ui/icons'
import axios from '../../../helpers/request'

import {useAppState} from '../../../state'
import {Breadcrumbs, Typography} from '@material-ui/core'

import Swal from 'sweetalert2'

const EMAIL_REGEX = new RegExp(/\S+@\S+\.\S+/)

export default function SingleChannel() {
  const history = useHistory()
  const [channelData, setData] = useState({
    sid: '',
    token: '',
    label: '',
    type: '',
    fromdata: ''
  })
  const [phoneNumbers, setPhoneNumbers] = useState([])
  const [messagingServices, setServices] = useState([])

  const {channelId} = useParams()
  const location = useLocation()
  const isEditable = location.pathname.includes('edit')
  console.log(location.pathname, isEditable)
  const getChannelDetails = channelId => {
    axios.get('/channel/' + channelId).then(async response => {
      if (response.status === 200 && response.data && response.data.id) {
        setData(response.data)
        if (response.data.type !== 'sendgrid-mail-service') {
          axios
            .post(
              ['twilio-sms', 'whatsapp', 'twilio-voice'].includes(response.data.type)
                ? 'twilio-account-numbers'
                : 'twilio-account-services',
              {
                data: {
                  twilioSid: response.data.sid,
                  twilioToken: response.data.token
                }
              }
            )
            .then(res => {
              console.log(res.data)
              if (
                ['twilio-sms', 'whatsapp', 'twilio-voice'].includes(response.data.type) &&
                res.data &&
                res.data.incomingPhoneNumbers
              ) {
                setPhoneNumbers(res.data.incomingPhoneNumbers)
              } else if (res.data && res.data.services) {
                setServices(res.data.services)
              }
            })
            .catch(err => {
              Swal.fire({
                title: err?.response?.data?.message || 'Something went wrong fetching numbers!',
                confirmButtonColor: '#3085d6'
              })
              console.log(err)
            })
        }
      } else {
        throw new Error(response?.error)
      }
    })
  }

  useEffect(() => {
    getChannelDetails(channelId)
  }, [channelId])

  return (
    <div>
      <Breadcrumbs aria-label="breadcrumb">
        <Link to="/Channels">Channels</Link>
        <Typography color="text.primary">{!isEditable ? 'View channel' : 'Edit Channel'}</Typography>
      </Breadcrumbs>
      <form className="newUserForm">
        <h2>Authentication Information</h2>
        {channelData.type !== 'sendgrid-mail-service' ? (
          <div className="newUserItem">
            <label>
              Account SID <span className="mandatory">*</span>
            </label>
            <input value={channelData.sid} name="sid" type="text" placeholder="Account SID" />
          </div>
        ) : null}
        <div className="newUserItem">
          <label>
            Auth Token <span className="mandatory">*</span>
          </label>
          <input value={channelData.token} name="token" type="text" placeholder="Auth Token" />
        </div>
        <div className="newUserItem">
          {isEditable ? (
            <button onClick={() => history.push('/channel-edit-auth/' + channelId)} className="newChannelButton align-self-end">
              Update auth details
            </button>
          ) : (
            ''
          )}
        </div>
        <h2>Channel Information</h2>
        <div className="newUserItem">
          <label>
            Label <span className="mandatory">*</span>
          </label>
          <input value={channelData.label} name="label" type="text" placeholder="Label" />
        </div>
        {channelData.type === 'twilio-messaging-service' && (
          <div className="newUserItem">
            <label>
              From data <span className="mandatory">*</span>
            </label>
            <select
              name="fromdata"
              className="popupDropdown"
              value={channelData.fromdata ? channelData.label + ' - ' + channelData.fromdata : ''}
              disabled
            >
              <option disabled selected value="">
                Select messaging service
              </option>
              {messagingServices
                .map(v => ({
                  label: v.friendlyName + ' ( ' + v.sid + ' )',
                  value: v.friendlyName + ' - ' + v.sid
                }))
                .map(channel => {
                  return (
                    <option key={channel.value} value={channel.value}>
                      {channel.label}
                    </option>
                  )
                })}
            </select>
          </div>
        )}
        {['twilio-sms', 'whatsapp', 'twilio-voice'].includes(channelData.type) && (
          <div className="newUserItem">
            <label>
              From data <span className="mandatory">*</span>
            </label>
            <select
              name="fromdata"
              className="popupDropdown"
              value={channelData.fromdata ? channelData.label + ' - ' + channelData.fromdata : ''}
              disabled
            >
              <option disabled selected value="">
                Select Phone number
              </option>
              {phoneNumbers
                .map(v => ({
                  label: v.friendlyName + ' ( ' + v.phoneNumber + ' )',
                  value: v.friendlyName + ' - ' + v.phoneNumber
                }))
                .map(channel => {
                  return (
                    <option key={channel.value} value={channel.value}>
                      {channel.label}
                    </option>
                  )
                })}
            </select>
          </div>
        )}
        {/* {channelData.type === "sendgrid-mail-service" &&
          <div className="newUserItem">
            <label>From email <span className="mandatory">*</span></label>
            <input value={channelData.fromdata} name="fromdata" type="email" placeholder='From email' />
          </div>
        } */}
        {channelData.type === 'whatsapp' && (
          <div className="newUserItem">
            <p>Please complete below additional setup on Twilio's website to link WhatsApp communication with Twilio.</p>
            <ul class="ms-4 mb-4">
              <li>
                Go to, Twilio's&nbsp;
                <a href="https://www.twilio.com/console/sms/whatsapp/senders" target="_blank">
                  WhatsApp Enabled Senders
                </a>
                &nbsp; page.
              </li>
              <li>Click on "Edit Sender" for the number you connected on Twilio.</li>{' '}
              <li>Under "How would you like to configure this sender?", select "Use webhooks".</li>{' '}
              <li>
                Copy and paste below values in the text boxes. Leave the drop-down list values as
                <em>"HTTP Post"</em>.
              </li>
            </ul>
            <div class="form-group">
              <label for="Email-Webhook-url" class="col-form-label">
                Webhook URL for incoming messages
              </label>
              <div className="newUserItem">
                <input
                  name="Email-Webhook-url"
                  type="text"
                  value={process.env.REACT_APP_WHATSAPP_WEBHOOK_URL}
                  readOnly
                  disabled
                />
              </div>
            </div>
          </div>
        )}
        {channelData.type === 'sendgrid-mail-service' && (
          <>
            <div className="newUserItem">
              <p>Please complete below additional setup on Twilio's website to link WhatsApp communication with Twilio.</p>
              <ul class="ms-4 mb-4">
                <li>
                  Go to, Twilio's&nbsp;
                  <a href="https://www.twilio.com/console/sms/whatsapp/senders" target="_blank">
                    WhatsApp Enabled Senders
                  </a>
                  &nbsp; page.
                </li>
                <li>Click on "Edit Sender" for the number you connected on Twilio.</li>{' '}
                <li>Under "How would you like to configure this sender?", select "Use webhooks".</li>{' '}
                <li>
                  Copy and paste below values in the text boxes. Leave the drop-down list values as
                  <em>"HTTP Post"</em>.
                </li>
              </ul>
              <div class="form-group">
                <label for="Email-Webhook-url" class="col-form-label">
                  Webhook URL for incoming messages
                </label>
                <div className="newUserItem">
                  <input name="Email-Webhook-url" type="text" value={process.env.REACT_APP_EMAIL_WEBHOOK_URL} readOnly disabled />
                </div>
              </div>
            </div>
          </>
        )}
        <div className="newUserItem">
          <label>
            Channel Type <span className="mandatory">*</span>
          </label>
          <select name="channel" className="popupDropdown" value={channelData.type} disabled={true}>
            <option disabled selected value="">
              Select channel type
            </option>
            {[
              {
                label: 'SMS with Twilio Messaging Service',
                value: 'twilio-messaging-service'
              },
              {
                label: 'SMS (with Twilio)',
                value: 'twilio-sms'
              },
              {
                label: 'Voice (with Twilio)',
                value: 'twilio-voice'
              },
              {
                label: 'Sendgrid Mail service',
                value: 'sendgrid-mail-service'
              },
              {
                label: 'WhatsApp with Twilio',
                value: 'whatsapp'
              }
            ].map(channel => {
              return (
                <option key={channel.value} value={channel.value}>
                  {channel.label}
                </option>
              )
            })}
          </select>
        </div>
        <div className="newUserItem">
          {isEditable ? (
            <button
              onClick={() => {
                history.push('/channel-details-edit/' + channelId)
              }}
              className="newChannelButton align-self-end"
            >
              Update channel details
            </button>
          ) : (
            ''
          )}
        </div>
      </form>
    </div>
  )
}
