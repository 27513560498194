import {LayersOutlined, KeyboardReturnOutlined, VisibilityOutlined} from '@material-ui/icons'
import {DataGrid, GridToolbarContainer, GridToolbarExport} from '@material-ui/data-grid'
import './emailcampaign.css'
import {useState, useEffect} from 'react'
import {useParams, useLocation, Link} from 'react-router-dom'
import axios from '../../../helpers/request'
import moment from 'moment'
import CustomPopup from '../../Popup/CustomPopup'
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined'

import {Chart as ChartJS, ArcElement, Tooltip, Legend} from 'chart.js'
import {Pie} from 'react-chartjs-2'
import {Breadcrumbs, Typography} from '@material-ui/core'

function CustomToolbar() {
  return (
    <span className="exportbutton">
      <GridToolbarExport />
    </span>
  )
}

export default function EmailCampaign() {
  const {campaignId} = useParams()

  const [campaign, setCampaign] = useState({})
  const [emails, setEmails] = useState({
    data: [],
    total: 0
  })

  const [buttonPopup, setButtonPopup] = useState(false)

  const [messageData, setMessageData] = useState('')

  const [pageSize, setPageSize] = useState(25)
  const [pageNumber, setPageNumber] = useState(1)

  const [successCount, setSuccessCount] = useState(0)
  const [failCount, setFailCount] = useState(0)

  const getCampaign = () => {
    axios.get('/analytic/' + campaignId).then(async response => {
      if (response.status === 200 && response.data && response.data[0]) {
        setCampaign(response.data[0])
      } else {
        throw new Error(response?.error)
      }
    })
  }

  const getMails = () => {
    setEmails({
      data: [],
      total: 0
    })
    let searchParams = {
      page_number: pageNumber,
      page_size: pageSize
    }
    axios.get('/emails/' + campaignId, {params: searchParams}).then(async response => {
      console.log('response', response)
      if (response.status === 200 && response.data) {
        const allMails = response.data
        setEmails(allMails)
        const sCount = allMails.data.filter(mail => mail.status == 'delivered')
        setSuccessCount(sCount.length)
        const fCount = allMails.data.filter(mail => mail.status != 'delivered')
        setFailCount(fCount.length)
      } else {
        throw new Error(response?.error)
      }
    })
  }

  const getMailInfo = messageId => {
    if (messageId) {
      axios.get('/email-details/' + messageId).then(async response => {
        console.log('response', response)
        if (response.status === 200 && response.data) {
          console.log('response.data', response.data)
          setMessageData(response.data)
          setButtonPopup(true)
        } else {
          setMessageData('No details available')
          setButtonPopup(true)
        }
      })
    }
  }

  useEffect(() => {
    getCampaign()
  }, [])

  useEffect(() => {
    getMails()
  }, [pageSize, pageNumber])

  ChartJS.register(ArcElement, Tooltip, Legend)

  const data = {
    labels: ['Delivered', 'Not Delivered'],
    datasets: [
      {
        label: '# of Messages',
        data: [successCount, failCount],
        backgroundColor: ['rgba(97, 184, 91, 1)', 'rgba(220, 67, 76, 1)'],
        borderWidth: 1
      }
    ]
  }

  const emailColumns = [
    {field: 'uniqueid', headerName: 'ID', width: 150, sortable: false},
    {field: 'email', headerName: 'To', width: 250, sortable: false},
    {
      field: 'datetimesent',
      headerName: 'Date Processed',
      width: 170,
      type: 'dateTime',
      valueGetter: ({value}) => value && new Date(value)
    },
    {
      field: 'emailbody',
      headerName: 'Message Body',
      flex: 1,
      minWidth: 300,
      sortable: false,
      renderCell: params => {
        const text = params.row.emailbody.replace(/\n/g, '')
        console.log('text', text)
        return <span className="normal-line-hight" dangerouslySetInnerHTML={{__html: text}}></span>
      }
    },
    // { field: 'sentby', headerName: 'Sent By', width: 140, sortable: false },
    {field: 'status', headerName: 'Status', width: 85, sortable: false},
    {
      field: 'action',
      headerName: 'Action',
      width: 120,
      sortable: false,
      renderCell: params => {
        return (
          <div className="userListActions">
            <button onClick={() => getMailInfo(params.row.messageid)} className="actionElementButton">
              <VisibilityOutlined className="actionElementIcon" />
              <span className="actionButtonText">View Details</span>
            </button>
          </div>
        )
      }
    }
  ]

  return (
    <div className="campaigns">
      <Breadcrumbs aria-label="breadcrumb">
        <Link to="/analytics">Analytics</Link>
        <Typography color="text.primary">Email Campaign details</Typography>
      </Breadcrumbs>
      <div className="campaigndetailscontainer">
        <form className="campaignForm">
          <div className="campaignItem">
            <label>Campaign Name </label>
            <span className="dataDescriptionSM">{campaign.campaignname}</span>
          </div>

          <div className="campaignItem">
            <label>Sent By </label>
            <span className="dataDescriptionSM">{campaign.sentby}</span>
          </div>

          <div className="campaignItem">
            <label>{campaign.scheduled === 1 ? 'Date Scheduled' : 'Date Sent'}</label>
            <span className="dataDescriptionSM">{moment(campaign.datetimesent).format('DD/MM/YYYY hh:mm')}</span>
          </div>

          <div className="campaignItem">
            <label>Total Emails</label>
            <span className="dataDescriptionLG">{campaign.totalmessages}</span>
          </div>

          <div className="bodycontainer">
            <div className="bodyItem">
              <label>Email Body </label>
              <span className="dataDescriptionSM" dangerouslySetInnerHTML={{__html: campaign.smsbody}}></span>

              {/* <span className="dataDescriptionSM">{campaign.smsbody}</span> */}
            </div>
          </div>
        </form>

        <div className="campaigndetailschart">
          <Pie data={data} />
        </div>
      </div>

      <div className="userTables">
        <div className="userTablesTop">
          <DataGrid
            columns={emailColumns}
            rows={emails.data}
            rowCount={emails.total}
            pageSize={pageSize}
            rowsPerPageOptions={[10, 25, 50]}
            onPageSizeChange={newPageSize => setPageSize(newPageSize)}
            onPageChange={newPage => setPageNumber(newPage + 1)}
            pagination
            paginationMode="server"
            checkboxSelection
            disableSelectionOnClick
            autoHeight={true}
            components={{
              ColumnMenuIcon: FilterAltOutlinedIcon,
              Toolbar: CustomToolbar
            }}
          />
        </div>
      </div>

      <CustomPopup trigger={buttonPopup} setTrigger={setButtonPopup}>
        <h3 className="popupTitle">Email Details</h3>
        {messageData && (
          <table>
            {Object.keys(messageData).map(key => {
              return (
                <>
                  {key != 'events' ? (
                    <tr>
                      <td>{key} :</td>
                      <td>{messageData[key]}</td>
                    </tr>
                  ) : (
                    messageData[key].map(event => {
                      return (
                        <tr>
                          {Object.keys(event).map(subKey => {
                            return subKey === 'processed' ? (
                              <td>{moment(event[subKey]).format('DD/MM/YYYY hh:mm')}</td>
                            ) : (
                              <td>{event[subKey]} :</td>
                            )
                          })}
                        </tr>
                      )
                    })
                  )}
                </>
              )
            })}
          </table>
        )}
      </CustomPopup>
    </div>
  )
}
