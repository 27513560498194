// import './importcontacts.css';
import React, {useState} from 'react'
import {Link, useHistory} from 'react-router-dom'
import {Container, Grid} from '@mui/material'
import {Breadcrumbs, Typography} from '@material-ui/core'

import {useAppState} from '../../../state'
import axios from '../../../helpers/request'

import Swal from 'sweetalert2'

export default function PreviewMessage({campaign, closeModal}) {
  console.log('PreviewMessage---campaign', campaign)

  const [previewCampaign, setPreviewCampaign] = useState({email: '', contactNumber: ''})
  const [sending, setSending] = useState(false)
  const {user: loggedUser, setIsLoading} = useAppState()

  const handleChange = event => {
    const {name, value} = event.target

    setPreviewCampaign(prevCampaign => ({
      ...prevCampaign,
      [name]: value
    }))
  }

  const handleSubmit = () => {
    if (campaign.emailChannelId && !previewCampaign.email) {
      Swal.fire({title: 'Please enter email', confirmButtonColor: '#3085d6'})
      return
    }
    if (campaign.smsChannelId && !previewCampaign.contactNumber) {
      Swal.fire({title: 'Please enter phone number', confirmButtonColor: '#3085d6'})
      return
    }
    let previewData = {...campaign, ...previewCampaign}
    console.log('previewData', previewData)
    setSending(true)
    setIsLoading(true)
    axios
      .post('/preview-sms-email', {
        data: previewData,
        actionBy: loggedUser.fullName
      })
      .then(function (response) {
        setIsLoading(false)
        console.log('preview-response', response)

        let message = 'Request processed successfully \n'
        if (campaign.smsChannelId) {
          if (response.data.sms) {
            message = message + '    - ' + 'Preview sms sent successfully \n'
          } else {
            message = message + '    - ' + 'Preview sms failed \n'
          }
        }
        if (campaign.emailChannelId) {
          if (response.data.email) {
            message = message + '    - ' + 'Preview email sent successfully \n'
          } else {
            message = message + '    - ' + 'Preview email failed \n'
          }
        }
        Swal.fire({title: message, confirmButtonColor: '#3085d6'})
        setSending(false)
        closeModal()
      })
      .catch(function (error) {
        setIsLoading(false)
        setSending(false)
        console.log(error)
        Swal.fire({title: 'Unable to process preview request', confirmButtonColor: '#3085d6'})
      })
  }

  return (
    <div>
      {/* <Grid container item xs={12} md={12} lg={12} spacing={2}>
                <div className="ml-15">
                    <h5> Preview Details </h5>
                </div>
            </Grid> */}
      <Grid container>
        {campaign.emailChannelId && (
          <Grid item xs={12}>
            <div className="sendNowItem">
              <label>
                Please enter email <span className="mandatory">*</span>
              </label>
              <input
                value={previewCampaign.email}
                onChange={handleChange}
                name="email"
                type="text"
                placeholder="Enter an email"
                className="nameimage pl-60"
              />
            </div>
          </Grid>
        )}
        {campaign.smsChannelId && (
          <Grid item xs={12}>
            <div className="sendNowItem">
              <label>
                Please enter contact number<span className="mandatory">*</span>
              </label>
              <input
                value={previewCampaign.contactNumber}
                onChange={handleChange}
                name="contactNumber"
                type="text"
                placeholder="Enter contact number"
                className="nameimage pl-60"
              />
            </div>
          </Grid>
        )}
        <Grid item xs={12}>
          <div className="templateButtonContainer flex-row-reverse">
            <button disabled={sending} className="sendNowButton" onClick={handleSubmit}>
              Send Preview Message
            </button>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}
