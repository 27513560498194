import './newtemplate.css'
import {LayersOutlined, KeyboardReturnOutlined} from '@material-ui/icons'
import {useState} from 'react'
import {useAppState} from '../../../state'
import axios from '../../../helpers/request'
import {Link} from 'react-router-dom'

import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'

import Swal from 'sweetalert2'

import {ckeditorConfig} from '../../common/ckeditorConfig'
import CustomImageUploadAdapter from '../../common/ckeditorImageUploadAdaptor'
import {Breadcrumbs, Typography} from '@material-ui/core'

const TEMPLATE_TYPE_SMS_VALUE = 'sms'
const TEMPLATE_TYPE_EMAIL_VALUE = 'email'

export default function NewTemplate() {
  const {user} = useAppState()
  const [adding, setAdding] = useState(false)
  const [template, setTemplate] = useState({
    templatename: '',
    type: TEMPLATE_TYPE_SMS_VALUE,
    templatebody: '',
    updateBy: user.fullName,
    companyId: user.added_by ? user.added_by : user.id
  })
  const [emailEditor, setEmailEditor] = useState('')

  const loadCKEditor = () => {
    if (window && document) {
      let ckEditorDiv = document.getElementsByClassName('ck-editor')
      if (!ckEditorDiv.length) {
        window.CKEDITOR.ClassicEditor.create(document.getElementById('super-ckeditor'), ckeditorConfig)
          .then(editor => {
            window.editor = editor
            setEmailEditor(editor)
          })
          .catch(error => {
            console.log('error of ckeditor create=>', error)
            loadCKEditor()
          })
      }
    }
  }

  if (template.type === 'email') {
    loadCKEditor()
  }

  const isValidData = () => {
    const {templatename, templatebody} = template
    let isValid = true
    let message = ''

    if (templatename === '' || templatebody === '') {
      isValid = false
      message = 'All fields are mandatory'
    }

    return {isValid, message}
  }

  const resetForm = () => {
    setTemplate({
      templatename: '',
      templatebody: '',
      updateBy: user.fullName,
      type: TEMPLATE_TYPE_SMS_VALUE,
      companyId: user.added_by ? user.added_by : user.id
    })
  }

  const handleSubmit = event => {
    event.preventDefault()

    let ckcontent = ''
    if (template.type === 'email') {
      ckcontent = document.getElementsByClassName('ck-content')[0].innerHTML
      let ckcontentLength = document.getElementsByClassName('ck-content')[0].children.length
      if (ckcontentLength) {
        template.templatebody = ckcontent
      } else {
        template.templatebody = ''
      }
    }
    const {isValid, message} = isValidData()
    if (isValid) {
      // form is validate..
      if (template.type === 'email') {
        ckcontent = new DOMParser().parseFromString(ckcontent, 'text/html')
        ckcontent.querySelectorAll('.ck-widget__resizer').forEach(el => el.remove())
        ckcontent.querySelectorAll('.ck-reset_all').forEach(el => el.remove())
        ckcontent.querySelectorAll('.ck-widget__selection-handle').forEach(el => el.remove())
        template.templatebody = ckcontent.documentElement.innerHTML
      }

      setAdding(true)
      axios
        .post('/template', {data: template})
        .then(function (response) {
          if (template.type === 'email') {
            emailEditor.setData('')
            document.getElementsByClassName('ck-editor')[0].remove()
          }
          setAdding(false)
          resetForm()
          Swal.fire({title: 'Template added successfully', confirmButtonColor: '#3085d6'})
        })
        .catch(function (error) {
          setAdding(false)
          Swal.fire({title: error?.response?.data?.message || 'Unable to process this request', confirmButtonColor: '#3085d6'})
        })
    } else {
      // form is not validate so display message
      Swal.fire({title: message, confirmButtonColor: '#3085d6'})
    }
  }

  const handleChange = event => {
    const {name, value} = event.target
    setTemplate(prevTemplate => ({
      ...prevTemplate,
      [name]: value
    }))

    if (name === 'type' && value === 'sms') {
      var ckeditorDiv = document.getElementsByClassName('ck-editor')
      if (ckeditorDiv.length) {
        ckeditorDiv[0].remove()
      }
    }
  }

  return (
    <div className="newtemplate">
      <div className="pageHeader">
        <Breadcrumbs aria-label="breadcrumb">
          <Link to="/templates">Templates</Link>
          <Typography color="text.primary">Add Template</Typography>
        </Breadcrumbs>
      </div>
      <form className="newTemplateForm">
        <div className="newTemplateItem">
          <label>
            Template Name <span className="mandatory">*</span>
          </label>
          <input
            disabled={adding}
            type="text"
            placeholder="Give your template a unique name"
            name="templatename"
            value={template.templatename}
            onChange={handleChange}
          />
        </div>
        <div className="newTemplateItem">
          <label>Template Type </label>
          <RadioGroup
            className="flex-row"
            aria-label="type"
            name="type"
            disabled={adding}
            value={template.type}
            onChange={handleChange}
          >
            <FormControlLabel value={TEMPLATE_TYPE_SMS_VALUE} control={<Radio />} label="SMS" />
            <FormControlLabel value={TEMPLATE_TYPE_EMAIL_VALUE} control={<Radio />} label="Email" />
          </RadioGroup>
        </div>
        {template.type === TEMPLATE_TYPE_EMAIL_VALUE ? (
          <div className="newTemplateItem">
            <label>
              Enter Email Body Text <span className="mandatory">*</span>
            </label>
            <div id="super-ckeditor"></div>
          </div>
        ) : (
          <div className="newTemplateItem">
            <label>
              Enter SMS Body Text <span className="mandatory">*</span>
            </label>
            <textarea
              placeholder="Enter the body of your sms here"
              name="templatebody"
              value={template.templatebody}
              onChange={handleChange}
              disabled={adding}
            ></textarea>
          </div>
        )}
        <div className="newTemplateItem">
          <button disabled={adding} className="newTemplateButton" onClick={handleSubmit}>
            Create
          </button>
        </div>
      </form>
    </div>
  )
}
