import './analytichistory.css'
import {BarChartOutlined, VisibilityOutlined, DeleteOutlined} from '@material-ui/icons'
import {DataGrid, GridToolbarContainer, GridToolbarExport} from '@material-ui/data-grid'
import {useState, useEffect} from 'react'
import axios from '../../../helpers/request'
import {Link} from 'react-router-dom'
import Button from '@material-ui/core/Button'
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined'
import PropTypes from 'prop-types'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

function CustomToolbar() {
  return (
    <span className="exportbutton">
      <GridToolbarExport />
    </span>
  )
}

export default function AnalyticHistory() {
  const [data, setData] = useState({
    data: [],
    total: 0
  })

  const [emailData, setEmailData] = useState({
    data: [],
    total: 0
  })

  const [pageSize, setPageSize] = useState(25)
  const [pageNumber, setPageNumber] = useState(1)
  const [disableExport, setDisableExport] = useState(false)

  const columns = [
    'id',
    'campaignid',
    'campaignname',
    'datetimesent',
    'sentby',
    'processed',
    'delivered',
    'opens',
    'clicks',
    'deferred',
    'dropped',
    'bounced',
    'scheduled'
  ]

  const getAnalytics = () => {
    setData({
      data: [],
      total: 0
    })
    let searchParams = {
      page_number: pageNumber,
      page_size: pageSize
    }
    axios.get('/analytics', {params: searchParams}).then(async response => {
      if (response.status === 200 && response.data) {
        setData(response.data)
      } else {
        throw new Error(response?.error)
      }
    })
  }

  const getEmailAnalytics = () => {
    setEmailData({
      data: [],
      total: 0
    })
    let searchParams = {
      page_number: pageNumber,
      page_size: pageSize
    }
    axios.get('/analytics-history', {params: searchParams}).then(async response => {
      if (response.status === 200 && response.data) {
        setEmailData(response.data)
      } else {
        throw new Error(response?.error)
      }
    })
  }

  useEffect(() => {
    {
      getEmailAnalytics()
    }
  }, [pageSize, pageNumber])

  const [sortModel, setSortModel] = useState([
    {
      field: 'id',
      sort: 'desc'
    }
  ])

  const objectToCsv = function (data) {
    const csvRows = []

    /* Using push() method we push fetched
       data into csvRows[] array */
    csvRows.push(columns.join(','))

    // Loop to get value of each objects key
    for (const row of data) {
      const values = columns.map(header => {
        let val = row[header]
        if (header === 'campaignname') {
          let newVal = val.replace(/,/g, ' ')
          return `${newVal}`
        } else if (['scheduled'].includes(header)) {
          return val ? 'Yes' : 'No'
        } else if (['processed', 'delivered', 'opens', 'clicks', 'deferred', 'dropped', 'bounced'].includes(header)) {
          return val ? val : 0
        } else {
          return `${val}`
        }
      })

      // To add, separator between each value
      csvRows.push(values.join(','))
    }

    /* To add new line for each objects values
       and this return statement array csvRows
       to this function.*/
    return csvRows.join('\n')
  }

  const download = function (data) {
    // Creating a Blob for having a csv file format
    // and passing the data with type
    const blob = new Blob([data], {type: 'text/csv'})
    // Creating an object for downloading url
    const url = window.URL.createObjectURL(blob)
    // Creating an anchor(a) tag of HTML
    const a = document.createElement('a')
    // Passing the blob downloading url
    a.setAttribute('href', url)
    // Setting the anchor tag attribute for downloading
    // and passing the download file name
    a.setAttribute('download', 'gdc_analytics_' + new Date().getTime() + '.csv')
    // Performing a download with click
    a.click()
  }

  const exportAllCampaigns = () => {
    let searchParams = {
      page_number: -1,
      page_size: -1
    }
    setDisableExport(true)
    axios.get('/analytics-history', {params: searchParams}).then(async response => {
      // console.log("response", response);
      if (response.status === 200 && response.data) {
        const content = objectToCsv(response.data)
        download(content)
        setDisableExport(false)
      } else {
        setDisableExport(false)
        throw new Error(response?.error)
      }
    })
  }

  const emailColumns = [
    {field: 'id', headerName: 'ID', width: 80, sortable: false},
    {field: 'campaignname', headerName: 'Campaign Name', minWidth: 200, flex: 0.5, sortable: false},
    // {
    //   field: 'smsbody', headerName: 'Message Body', flex: 1, minWidth: 300, sortable: false,
    //   renderCell: (params) => {
    //     return (
    //       <span className="dataDescriptionSM" dangerouslySetInnerHTML={{ __html: params.row.smsbody }}></span>
    //     )
    //   }
    // },
    {
      field: 'datetimesent',
      headerName: 'Date Sent/Scheduled',
      width: 250,
      type: 'dateTime',
      valueGetter: ({value}) => value && new Date(value)
    },
    {field: 'sentby', headerName: 'Sent By', width: 200, sortable: false},
    {
      field: 'processed',
      headerName: 'Processed',
      width: 120,
      sortable: false,
      valueGetter: ({value}) => Number(value)
    },
    {
      field: 'delivered',
      headerName: 'Delivered',
      width: 120,
      sortable: false,
      valueGetter: ({value}) => Number(value)
    },
    {
      field: 'opens',
      headerName: 'Opened',
      width: 120,
      sortable: false,
      valueGetter: ({value}) => Number(value)
    },
    {
      field: 'clicks',
      headerName: 'Clicked',
      width: 120,
      sortable: false,
      valueGetter: ({value}) => Number(value)
    },
    {
      field: 'deferred',
      headerName: 'Defer',
      width: 120,
      sortable: false,
      valueGetter: ({value}) => Number(value)
    },
    {
      field: 'dropped',
      headerName: 'Drop',
      width: 120,
      sortable: false,
      valueGetter: ({value}) => Number(value)
    },
    {
      field: 'bounced',
      headerName: 'Bounce',
      width: 120,
      sortable: false,
      valueGetter: ({value}) => Number(value)
    },
    {
      field: 'totalfails',
      headerName: 'Fails',
      width: 120,
      sortable: false,
      renderCell: params => Number(params.row.deferred) + Number(params.row.dropped) + Number(params.row.bounced)
    },
    {
      field: 'totalmessages',
      headerName: 'Total',
      width: 120,
      sortable: false,
      valueGetter: ({value}) => Number(value)
    },
    {
      field: 'scheduled',
      headerName: 'Scheduled',
      width: 120,
      sortable: false,
      renderCell: params => {
        return <div className="userListActions">{params.row.scheduled ? 'Yes' : 'No'}</div>
      }
    },
    {
      field: 'action',
      headerName: 'Action',
      width: 100,
      sortable: false,
      renderCell: params => {
        return (
          <div className="userListActions">
            {params.row.campaignid ? (
              <Link to={'/email-history/' + params.row.campaignid}>
                <button className="actionElementButton">
                  <VisibilityOutlined className="actionElementIcon" />
                  <span className="actionButtonText">View</span>
                </button>
              </Link>
            ) : (
              <button className="actionElementButton" disabled={true}>
                <VisibilityOutlined className="actionElementIcon" />
                <span className="actionButtonText">View</span>
              </button>
            )}
          </div>
        )
      }
    }
  ]

  return (
    <div className="analytics">
      <h3 className="pageTableTitleNoButton">Campaigns (History)</h3>
      <DataGrid
        columns={emailColumns}
        rows={emailData.data}
        rowCount={emailData.total}
        pageSize={pageSize}
        rowsPerPageOptions={[10, 25, 50]}
        onPageSizeChange={newPageSize => setPageSize(newPageSize)}
        onPageChange={newPage => setPageNumber(newPage + 1)}
        pagination
        paginationMode="server"
        checkboxSelection
        disableSelectionOnClick
        autoHeight={true}
        components={{
          ColumnMenuIcon: FilterAltOutlinedIcon,
          Toolbar: () => {
            return (
              <div className="tableButtonRow">
                <span className="exportbutton">
                  <Button disabled={disableExport} className="exportbutton_other" color="primary" onClick={exportAllCampaigns}>
                    Export All
                  </Button>
                </span>
              </div>
            )
          }
        }}
      />
    </div>
  )
}
