import {LayersOutlined, KeyboardReturnOutlined, VisibilityOutlined} from '@material-ui/icons'
import {DataGrid, GridToolbarContainer, GridToolbarExport} from '@material-ui/data-grid'
import './campaign.css'
import {useState, useEffect} from 'react'
import {useParams, useLocation, Link} from 'react-router-dom'
import axios from '../../../helpers/request'
import moment from 'moment'
import CustomPopup from '../../Popup/CustomPopup'
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined'
import {Chart as ChartJS, ArcElement, Tooltip, Legend} from 'chart.js'
import {Pie} from 'react-chartjs-2'
import {Breadcrumbs, Typography} from '@material-ui/core'

function CustomToolbar() {
  return (
    <span className="exportbutton">
      <GridToolbarExport />
    </span>
  )
}

export default function WhatsappCampaign() {
  const {campaignId} = useParams()

  const [campaign, setCampaign] = useState({})
  const [wpData, setAllData] = useState([])
  const [emails, setEmails] = useState({
    data: [],
    total: 0
  })

  const [buttonPopup, setButtonPopup] = useState(false)

  const [messageData, setMessageData] = useState('')

  const [pageSize, setPageSize] = useState(25)
  const [pageNumber, setPageNumber] = useState(1)

  const [successCount, setSuccessCount] = useState(0)
  const [failCount, setFailCount] = useState(0)

  const getCampaign = () => {
    axios.get('/analytic/' + campaignId).then(async response => {
      if (response.status === 200 && response.data && response.data[0]) {
        setCampaign(response.data[0])
      } else {
        throw new Error(response?.error)
      }
    })
  }

  const getMails = () => {
    setEmails({
      data: [],
      total: 0
    })
    axios.get('/get-chat-messages/' + campaignId).then(async response => {
      console.log('response', response)
      if (response.status === 200 && response.data) {
        const allMails = response.data
        setAllData(allMails)
        setEmails({
          data: allMails.filter((v, i) => i >= pageSize * (pageNumber - 1) && i < pageSize * pageNumber),
          total: allMails.length
        })
        const sCount = allMails.filter(mail => !(mail.status == 'failed' || mail.status == 'undelivered'))
        setSuccessCount(sCount.length)
        const fCount = allMails.filter(mail => mail.status == 'failed' || mail.status == 'undelivered')
        setFailCount(fCount.length)
      } else {
        throw new Error(response?.error)
      }
    })
  }

  useEffect(() => {
    getCampaign()
    getMails()
  }, [])

  useEffect(() => {
    setEmails({...emails, allMails: wpData.filter((v, i) => i >= pageSize * (pageNumber - 1) && i < pageSize * pageNumber)})
  }, [pageSize, pageNumber])

  ChartJS.register(ArcElement, Tooltip, Legend)

  const data = {
    labels: ['Processed', 'Failed'],
    datasets: [
      {
        label: '# of Messages',
        data: [successCount, failCount],
        backgroundColor: ['rgba(97, 184, 91, 1)', 'rgba(220, 67, 76, 1)'],
        borderWidth: 1
      }
    ]
  }

  const emailColumns = [
    {field: 'id', headerName: 'ID', width: 50, sortable: false},
    {
      field: 'type',
      headerName: 'Type',
      width: 150,
      sortable: false,
      valueGetter: ({value}) => (value == '0' ? 'Outgoing' : 'Incoming')
    },
    {
      field: 'messagetime',
      headerName: 'Date Processed',
      width: 170,
      type: 'dateTime',
      valueGetter: ({value}) => value && new Date(value)
    },
    {
      field: 'messagebody',
      headerName: 'Message Body',
      flex: 1,
      minWidth: 200,
      sortable: false,
      renderCell: params => {
        const text = params.row.messagebody.replace(/\n/g, '')
        console.log('text', text)
        return <span className="normal-line-hight" dangerouslySetInnerHTML={{__html: text}}></span>
      }
    },
    // { field: 'sentby', headerName: 'Sent By', width: 140, sortable: false },
    {field: 'status', headerName: 'Status', width: 120, sortable: false}
  ]

  return (
    <div className="campaigns">
      <Breadcrumbs aria-label="breadcrumb">
        <Link to="/analytics">Analytics</Link>
        <Typography color="text.primary">WhatsApp Campaign details</Typography>
      </Breadcrumbs>
      <div className="campaigndetailscontainer">
        <form className="campaignForm">
          <div className="campaignItem">
            <label>Sent By </label>
            <span className="dataDescriptionSM">{campaign.sentby}</span>
          </div>

          <div className="campaignItem">
            <label>{'Date Started'}</label>
            <span className="dataDescriptionSM">{moment(campaign.datetimesent).format('DD/MM/YYYY hh:mm')}</span>
          </div>

          <div className="campaignItem">
            <label>Total Messages</label>
            <span className="dataDescriptionSM">{emails.total}</span>
          </div>

          <div className="campaignItem">
            <label>Total Customer Number</label>
            <span className="dataDescriptionSM">{campaignId.split('-sends-')[1]}</span>
          </div>

          <div className="campaignItem">
            <label>Total Channel Number</label>
            <span className="dataDescriptionSM">{campaignId.split('-sends-')[0]}</span>
          </div>
        </form>

        <div className="campaigndetailschart">
          <Pie data={data} />
        </div>
      </div>

      <div className="userTables">
        <div className="userTablesTop">
          <DataGrid
            columns={emailColumns}
            rows={emails.data}
            rowCount={emails.total}
            pageSize={pageSize}
            rowsPerPageOptions={[10, 25, 50]}
            onPageSizeChange={newPageSize => setPageSize(newPageSize)}
            onPageChange={newPage => setPageNumber(newPage + 1)}
            pagination
            paginationMode="server"
            checkboxSelection
            disableSelectionOnClick
            autoHeight={true}
            components={{
              ColumnMenuIcon: FilterAltOutlinedIcon,
              Toolbar: CustomToolbar
            }}
          />
        </div>
      </div>
    </div>
  )
}
