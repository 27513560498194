export const alltimeZones = [
  {
    label: 'America/Puerto_Rico',
    name: 'Puerto Rico (Atlantic)',
    group: 'US (Common)'
  },
  {
    label: 'America/New_York',
    name: 'New York (Eastern)',
    group: 'US (Common)'
  },
  {
    label: 'America/Chicago',
    name: 'Chicago (Central)',
    group: 'US (Common)'
  },
  {
    label: 'America/Denver',
    name: 'Denver (Mountain)',
    group: 'US (Common)'
  },
  {
    label: 'America/Phoenix',
    name: 'Phoenix (MST)',
    group: 'US (Common)'
  },
  {
    label: 'America/Los_Angeles',
    name: 'Los Angeles (Pacific)',
    group: 'US (Common)'
  },
  {
    label: 'America/Anchorage',
    name: 'Anchorage (Alaska)',
    group: 'US (Common)'
  },
  {
    label: 'Pacific/Honolulu',
    name: 'Honolulu (Hawaii)',
    group: 'US (Common)'
  },
  {
    label: 'America/Adak',
    name: 'Adak',
    group: 'America'
  },
  {
    label: 'America/Anchorage',
    name: 'Anchorage',
    group: 'America'
  },
  {
    label: 'America/Anguilla',
    name: 'Anguilla',
    group: 'America'
  },
  {
    label: 'America/Antigua',
    name: 'Antigua',
    group: 'America'
  },
  {
    label: 'America/Araguaina',
    name: 'Araguaina',
    group: 'America'
  },
  {
    label: 'America/Argentina/Buenos_Aires',
    name: 'Argentina - Buenos Aires',
    group: 'America'
  },
  {
    label: 'America/Argentina/Catamarca',
    name: 'Argentina - Catamarca',
    group: 'America'
  },
  {
    label: 'America/Argentina/ComodRivadavia',
    name: 'Argentina - ComodRivadavia',
    group: 'America'
  },
  {
    label: 'America/Argentina/Cordoba',
    name: 'Argentina - Cordoba',
    group: 'America'
  },
  {
    label: 'America/Argentina/Jujuy',
    name: 'Argentina - Jujuy',
    group: 'America'
  },
  {
    label: 'America/Argentina/La_Rioja',
    name: 'Argentina - La Rioja',
    group: 'America'
  },
  {
    label: 'America/Argentina/Mendoza',
    name: 'Argentina - Mendoza'
  },
  {
    label: 'America/Argentina/Rio_Gallegos',
    name: 'Argentina - Rio Gallegos',
    group: 'America'
  },
  {
    label: 'America/Argentina/Salta',
    name: 'Argentina - Salta',
    group: 'America'
  },
  {
    label: 'America/Argentina/San_Juan',
    name: 'Argentina - San Juan',
    group: 'America'
  },
  {
    label: 'America/Argentina/San_Luis',
    name: 'Argentina - San Luis',
    group: 'America'
  },
  {
    label: 'America/Argentina/Tucuman',
    name: 'Argentina - Tucuman',
    group: 'America'
  },
  {
    label: 'America/Argentina/Ushuaia',
    name: 'Argentina - Ushuaia',
    group: 'America'
  },
  {
    label: 'America/Aruba',
    name: 'Aruba',
    group: 'America'
  },
  {
    label: 'America/Asuncion',
    name: 'Asuncion',
    group: 'America'
  },
  {
    label: 'America/Atikokan',
    name: 'Atikokan',
    group: 'America'
  },
  {
    label: 'America/Atka',
    name: 'Atka'
  },
  {
    label: 'America/Bahia',
    name: 'Bahia',
    group: 'America'
  },
  {
    label: 'America/Barbados',
    name: 'Barbados',
    group: 'America'
  },
  {
    label: 'America/Belem',
    name: 'Belem',
    group: 'America'
  },
  {
    label: 'America/Belize',
    name: 'Belize',
    group: 'America'
  },
  {
    label: 'America/Blanc-Sablon',
    name: 'Blanc-Sablon',
    group: 'America'
  },
  {
    label: 'America/Boa_Vista',
    name: 'Boa Vista',
    group: 'America'
  },
  {
    label: 'America/Bogota',
    name: 'Bogota',
    group: 'America'
  },
  {
    label: 'America/Boise',
    name: 'Boise',
    group: 'America'
  },
  {
    label: 'America/Buenos_Aires',
    name: 'Buenos Aires',
    group: 'America'
  },
  {
    label: 'America/Cambridge_Bay',
    name: 'Cambridge Bay',
    group: 'America'
  },
  {
    label: 'America/Campo_Grande',
    name: 'Campo Grande',
    group: 'America'
  },
  {
    label: 'America/Cancun',
    name: 'Cancun',
    group: 'America'
  },
  {
    label: 'America/Caracas',
    name: 'Caracas',
    group: 'America'
  },
  {
    label: 'America/Catamarca',
    name: 'Catamarca',
    group: 'America'
  },
  {
    label: 'America/Cayenne',
    name: 'Cayenne',
    group: 'America'
  },
  {
    label: 'America/Cayman',
    name: 'Cayman',
    group: 'America'
  },
  {
    label: 'America/Chicago',
    name: 'Chicago',
    group: 'America'
  },
  {
    label: 'America/Chihuahua',
    name: 'Chihuahua',
    group: 'America'
  },
  {
    label: 'America/Coral_Harbour',
    name: 'Coral Harbour',
    group: 'America'
  },
  {
    label: 'America/Cordoba',
    name: 'Cordoba',
    group: 'America'
  },
  {
    label: 'America/Costa_Rica',
    name: 'Costa Rica'
  },
  {
    label: 'America/Cuiaba',
    name: 'Cuiaba',
    group: 'America'
  },
  {
    label: 'America/Curacao',
    name: 'Curacao',
    group: 'America'
  },
  {
    label: 'America/Danmarkshavn',
    name: 'Danmarkshavn',
    group: 'America'
  },
  {
    label: 'America/Dawson',
    name: 'Dawson',
    group: 'America'
  },
  {
    label: 'America/Dawson_Creek',
    name: 'Dawson Creek',
    group: 'America'
  },
  {
    label: 'America/Denver',
    name: 'Denver',
    group: 'America'
  },
  {
    label: 'America/Detroit',
    name: 'Detroit',
    group: 'America'
  },
  {
    label: 'America/Dominica',
    name: 'Dominica',
    group: 'America'
  },
  {
    label: 'America/Edmonton',
    name: 'Edmonton',
    group: 'America'
  },
  {
    label: 'America/Eirunepe',
    name: 'Eirunepe',
    group: 'America'
  },
  {
    label: 'America/El_Salvador',
    name: 'El Salvador',
    group: 'America'
  },
  {
    label: 'America/Ensenada',
    name: 'Ensenada',
    group: 'America'
  },
  {
    label: 'America/Fortaleza',
    name: 'Fortaleza',
    group: 'America'
  },
  {
    label: 'America/Fort_Wayne',
    name: 'Fort Wayne',
    group: 'America'
  },
  {
    label: 'America/Glace_Bay',
    name: 'Glace Bay',
    group: 'America'
  },
  {
    label: 'America/Godthab',
    name: 'Godthab',
    group: 'America'
  },
  {
    label: 'America/Goose_Bay',
    name: 'Goose Bay',
    group: 'America'
  },
  {
    label: 'America/Grand_Turk',
    name: 'Grand Turk',
    group: 'America'
  },
  {
    label: 'America/Grenada',
    name: 'Grenada',
    group: 'America'
  },
  {
    label: 'America/Guadeloupe',
    name: 'Guadeloupe',
    group: 'America'
  },
  {
    label: 'America/Guatemala',
    name: 'Guatemala',
    group: 'America'
  },
  {
    label: 'America/Guayaquil',
    name: 'Guayaquil',
    group: 'America'
  },
  {
    label: 'America/Guyana',
    name: 'Guyana',
    group: 'America'
  },
  {
    label: 'America/Halifax',
    name: 'Halifax',
    group: 'America'
  },
  {
    label: 'America/Havana',
    name: 'Havana',
    group: 'America'
  },
  {
    label: 'America/Hermosillo',
    name: 'Hermosillo',
    group: 'America'
  },
  {
    label: 'America/Indiana/Indianapolis',
    name: 'Indiana - Indianapolis',
    group: 'America'
  },
  {
    label: 'America/Indiana/Knox',
    name: 'Indiana - Knox',
    group: 'America'
  },
  {
    label: 'America/Indiana/Marengo',
    name: 'Indiana - Marengo',
    group: 'America'
  },
  {
    label: 'America/Indiana/Petersburg',
    name: 'Indiana - Petersburg',
    group: 'America'
  },
  {
    label: 'America/Indiana/Tell_City',
    name: 'Indiana - Tell City',
    group: 'America'
  },
  {
    label: 'America/Indiana/Vevay',
    name: 'Indiana - Vevay',
    group: 'America'
  },
  {
    label: 'America/Indiana/Vincennes',
    name: 'Indiana - Vincennes',
    group: 'America'
  },
  {
    label: 'America/Indiana/Winamac',
    name: 'Indiana - Winamac',
    group: 'America'
  },
  {
    label: 'America/Indianapolis',
    name: 'Indianapolis',
    group: 'America'
  },
  {
    label: 'America/Inuvik',
    name: 'Inuvik',
    group: 'America'
  },
  {
    label: 'America/Iqaluit',
    name: 'Iqaluit',
    group: 'America'
  },
  {
    label: 'America/Jamaica',
    name: 'Jamaica',
    group: 'America'
  },
  {
    label: 'America/Jujuy',
    name: 'Jujuy',
    group: 'America'
  },
  {
    label: 'America/Juneau',
    name: 'Juneau',
    group: 'America'
  },
  {
    label: 'America/Kentucky/Louisville',
    name: 'Kentucky - Louisville',
    group: 'America'
  },
  {
    label: 'America/Kentucky/Monticello',
    name: 'Kentucky - Monticello',
    group: 'America'
  },
  {
    label: 'America/Knox_IN',
    name: 'Knox IN',
    group: 'America'
  },
  {
    label: 'America/La_Paz',
    name: 'La Paz',
    group: 'America'
  },
  {
    label: 'America/Lima',
    name: 'Lima',
    group: 'America'
  },
  {
    label: 'America/Los_Angeles',
    name: 'Los Angeles',
    group: 'America'
  },
  {
    label: 'America/Louisville',
    name: 'Louisville',
    group: 'America'
  },
  {
    label: 'America/Maceio',
    name: 'Maceio',
    group: 'America'
  },
  {
    label: 'America/Managua',
    name: 'Managua',
    group: 'America'
  },
  {
    label: 'America/Manaus',
    name: 'Manaus',
    group: 'America'
  },
  {
    label: 'America/Marigot',
    name: 'Marigot',
    group: 'America'
  },
  {
    label: 'America/Martinique',
    name: 'Martinique',
    group: 'America'
  },
  {
    label: 'America/Matamoros',
    name: 'Matamoros',
    group: 'America'
  },
  {
    label: 'America/Mazatlan',
    name: 'Mazatlan',
    group: 'America'
  },
  {
    label: 'America/Mendoza',
    name: 'Mendoza',
    group: 'America'
  },
  {
    label: 'America/Menominee',
    name: 'Menominee',
    group: 'America'
  },
  {
    label: 'America/Merida',
    name: 'Merida',
    group: 'America'
  },
  {
    label: 'America/Mexico_City',
    name: 'Mexico City',
    group: 'America'
  },
  {
    label: 'America/Miquelon',
    name: 'Miquelon',
    group: 'America'
  },
  {
    label: 'America/Moncton',
    name: 'Moncton',
    group: 'America'
  },
  {
    label: 'America/Monterrey',
    name: 'Monterrey',
    group: 'America'
  },
  {
    label: 'America/Montevideo',
    name: 'Montevideo',
    group: 'America'
  },
  {
    label: 'America/Montreal',
    name: 'Montreal',
    group: 'America'
  },
  {
    label: 'America/Montserrat',
    name: 'Montserrat',
    group: 'America'
  },
  {
    label: 'America/Nassau',
    name: 'Nassau',
    group: 'America'
  },
  {
    label: 'America/New_York',
    name: 'New York',
    group: 'America'
  },
  {
    label: 'America/Nipigon',
    name: 'Nipigon',
    group: 'America'
  },
  {
    label: 'America/Nome',
    name: 'Nome',
    group: 'America'
  },
  {
    label: 'America/Noronha',
    name: 'Noronha',
    group: 'America'
  },
  {
    label: 'America/North_Dakota/Center',
    name: 'North Dakota - Center',
    group: 'America'
  },
  {
    label: 'America/North_Dakota/New_Salem',
    name: 'North Dakota - New Salem',
    group: 'America'
  },
  {
    label: 'America/Ojinaga',
    name: 'Ojinaga',
    group: 'America'
  },
  {
    label: 'America/Panama',
    name: 'Panama',
    group: 'America'
  },
  {
    label: 'America/Pangnirtung',
    name: 'Pangnirtung',
    group: 'America'
  },
  {
    label: 'America/Paramaribo',
    name: 'Paramaribo',
    group: 'America'
  },
  {
    label: 'America/Phoenix',
    name: 'Phoenix',
    group: 'America'
  },
  {
    label: 'America/Port-au-Prince',
    name: 'Port-au-Prince',
    group: 'America'
  },
  {
    label: 'America/Porto_Acre',
    name: 'Porto Acre',
    group: 'America'
  },
  {
    label: 'America/Port_of_Spain',
    name: 'Port of Spain',
    group: 'America'
  },
  {
    label: 'America/Porto_Velho',
    name: 'Porto Velho',
    group: 'America'
  },
  {
    label: 'America/Puerto_Rico',
    name: 'Puerto Rico',
    group: 'America'
  },
  {
    label: 'America/Rainy_River',
    name: 'Rainy River',
    group: 'America'
  },
  {
    label: 'America/Rankin_Inlet',
    name: 'Rankin Inlet',
    group: 'America'
  },
  {
    label: 'America/Recife',
    name: 'Recife',
    group: 'America'
  },
  {
    label: 'America/Regina',
    name: 'Regina',
    group: 'America'
  },
  {
    label: 'America/Resolute',
    name: 'Resolute',
    group: 'America'
  },
  {
    label: 'America/Rio_Branco',
    name: 'Rio Branco',
    group: 'America'
  },
  {
    label: 'America/Rosario',
    name: 'Rosario',
    group: 'America'
  },
  {
    label: 'America/Santa_Isabel',
    name: 'Santa Isabel',
    group: 'America'
  },
  {
    label: 'America/Santarem',
    name: 'Santarem',
    group: 'America'
  },
  {
    label: 'America/Santiago',
    name: 'Santiago',
    group: 'America'
  },
  {
    label: 'America/Santo_Domingo',
    name: 'Santo Domingo',
    group: 'America'
  },
  {
    label: 'America/Sao_Paulo',
    name: 'Sao Paulo',
    group: 'America'
  },
  {
    label: 'America/Scoresbysund',
    name: 'Scoresbysund',
    group: 'America'
  },
  {
    label: 'America/Shiprock',
    name: 'Shiprock',
    group: 'America'
  },
  {
    label: 'America/St_Barthelemy',
    name: 'St Barthelemy',
    group: 'America'
  },
  {
    label: 'America/St_Johns',
    name: 'St Johns',
    group: 'America'
  },
  {
    label: 'America/St_Kitts',
    name: 'St Kitts',
    group: 'America'
  },
  {
    label: 'America/St_Lucia',
    name: 'St Lucia',
    group: 'America'
  },
  {
    label: 'America/St_Thomas',
    name: 'St Thomas',
    group: 'America'
  },
  {
    label: 'America/St_Vincent',
    name: 'St Vincent',
    group: 'America'
  },
  {
    label: 'America/Swift_Current',
    name: 'Swift Current',
    group: 'America'
  },
  {
    label: 'America/Tegucigalpa',
    name: 'Tegucigalpa',
    group: 'America'
  },
  {
    label: 'America/Thule',
    name: 'Thule',
    group: 'America'
  },
  {
    label: 'America/Thunder_Bay',
    name: 'Thunder Bay',
    group: 'America'
  },
  {
    label: 'America/Tijuana',
    name: 'Tijuana',
    group: 'America'
  },
  {
    label: 'America/Toronto',
    name: 'Toronto',
    group: 'America'
  },
  {
    label: 'America/Tortola',
    name: 'Tortola',
    group: 'America'
  },
  {
    label: 'America/Vancouver',
    name: 'Vancouver',
    group: 'America'
  },
  {
    label: 'America/Virgin',
    name: 'Virgin',
    group: 'America'
  },
  {
    label: 'America/Whitehorse',
    name: 'Whitehorse',
    group: 'America'
  },
  {
    label: 'America/Winnipeg',
    name: 'Winnipeg',
    group: 'America'
  },
  {
    label: 'America/Yakutat',
    name: 'Yakutat',
    group: 'America'
  },
  {
    label: 'America/Yellowknife',
    name: 'Yellowknife',
    group: 'America'
  },
  {
    label: 'Europe/Amsterdam',
    name: 'Amsterdam',
    group: 'Europe'
  },
  {
    label: 'Europe/Andorra',
    name: 'Andorra',
    group: 'Europe'
  },
  {
    label: 'Europe/Athens',
    name: 'Athens',
    group: 'Europe'
  },
  {
    label: 'Europe/Belfast',
    name: 'Belfast',
    group: 'Europe'
  },
  {
    label: 'Europe/Belgrade',
    name: 'Belgrade',
    group: 'Europe'
  },
  {
    label: 'Europe/Berlin',
    name: 'Berlin',
    group: 'Europe'
  },
  {
    label: 'Europe/Bratislava',
    name: 'Bratislava',
    group: 'Europe'
  },
  {
    label: 'Europe/Brussels',
    name: 'Brussels',
    group: 'Europe'
  },
  {
    label: 'Europe/Bucharest',
    name: 'Bucharest',
    group: 'Europe'
  },
  {
    label: 'Europe/Budapest',
    name: 'Budapest',
    group: 'Europe'
  },
  {
    label: 'Europe/Chisinau',
    name: 'Chisinau',
    group: 'Europe'
  },
  {
    label: 'Europe/Copenhagen',
    name: 'Copenhagen',
    group: 'Europe'
  },
  {
    label: 'Europe/Dublin',
    name: 'Dublin',
    group: 'Europe'
  },
  {
    label: 'Europe/Gibraltar',
    name: 'Gibraltar',
    group: 'Europe'
  },
  {
    label: 'Europe/Guernsey',
    name: 'Guernsey',
    group: 'Europe'
  },
  {
    label: 'Europe/Helsinki',
    name: 'Helsinki',
    group: 'Europe'
  },
  {
    label: 'Europe/Isle_of_Man',
    name: 'Isle of Man',
    group: 'Europe'
  },
  {
    label: 'Europe/Istanbul',
    name: 'Istanbul',
    group: 'Europe'
  },
  {
    label: 'Europe/Jersey',
    name: 'Jersey',
    group: 'Europe'
  },
  {
    label: 'Europe/Kaliningrad',
    name: 'Kaliningrad',
    group: 'Europe'
  },
  {
    label: 'Europe/Kiev',
    name: 'Kiev',
    group: 'Europe'
  },
  {
    label: 'Europe/Lisbon',
    name: 'Lisbon',
    group: 'Europe'
  },
  {
    label: 'Europe/Ljubljana',
    name: 'Ljubljana',
    group: 'Europe'
  },
  {
    label: 'Europe/London',
    name: 'London',
    group: 'Europe'
  },
  {
    label: 'Europe/Luxembourg',
    name: 'Luxembourg',
    group: 'Europe'
  },
  {
    label: 'Europe/Madrid',
    name: 'Madrid',
    group: 'Europe'
  },
  {
    label: 'Europe/Malta',
    name: 'Malta',
    group: 'Europe'
  },
  {
    label: 'Europe/Mariehamn',
    name: 'Mariehamn',
    group: 'Europe'
  },
  {
    label: 'Europe/Minsk',
    name: 'Minsk',
    group: 'Europe'
  },
  {
    label: 'Europe/Monaco',
    name: 'Monaco',
    group: 'Europe'
  },
  {
    label: 'Europe/Moscow',
    name: 'Moscow',
    group: 'Europe'
  },
  {
    label: 'Europe/Nicosia',
    name: 'Nicosia',
    group: 'Europe'
  },
  {
    label: 'Europe/Oslo',
    name: 'Oslo',
    group: 'Europe'
  },
  {
    label: 'Europe/Paris',
    name: 'Paris',
    group: 'Europe'
  },
  {
    label: 'Europe/Podgorica',
    name: 'Podgorica',
    group: 'Europe'
  },
  {
    label: 'Europe/Prague',
    name: 'Prague',
    group: 'Europe'
  },
  {
    label: 'Europe/Riga',
    name: 'Riga',
    group: 'Europe'
  },
  {
    label: 'Europe/Rome',
    name: 'Rome',
    group: 'Europe'
  },
  {
    label: 'Europe/Samara',
    name: 'Samara',
    group: 'Europe'
  },
  {
    label: 'Europe/San_Marino',
    name: 'San Marino',
    group: 'Europe'
  },
  {
    label: 'Europe/Sarajevo',
    name: 'Sarajevo',
    group: 'Europe'
  },
  {
    label: 'Europe/Simferopol',
    name: 'Simferopol',
    group: 'Europe'
  },
  {
    label: 'Europe/Skopje',
    name: 'Skopje',
    group: 'Europe'
  },
  {
    label: 'Europe/Sofia',
    name: 'Sofia',
    group: 'Europe'
  },
  {
    label: 'Europe/Stockholm',
    name: 'Stockholm',
    group: 'Europe'
  },
  {
    label: 'Europe/Tallinn',
    name: 'Tallinn',
    group: 'Europe'
  },
  {
    label: 'Europe/Tirane',
    name: 'Tirane',
    group: 'Europe'
  },
  {
    label: 'Europe/Tiraspol',
    name: 'Tiraspol',
    group: 'Europe'
  },
  {
    label: 'Europe/Uzhgorod',
    name: 'Uzhgorod',
    group: 'Europe'
  },
  {
    label: 'Europe/Vaduz',
    name: 'Vaduz',
    group: 'Europe'
  },
  {
    label: 'Europe/Vatican',
    name: 'Vatican',
    group: 'Europe'
  },
  {
    label: 'Europe/Vienna',
    name: 'Vienna',
    group: 'Europe'
  },
  {
    label: 'Europe/Vilnius',
    name: 'Vilnius',
    group: 'Europe'
  },
  {
    label: 'Europe/Volgograd',
    name: 'Volgograd',
    group: 'Europe'
  },
  {
    label: 'Europe/Warsaw',
    name: 'Warsaw',
    group: 'Europe'
  },
  {
    label: 'Europe/Zagreb',
    name: 'Zagreb',
    group: 'Europe'
  },
  {
    label: 'Europe/Zaporozhye',
    name: 'Zaporozhye',
    group: 'Europe'
  },
  {
    label: 'Europe/Zurich',
    name: 'Zurich',
    group: 'Europe'
  },
  {
    label: 'Asia/Aden',
    name: 'Aden',
    group: 'Asia'
  },
  {
    label: 'Asia/Almaty',
    name: 'Almaty',
    group: 'Asia'
  },
  {
    label: 'Asia/Amman',
    name: 'Amman',
    group: 'Asia'
  },
  {
    label: 'Asia/Anadyr',
    name: 'Anadyr',
    group: 'Asia'
  },
  {
    label: 'Asia/Aqtau',
    name: 'Aqtau',
    group: 'Asia'
  },
  {
    label: 'Asia/Aqtobe',
    name: 'Aqtobe',
    group: 'Asia'
  },
  {
    label: 'Asia/Ashgabat',
    name: 'Ashgabat',
    group: 'Asia'
  },
  {
    label: 'Asia/Ashkhabad',
    name: 'Ashkhabad',
    group: 'Asia'
  },
  {
    label: 'Asia/Baghdad',
    name: 'Baghdad',
    group: 'Asia'
  },
  {
    label: 'Asia/Bahrain',
    name: 'Bahrain',
    group: 'Asia'
  },
  {
    label: 'Asia/Baku',
    name: 'Baku',
    group: 'Asia'
  },
  {
    label: 'Asia/Bangkok',
    name: 'Bangkok',
    group: 'Asia'
  },
  {
    label: 'Asia/Beirut',
    name: 'Beirut',
    group: 'Asia'
  },
  {
    label: 'Asia/Bishkek',
    name: 'Bishkek',
    group: 'Asia'
  },
  {
    label: 'Asia/Brunei',
    name: 'Brunei',
    group: 'Asia'
  },
  {
    label: 'Asia/Calcutta',
    name: 'Calcutta',
    group: 'Asia'
  },
  {
    label: 'Asia/Choibalsan',
    name: 'Choibalsan',
    group: 'Asia'
  },
  {
    label: 'Asia/Chongqing',
    name: 'Chongqing',
    group: 'Asia'
  },
  {
    label: 'Asia/Chungking',
    name: 'Chungking',
    group: 'Asia'
  },
  {
    label: 'Asia/Colombo',
    name: 'Colombo',
    group: 'Asia'
  },
  {
    label: 'Asia/Dacca',
    name: 'Dacca',
    group: 'Asia'
  },
  {
    label: 'Asia/Damascus',
    name: 'Damascus',
    group: 'Asia'
  },
  {
    label: 'Asia/Dhaka',
    name: 'Dhaka',
    group: 'Asia'
  },
  {
    label: 'Asia/Dili',
    name: 'Dili',
    group: 'Asia'
  },
  {
    label: 'Asia/Dubai',
    name: 'Dubai',
    group: 'Asia'
  },
  {
    label: 'Asia/Dushanbe',
    name: 'Dushanbe',
    group: 'Asia'
  },
  {
    label: 'Asia/Gaza',
    name: 'Gaza',
    group: 'Asia'
  },
  {
    label: 'Asia/Harbin',
    name: 'Harbin',
    group: 'Asia'
  },
  {
    label: 'Asia/Ho_Chi_Minh',
    name: 'Ho Chi Minh',
    group: 'Asia'
  },
  {
    label: 'Asia/Hong_Kong',
    name: 'Hong Kong',
    group: 'Asia'
  },
  {
    label: 'Asia/Hovd',
    name: 'Hovd',
    group: 'Asia'
  },
  {
    label: 'Asia/Irkutsk',
    name: 'Irkutsk',
    group: 'Asia'
  },
  {
    label: 'Asia/Istanbul',
    name: 'Istanbul',
    group: 'Asia'
  },
  {
    label: 'Asia/Jakarta',
    name: 'Jakarta',
    group: 'Asia'
  },
  {
    label: 'Asia/Jayapura',
    name: 'Jayapura',
    group: 'Asia'
  },
  {
    label: 'Asia/Jerusalem',
    name: 'Jerusalem',
    group: 'Asia'
  },
  {
    label: 'Asia/Kabul',
    name: 'Kabul',
    group: 'Asia'
  },
  {
    label: 'Asia/Kamchatka',
    name: 'Kamchatka',
    group: 'Asia'
  },
  {
    label: 'Asia/Karachi',
    name: 'Karachi',
    group: 'Asia'
  },
  {
    label: 'Asia/Kashgar',
    name: 'Kashgar',
    group: 'Asia'
  },
  {
    label: 'Asia/Kathmandu',
    name: 'Kathmandu',
    group: 'Asia'
  },
  {
    label: 'Asia/Katmandu',
    name: 'Katmandu',
    group: 'Asia'
  },
  {
    label: 'Asia/Kolkata',
    name: 'Kolkata',
    group: 'Asia'
  },
  {
    label: 'Asia/Krasnoyarsk',
    name: 'Krasnoyarsk',
    group: 'Asia'
  },
  {
    label: 'Asia/Kuala_Lumpur',
    name: 'Kuala Lumpur',
    group: 'Asia'
  },
  {
    label: 'Asia/Kuching',
    name: 'Kuching',
    group: 'Asia'
  },
  {
    label: 'Asia/Kuwait',
    name: 'Kuwait',
    group: 'Asia'
  },
  {
    label: 'Asia/Macao',
    name: 'Macao',
    group: 'Asia'
  },
  {
    label: 'Asia/Macau',
    name: 'Macau',
    group: 'Asia'
  },
  {
    label: 'Asia/Magadan',
    name: 'Magadan',
    group: 'Asia'
  },
  {
    label: 'Asia/Makassar',
    name: 'Makassar',
    group: 'Asia'
  },
  {
    label: 'Asia/Manila',
    name: 'Manila',
    group: 'Asia'
  },
  {
    label: 'Asia/Muscat',
    name: 'Muscat',
    group: 'Asia'
  },
  {
    label: 'Asia/Nicosia',
    name: 'Nicosia',
    group: 'Asia'
  },
  {
    label: 'Asia/Novokuznetsk',
    name: 'Novokuznetsk',
    group: 'Asia'
  },
  {
    label: 'Asia/Novosibirsk',
    name: 'Novosibirsk',
    group: 'Asia'
  },
  {
    label: 'Asia/Omsk',
    name: 'Omsk',
    group: 'Asia'
  },
  {
    label: 'Asia/Oral',
    name: 'Oral',
    group: 'Asia'
  },
  {
    label: 'Asia/Phnom_Penh',
    name: 'Phnom Penh',
    group: 'Asia'
  },
  {
    label: 'Asia/Pontianak',
    name: 'Pontianak',
    group: 'Asia'
  },
  {
    label: 'Asia/Pyongyang',
    name: 'Pyongyang',
    group: 'Asia'
  },
  {
    label: 'Asia/Qatar',
    name: 'Qatar',
    group: 'Asia'
  },
  {
    label: 'Asia/Qyzylorda',
    name: 'Qyzylorda',
    group: 'Asia'
  },
  {
    label: 'Asia/Rangoon',
    name: 'Rangoon',
    group: 'Asia'
  },
  {
    label: 'Asia/Riyadh',
    name: 'Riyadh',
    group: 'Asia'
  },
  {
    label: 'Asia/Saigon',
    name: 'Saigon',
    group: 'Asia'
  },
  {
    label: 'Asia/Sakhalin',
    name: 'Sakhalin',
    group: 'Asia'
  },
  {
    label: 'Asia/Samarkand',
    name: 'Samarkand',
    group: 'Asia'
  },
  {
    label: 'Asia/Seoul',
    name: 'Seoul',
    group: 'Asia'
  },
  {
    label: 'Asia/Shanghai',
    name: 'Shanghai',
    group: 'Asia'
  },
  {
    label: 'Asia/Singapore',
    name: 'Singapore',
    group: 'Asia'
  },
  {
    label: 'Asia/Taipei',
    name: 'Taipei',
    group: 'Asia'
  },
  {
    label: 'Asia/Tashkent',
    name: 'Tashkent',
    group: 'Asia'
  },
  {
    label: 'Asia/Tbilisi',
    name: 'Tbilisi',
    group: 'Asia'
  },
  {
    label: 'Asia/Tehran',
    name: 'Tehran',
    group: 'Asia'
  },
  {
    label: 'Asia/Tel_Aviv',
    name: 'Tel Aviv',
    group: 'Asia'
  },
  {
    label: 'Asia/Thimbu',
    name: 'Thimbu',
    group: 'Asia'
  },
  {
    label: 'Asia/Thimphu',
    name: 'Thimphu',
    group: 'Asia'
  },
  {
    label: 'Asia/Tokyo',
    name: 'Tokyo',
    group: 'Asia'
  },
  {
    label: 'Asia/Ujung_Pandang',
    name: 'Ujung Pandang',
    group: 'Asia'
  },
  {
    label: 'Asia/Ulaanbaatar',
    name: 'Ulaanbaatar',
    group: 'Asia'
  },
  {
    label: 'Asia/Ulan_Bator',
    name: 'Ulan Bator',
    group: 'Asia'
  },
  {
    label: 'Asia/Urumqi',
    name: 'Urumqi',
    group: 'Asia'
  },
  {
    label: 'Asia/Vientiane',
    name: 'Vientiane',
    group: 'Asia'
  },
  {
    label: 'Asia/Vladivostok',
    name: 'Vladivostok',
    group: 'Asia'
  },
  {
    label: 'Asia/Yakutsk',
    name: 'Yakutsk',
    group: 'Asia'
  },
  {
    label: 'Asia/Yekaterinburg',
    name: 'Yekaterinburg',
    group: 'Asia'
  },
  {
    label: 'Asia/Yerevan',
    name: 'Yerevan',
    group: 'Asia'
  },
  {
    label: 'Africa/Abidjan',
    name: 'Abidjan',
    group: 'Africa'
  },
  {
    label: 'Africa/Accra',
    name: 'Accra',
    group: 'Africa'
  },
  {
    label: 'Africa/Addis_Ababa',
    name: 'Addis Ababa',
    group: 'Africa'
  },
  {
    label: 'Africa/Algiers',
    name: 'Algiers',
    group: 'Africa'
  },
  {
    label: 'Africa/Asmara',
    name: 'Asmara',
    group: 'Africa'
  },
  {
    label: 'Africa/Asmera',
    name: 'Asmera',
    group: 'Africa'
  },
  {
    label: 'Africa/Bamako',
    name: 'Bamako',
    group: 'Africa'
  },
  {
    label: 'Africa/Bangui',
    name: 'Bangui',
    group: 'Africa'
  },
  {
    label: 'Africa/Banjul',
    name: 'Banjul',
    group: 'Africa'
  },
  {
    label: 'Africa/Bissau',
    name: 'Bissau',
    group: 'Africa'
  },
  {
    label: 'Africa/Blantyre',
    name: 'Blantyre',
    group: 'Africa'
  },
  {
    label: 'Africa/Brazzaville',
    name: 'Brazzaville',
    group: 'Africa'
  },
  {
    label: 'Africa/Bujumbura',
    name: 'Bujumbura',
    group: 'Africa'
  },
  {
    label: 'Africa/Cairo',
    name: 'Cairo',
    group: 'Africa'
  },
  {
    label: 'Africa/Casablanca',
    name: 'Casablanca',
    group: 'Africa'
  },
  {
    label: 'Africa/Ceuta',
    name: 'Ceuta',
    group: 'Africa'
  },
  {
    label: 'Africa/Conakry',
    name: 'Conakry',
    group: 'Africa'
  },
  {
    label: 'Africa/Dakar',
    name: 'Dakar',
    group: 'Africa'
  },
  {
    label: 'Africa/Dar_es_Salaam',
    name: 'Dar es Salaam',
    group: 'Africa'
  },
  {
    label: 'Africa/Djibouti',
    name: 'Djibouti',
    group: 'Africa'
  },
  {
    label: 'Africa/Douala',
    name: 'Douala',
    group: 'Africa'
  },
  {
    label: 'Africa/El_Aaiun',
    name: 'El Aaiun',
    group: 'Africa'
  },
  {
    label: 'Africa/Freetown',
    name: 'Freetown',
    group: 'Africa'
  },
  {
    label: 'Africa/Gaborone',
    name: 'Gaborone',
    group: 'Africa'
  },
  {
    label: 'Africa/Harare',
    name: 'Harare',
    group: 'Africa'
  },
  {
    label: 'Africa/Johannesburg',
    name: 'Johannesburg',
    group: 'Africa'
  },
  {
    label: 'Africa/Kampala',
    name: 'Kampala',
    group: 'Africa'
  },
  {
    label: 'Africa/Khartoum',
    name: 'Khartoum',
    group: 'Africa'
  },
  {
    label: 'Africa/Kigali',
    name: 'Kigali',
    group: 'Africa'
  },
  {
    label: 'Africa/Kinshasa',
    name: 'Kinshasa',
    group: 'Africa'
  },
  {
    label: 'Africa/Lagos',
    name: 'Lagos',
    group: 'Africa'
  },
  {
    label: 'Africa/Libreville',
    name: 'Libreville',
    group: 'Africa'
  },
  {
    label: 'Africa/Lome',
    name: 'Lome',
    group: 'Africa'
  },
  {
    label: 'Africa/Luanda',
    name: 'Luanda',
    group: 'Africa'
  },
  {
    label: 'Africa/Lubumbashi',
    name: 'Lubumbashi',
    group: 'Africa'
  },
  {
    label: 'Africa/Lusaka',
    name: 'Lusaka',
    group: 'Africa'
  },
  {
    label: 'Africa/Malabo',
    name: 'Malabo',
    group: 'Africa'
  },
  {
    label: 'Africa/Maputo',
    name: 'Maputo',
    group: 'Africa'
  },
  {
    label: 'Africa/Maseru',
    name: 'Maseru',
    group: 'Africa'
  },
  {
    label: 'Africa/Mbabane',
    name: 'Mbabane',
    group: 'Africa'
  },
  {
    label: 'Africa/Mogadishu',
    name: 'Mogadishu',
    group: 'Africa'
  },
  {
    label: 'Africa/Monrovia',
    name: 'Monrovia',
    group: 'Africa'
  },
  {
    label: 'Africa/Nairobi',
    name: 'Nairobi',
    group: 'Africa'
  },
  {
    label: 'Africa/Ndjamena',
    name: 'Ndjamena',
    group: 'Africa'
  },
  {
    label: 'Africa/Niamey',
    name: 'Niamey',
    group: 'Africa'
  },
  {
    label: 'Africa/Nouakchott',
    name: 'Nouakchott',
    group: 'Africa'
  },
  {
    label: 'Africa/Ouagadougou',
    name: 'Ouagadougou',
    group: 'Africa'
  },
  {
    label: 'Africa/Porto-Novo',
    name: 'Porto-Novo',
    group: 'Africa'
  },
  {
    label: 'Africa/Sao_Tome',
    name: 'Sao Tome',
    group: 'Africa'
  },
  {
    label: 'Africa/Timbuktu',
    name: 'Timbuktu',
    group: 'Africa'
  },
  {
    label: 'Africa/Tripoli',
    name: 'Tripoli',
    group: 'Africa'
  },
  {
    label: 'Africa/Tunis',
    name: 'Tunis',
    group: 'Africa'
  },
  {
    label: 'Africa/Windhoek',
    name: 'Windhoek',
    group: 'Africa'
  },
  {
    label: 'Australia/ACT',
    name: 'ACT',
    group: 'Australia'
  },
  {
    label: 'Australia/Adelaide',
    name: 'Adelaide',
    group: 'Australia'
  },
  {
    label: 'Australia/Brisbane',
    name: 'Brisbane',
    group: 'Australia'
  },
  {
    label: 'Australia/Broken_Hill',
    name: 'Broken Hill',
    group: 'Australia'
  },
  {
    label: 'Australia/Canberra',
    name: 'Canberra',
    group: 'Australia'
  },
  {
    label: 'Australia/Currie',
    name: 'Currie',
    group: 'Australia'
  },
  {
    label: 'Australia/Darwin',
    name: 'Darwin',
    group: 'Australia'
  },
  {
    label: 'Australia/Eucla',
    name: 'Eucla',
    group: 'Australia'
  },
  {
    label: 'Australia/Hobart',
    name: 'Hobart',
    group: 'Australia'
  },
  {
    label: 'Australia/LHI',
    name: 'LHI',
    group: 'Australia'
  },
  {
    label: 'Australia/Lindeman',
    name: 'Lindeman',
    group: 'Australia'
  },
  {
    label: 'Australia/Lord_Howe',
    name: 'Lord Howe',
    group: 'Australia'
  },
  {
    label: 'Australia/Melbourne',
    name: 'Melbourne',
    group: 'Australia'
  },
  {
    label: 'Australia/North',
    name: 'North',
    group: 'Australia'
  },
  {
    label: 'Australia/NSW',
    name: 'NSW',
    group: 'Australia'
  },
  {
    label: 'Australia/Perth',
    name: 'Perth',
    group: 'Australia'
  },
  {
    label: 'Australia/Queensland',
    name: 'Queensland',
    group: 'Australia'
  },
  {
    label: 'Australia/South',
    name: 'South',
    group: 'Australia'
  },
  {
    label: 'Australia/Sydney',
    name: 'Sydney',
    group: 'Australia'
  },
  {
    label: 'Australia/Tasmania',
    name: 'Tasmania',
    group: 'Australia'
  },
  {
    label: 'Australia/Victoria',
    name: 'Victoria',
    group: 'Australia'
  },
  {
    label: 'Australia/West',
    name: 'West',
    group: 'Australia'
  },
  {
    label: 'Australia/Yancowinna',
    name: 'Yancowinna',
    group: 'Australia'
  },
  {
    label: 'Indian/Antananarivo',
    name: 'Antananarivo',
    group: 'Indian'
  },
  {
    label: 'Indian/Chagos',
    name: 'Chagos',
    group: 'Indian'
  },
  {
    label: 'Indian/Christmas',
    name: 'Christmas',
    group: 'Indian'
  },
  {
    label: 'Indian/Cocos',
    name: 'Cocos',
    group: 'Indian'
  },
  {
    label: 'Indian/Comoro',
    name: 'Comoro',
    group: 'Indian'
  },
  {
    label: 'Indian/Kerguelen',
    name: 'Kerguelen',
    group: 'Indian'
  },
  {
    label: 'Indian/Mahe',
    name: 'Mahe',
    group: 'Indian'
  },
  {
    label: 'Indian/Maldives',
    name: 'Maldives',
    group: 'Indian'
  },
  {
    label: 'Indian/Mauritius',
    name: 'Mauritius',
    group: 'Indian'
  },
  {
    label: 'Indian/Mayotte',
    name: 'Mayotte',
    group: 'Indian'
  },
  {
    label: 'Indian/Reunion',
    name: 'Reunion',
    group: 'Indian'
  },
  {
    label: 'Atlantic/Azores',
    name: 'Azores',
    group: 'Atlantic'
  },
  {
    label: 'Atlantic/Bermuda',
    name: 'Bermuda',
    group: 'Atlantic'
  },
  {
    label: 'Atlantic/Canary',
    name: 'Canary',
    group: 'Atlantic'
  },
  {
    label: 'Atlantic/Cape_Verde',
    name: 'Cape Verde',
    group: 'Atlantic'
  },
  {
    label: 'Atlantic/Faeroe',
    name: 'Faeroe',
    group: 'Atlantic'
  },
  {
    label: 'Atlantic/Faroe',
    name: 'Faroe',
    group: 'Atlantic'
  },
  {
    label: 'Atlantic/Jan_Mayen',
    name: 'Jan Mayen',
    group: 'Atlantic'
  },
  {
    label: 'Atlantic/Madeira',
    name: 'Madeira',
    group: 'Atlantic'
  },
  {
    label: 'Atlantic/Reykjavik',
    name: 'Reykjavik',
    group: 'Atlantic'
  },
  {
    label: 'Atlantic/South_Georgia',
    name: 'South Georgia',
    group: 'Atlantic'
  },
  {
    label: 'Atlantic/Stanley',
    name: 'Stanley',
    group: 'Atlantic'
  },
  {
    label: 'Atlantic/St_Helena',
    name: 'St Helena',
    group: 'Atlantic'
  },
  {
    label: 'Pacific/Apia',
    name: 'Apia',
    group: 'Atlantic'
  },
  {
    label: 'Pacific/Auckland',
    name: 'Auckland',
    group: 'Atlantic'
  },
  {
    label: 'Pacific/Chatham',
    name: 'Chatham',
    group: 'Atlantic'
  },
  {
    label: 'Pacific/Easter',
    name: 'Easter',
    group: 'Atlantic'
  },
  {
    label: 'Pacific/Efate',
    name: 'Efate',
    group: 'Atlantic'
  },
  {
    label: 'Pacific/Enderbury',
    name: 'Enderbury',
    group: 'Atlantic'
  },
  {
    label: 'Pacific/Fakaofo',
    name: 'Fakaofo',
    group: 'Atlantic'
  },
  {
    label: 'Pacific/Fiji',
    name: 'Fiji',
    group: 'Atlantic'
  },
  {
    label: 'Pacific/Funafuti',
    name: 'Funafuti',
    group: 'Atlantic'
  },
  {
    label: 'Pacific/Galapagos',
    name: 'Galapagos',
    group: 'Atlantic'
  },
  {
    label: 'Pacific/Gambier',
    name: 'Gambier',
    group: 'Atlantic'
  },
  {
    label: 'Pacific/Guadalcanal',
    name: 'Guadalcanal',
    group: 'Atlantic'
  },
  {
    label: 'Pacific/Guam',
    name: 'Guam',
    group: 'Atlantic'
  },
  {
    label: 'Pacific/Honolulu',
    name: 'Honolulu',
    group: 'Atlantic'
  },
  {
    label: 'Pacific/Johnston',
    name: 'Johnston',
    group: 'Atlantic'
  },
  {
    label: 'Pacific/Kiritimati',
    name: 'Kiritimati',
    group: 'Atlantic'
  },
  {
    label: 'Pacific/Kosrae',
    name: 'Kosrae',
    group: 'Atlantic'
  },
  {
    label: 'Pacific/Kwajalein',
    name: 'Kwajalein',
    group: 'Atlantic'
  },
  {
    label: 'Pacific/Majuro',
    name: 'Majuro',
    group: 'Atlantic'
  },
  {
    label: 'Pacific/Marquesas',
    name: 'Marquesas',
    group: 'Atlantic'
  },
  {
    label: 'Pacific/Midway',
    name: 'Midway',
    group: 'Atlantic'
  },
  {
    label: 'Pacific/Nauru',
    name: 'Nauru',
    group: 'Atlantic'
  },
  {
    label: 'Pacific/Niue',
    name: 'Niue',
    group: 'Atlantic'
  },
  {
    label: 'Pacific/Norfolk',
    name: 'Norfolk',
    group: 'Atlantic'
  },
  {
    label: 'Pacific/Noumea',
    name: 'Noumea',
    group: 'Atlantic'
  },
  {
    label: 'Pacific/Pago_Pago',
    name: 'Pago Pago',
    group: 'Atlantic'
  },
  {
    label: 'Pacific/Palau',
    name: 'Palau',
    group: 'Atlantic'
  },
  {
    label: 'Pacific/Pitcairn',
    name: 'Pitcairn',
    group: 'Atlantic'
  },
  {
    label: 'Pacific/Ponape',
    name: 'Ponape',
    group: 'Atlantic'
  },
  {
    label: 'Pacific/Port_Moresby',
    name: 'Port Moresby',
    group: 'Atlantic'
  },
  {
    label: 'Pacific/Rarotonga',
    name: 'Rarotonga',
    group: 'Atlantic'
  },
  {
    label: 'Pacific/Saipan',
    name: 'Saipan',
    group: 'Atlantic'
  },
  {
    label: 'Pacific/Samoa',
    name: 'Samoa',
    group: 'Atlantic'
  },
  {
    label: 'Pacific/Tahiti',
    name: 'Tahiti',
    group: 'Atlantic'
  },
  {
    label: 'Pacific/Tarawa',
    name: 'Tarawa',
    group: 'Atlantic'
  },
  {
    label: 'Pacific/Tongatapu',
    name: 'Tongatapu',
    group: 'Atlantic'
  },
  {
    label: 'Pacific/Truk',
    name: 'Truk',
    group: 'Atlantic'
  },
  {
    label: 'Pacific/Wake',
    name: 'Wake',
    group: 'Atlantic'
  },
  {
    label: 'Pacific/Wallis',
    name: 'Wallis',
    group: 'Atlantic'
  },
  {
    label: 'Pacific/Yap',
    name: 'Yap',
    group: 'Atlantic'
  },
  {
    label: 'Pacific/Apia',
    name: 'Apia',
    group: 'Pacific'
  },
  {
    label: 'Pacific/Auckland',
    name: 'Auckland',
    group: 'Pacific'
  },
  {
    label: 'Pacific/Chatham',
    name: 'Chatham',
    group: 'Pacific'
  },
  {
    label: 'Pacific/Easter',
    name: 'Easter',
    group: 'Pacific'
  },
  {
    label: 'Pacific/Efate',
    name: 'Efate',
    group: 'Pacific'
  },
  {
    label: 'Pacific/Enderbury',
    name: 'Enderbury',
    group: 'Pacific'
  },
  {
    label: 'Pacific/Fakaofo',
    name: 'Fakaofo',
    group: 'Pacific'
  },
  {
    label: 'Pacific/Fiji',
    name: 'Fiji',
    group: 'Pacific'
  },
  {
    label: 'Pacific/Funafuti',
    name: 'Funafuti',
    group: 'Pacific'
  },
  {
    label: 'Pacific/Galapagos',
    name: 'Galapagos',
    group: 'Pacific'
  },
  {
    label: 'Pacific/Gambier',
    name: 'Gambier',
    group: 'Pacific'
  },
  {
    label: 'Pacific/Guadalcanal',
    name: 'Guadalcanal',
    group: 'Pacific'
  },
  {
    label: 'Pacific/Guam',
    name: 'Guam',
    group: 'Pacific'
  },
  {
    label: 'Pacific/Honolulu',
    name: 'Honolulu',
    group: 'Pacific'
  },
  {
    label: 'Pacific/Johnston',
    name: 'Johnston',
    group: 'Pacific'
  },
  {
    label: 'Pacific/Kiritimati',
    name: 'Kiritimati',
    group: 'Pacific'
  },
  {
    label: 'Pacific/Kosrae',
    name: 'Kosrae',
    group: 'Pacific'
  },
  {
    label: 'Pacific/Kwajalein',
    name: 'Kwajalein',
    group: 'Pacific'
  },
  {
    label: 'Pacific/Majuro',
    name: 'Majuro',
    group: 'Pacific'
  },
  {
    label: 'Pacific/Marquesas',
    name: 'Marquesas',
    group: 'Pacific'
  },
  {
    label: 'Pacific/Midway',
    name: 'Midway',
    group: 'Pacific'
  },
  {
    label: 'Pacific/Nauru',
    name: 'Nauru',
    group: 'Pacific'
  },
  {
    label: 'Pacific/Niue',
    name: 'Niue',
    group: 'Pacific'
  },
  {
    label: 'Pacific/Norfolk',
    name: 'Norfolk',
    group: 'Pacific'
  },
  {
    label: 'Pacific/Noumea',
    name: 'Noumea',
    group: 'Pacific'
  },
  {
    label: 'Pacific/Pago_Pago',
    name: 'Pago Pago',
    group: 'Pacific'
  },
  {
    label: 'Pacific/Palau',
    name: 'Palau',
    group: 'Pacific'
  },
  {
    label: 'Pacific/Pitcairn',
    name: 'Pitcairn',
    group: 'Pacific'
  },
  {
    label: 'Pacific/Ponape',
    name: 'Ponape',
    group: 'Pacific'
  },
  {
    label: 'Pacific/Port_Moresby',
    name: 'Port Moresby',
    group: 'Pacific'
  },
  {
    label: 'Pacific/Rarotonga',
    name: 'Rarotonga',
    group: 'Pacific'
  },
  {
    label: 'Pacific/Saipan',
    name: 'Saipan',
    group: 'Pacific'
  },
  {
    label: 'Pacific/Samoa',
    name: 'Samoa',
    group: 'Pacific'
  },
  {
    label: 'Pacific/Tahiti',
    name: 'Tahiti',
    group: 'Pacific'
  },
  {
    label: 'Pacific/Tarawa',
    name: 'Tarawa',
    group: 'Pacific'
  },
  {
    label: 'Pacific/Tongatapu',
    name: 'Tongatapu',
    group: 'Pacific'
  },
  {
    label: 'Pacific/Truk',
    name: 'Truk',
    group: 'Pacific'
  },
  {
    label: 'Pacific/Wake',
    name: 'Wake',
    group: 'Pacific'
  },
  {
    label: 'Pacific/Wallis',
    name: 'Wallis',
    group: 'Pacific'
  },
  {
    label: 'Pacific/Yap',
    name: 'Yap',
    group: 'Pacific'
  },
  {
    label: 'Antarctica/Casey',
    name: 'Casey',
    group: 'Antarctica'
  },
  {
    label: 'Antarctica/Davis',
    name: 'Davis',
    group: 'Antarctica'
  },
  {
    label: 'Antarctica/DumontDUrville',
    name: 'DumontDUrville',
    group: 'Antarctica'
  },
  {
    label: 'Antarctica/Macquarie',
    name: 'Macquarie',
    group: 'Antarctica'
  },
  {
    label: 'Antarctica/Mawson',
    name: 'Mawson',
    group: 'Antarctica'
  },
  {
    label: 'Antarctica/McMurdo',
    name: 'McMurdo',
    group: 'Antarctica'
  },
  {
    label: 'Antarctica/Palmer',
    name: 'Palmer',
    group: 'Antarctica'
  },
  {
    label: 'Antarctica/Rothera',
    name: 'Rothera',
    group: 'Antarctica'
  },
  {
    label: 'Antarctica/South_Pole',
    name: 'South Pole',
    group: 'Antarctica'
  },
  {
    label: 'Antarctica/Syowa',
    name: 'Syowa',
    group: 'Antarctica'
  },
  {
    label: 'Antarctica/Vostok',
    name: 'Vostok',
    group: 'Antarctica'
  },
  {
    label: 'Arctic/Longyearbyen',
    name: 'Arctic',
    group: 'Arctic'
  },
  {
    label: 'UTC',
    name: 'UTC',
    group: 'UTC'
  },
  {
    label: 'UTC-12',
    name: 'UTC-12',
    group: 'Manual Offsets'
  },
  {
    label: 'UTC-11',
    name: 'UTC-11',
    group: 'Manual Offsets'
  },
  {
    label: 'UTC-10',
    name: 'UTC-10',
    group: 'Manual Offsets'
  },
  {
    label: 'UTC-9',
    name: 'UTC-9',
    group: 'Manual Offsets'
  },
  {
    label: 'UTC-8',
    name: 'UTC-8',
    group: 'Manual Offsets'
  },
  {
    label: 'UTC-7',
    name: 'UTC-7',
    group: 'Manual Offsets'
  },
  {
    label: 'UTC-6',
    name: 'UTC-6',
    group: 'Manual Offsets'
  },
  {
    label: 'UTC-5',
    name: 'UTC-5',
    group: 'Manual Offsets'
  },
  {
    label: 'UTC-4',
    name: 'UTC-4',
    group: 'Manual Offsets'
  },
  {
    label: 'UTC-3',
    name: 'UTC-3',
    group: 'Manual Offsets'
  },
  {
    label: 'UTC-2',
    name: 'UTC-2',
    group: 'Manual Offsets'
  },
  {
    label: 'UTC-1',
    name: 'UTC-1',
    group: 'Manual Offsets'
  },
  {
    label: 'UTC+0',
    name: 'UTC+0',
    group: 'Manual Offsets'
  },
  {
    label: 'UTC+1',
    name: 'UTC+1',
    group: 'Manual Offsets'
  },
  {
    label: 'UTC+2',
    name: 'UTC+2',
    group: 'Manual Offsets'
  },
  {
    label: 'UTC+3',
    name: 'UTC+3',
    group: 'Manual Offsets'
  },
  {
    label: 'UTC+4',
    name: 'UTC+4',
    group: 'Manual Offsets'
  },
  {
    label: 'UTC+5',
    name: 'UTC+5',
    group: 'Manual Offsets'
  },
  {
    label: 'UTC+6',
    name: 'UTC+6',
    group: 'Manual Offsets'
  },
  {
    label: 'UTC+7',
    name: 'UTC+7',
    group: 'Manual Offsets'
  },
  {
    label: 'UTC+8',
    name: 'UTC+8',
    group: 'Manual Offsets'
  },
  {
    label: 'UTC+9',
    name: 'UTC+9',
    group: 'Manual Offsets'
  },
  {
    label: 'UTC+10',
    name: 'UTC+10',
    group: 'Manual Offsets'
  },
  {
    label: 'UTC+11',
    name: 'UTC+11',
    group: 'Manual Offsets'
  },
  {
    label: 'UTC+12',
    name: 'UTC+12',
    group: 'Manual Offsets'
  },
  {
    label: 'UTC+13',
    name: 'UTC+13',
    group: 'Manual Offsets'
  },
  {
    label: 'UTC+14',
    name: 'UTC+14',
    group: 'Antarctica'
  }
]
