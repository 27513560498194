import {LayersOutlined, KeyboardReturnOutlined, VisibilityOutlined} from '@material-ui/icons'
import {DataGrid, GridToolbarContainer, GridToolbarExport} from '@material-ui/data-grid'
import './emailhistorycampaign.css'
import {useState, useEffect} from 'react'
import {useParams, useLocation, Link} from 'react-router-dom'
import axios from '../../../../helpers/request'
import moment from 'moment'
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined'
import {Chart as ChartJS, ArcElement, Tooltip, Legend} from 'chart.js'
import {Pie} from 'react-chartjs-2'

function CustomToolbar() {
  return (
    <span className="exportbutton">
      <GridToolbarExport />
    </span>
  )
}

export default function EmailHistoryCampaign() {
  const {campaignId} = useParams()

  const [campaign, setCampaign] = useState({})
  const [emails, setEmails] = useState({
    data: [],
    total: 0
  })

  const [buttonPopup, setButtonPopup] = useState(false)

  const [messageData, setMessageData] = useState('')

  const [pageSize, setPageSize] = useState(25)
  const [pageNumber, setPageNumber] = useState(1)
  const [status, setStatus] = useState('')

  const [successCount, setSuccessCount] = useState(0)
  const [failCount, setFailCount] = useState(0)

  const getCampaign = () => {
    axios.get('/analytic-history/' + campaignId).then(async response => {
      if (response.status === 200 && response.data && response.data[0]) {
        setCampaign(response.data[0])
      } else {
        throw new Error(response?.error)
      }
    })
  }

  const getMails = () => {
    setEmails({
      data: [],
      total: 0
    })
    let searchParams = {
      page_number: pageNumber,
      page_size: pageSize,
      status: status
    }
    axios.get('/emails-history/' + campaignId, {params: searchParams}).then(async response => {
      console.log('response', response)
      if (response.status === 200 && response.data) {
        const allMails = response.data
        setEmails(allMails)
        // const sCount = allMails.data.filter(mail => mail.status == 'delivered')
        // setSuccessCount(sCount.length);
        // const fCount = allMails.data.filter(mail => mail.status != 'delivered')
        // setFailCount(fCount.length);
      } else {
        throw new Error(response?.error)
      }
    })
  }

  const getStats = () => {
    axios.get(`/email-history/campaign/${campaignId}/stats`).then(async response => {
      if (response.status === 200 && response.data && response.data[0]) {
        setSuccessCount(Number(response.data[0].successCount))
        setFailCount(
          Number(response.data[0].bouncedCount) + Number(response.data[0].deferredCount) + Number(response.data[0].droppedCount)
        )
      } else {
        throw new Error(response?.error)
      }
    })
  }

  useEffect(() => {
    getCampaign()
    getStats()
  }, [])

  useEffect(() => {
    getMails()
  }, [pageSize, pageNumber, status])

  ChartJS.register(ArcElement, Tooltip, Legend)

  const data = {
    labels: ['Delivered', 'Not Delivered'],
    datasets: [
      {
        label: '# of Messages',
        data: [successCount, failCount],
        backgroundColor: ['rgba(97, 184, 91, 1)', 'rgba(220, 67, 76, 1)'],
        borderWidth: 1
      }
    ]
  }

  const emailColumns = [
    {field: 'uniqueid', headerName: 'ID', flex: 1, sortable: false},
    {field: 'email', headerName: 'To', flex: 1, sortable: false},
    {
      field: 'datetimesent',
      headerName: 'Date Processed',
      flex: 1,
      type: 'dateTime',
      valueGetter: ({value}) => value && new Date(value)
    },
    // {
    //   field: 'emailbody', headerName: 'Message Body', flex: 1, minWidth: 300, sortable: false,
    //   renderCell: (params) => {
    //     const text = params.row.emailbody.replace(/\n/g, "");
    //     console.log("text",text);
    //     return (
    //       <span className='normal-line-hight' dangerouslySetInnerHTML={{ __html: text }}></span>
    //     )
    //   }
    // },
    // { field: 'sentby', headerName: 'Sent By', width: 140, sortable: false },
    {field: 'status', headerName: 'Status', flex: 1, sortable: false}
    // {
    //   field: 'action',
    //   headerName: 'Action',
    //   width: 160,
    //   sortable: false,
    //   renderCell: (params) => {
    //     return (
    //       <div className='userListActions'>
    //         <button disabled={params.row.messageid ? false : true} onClick={params.row.messageid ? () => getMailInfo(params.row.messageid) : () => { }} className="actionElementButton">
    //           <VisibilityOutlined className='actionElementIcon' />
    //           <span className="actionButtonText">View Details</span>
    //         </button>
    //       </div>
    //     )
    //   }
    // }
  ]

  return (
    <div className="campaigns">
      <Link to={'/analytic-history'}>
        <KeyboardReturnOutlined className="returnHeaderIcon" />
      </Link>
      <div className="campaigndetailscontainer">
        <form className="campaignForm">
          <div className="campaignItem">
            <label>Campaign Name / Email Subject </label>
            <span className="dataDescriptionSM">{campaign.campaignname}</span>
          </div>

          <div className="campaignItem">
            <label>Sent By </label>
            <span className="dataDescriptionSM">{campaign.sentby}</span>
          </div>

          <div className="campaignItem">
            <label>{campaign.scheduled === 1 ? 'Date Scheduled' : 'Date Sent'}</label>
            <span className="dataDescriptionSM">{moment(campaign.datetimesent).format('DD/MM/YYYY hh:mm')}</span>
          </div>

          <div className="campaignItem">
            <label>Total Emails</label>
            <span className="dataDescriptionLG">{campaign.totalmessages}</span>
          </div>

          <div className="bodycontainer">
            <div className="bodyItem">
              <h3 className="pageTableTitle">Email Body</h3>
              <span className="dataDescriptionSM" dangerouslySetInnerHTML={{__html: campaign.smsbody}}></span>

              {/* <span className="dataDescriptionSM">{campaign.smsbody}</span> */}
            </div>
          </div>
        </form>

        <div className="campaigndetailschart">
          <Pie data={data} />
        </div>
      </div>

      <div className="userTables">
        <div className="sendNowItem">
          <label>Select Email Status</label>
          <select
            name="sendGridSenderId"
            value={status}
            className="popupDropdown"
            onChange={event => setStatus(event.target.value)}
          >
            <option value="">All</option>
            <option value="delivered">Delivered</option>
            <option value="failed">Failed</option>
          </select>
        </div>
        <div className="userTablesTop">
          <DataGrid
            columns={emailColumns}
            rows={emails.data}
            rowCount={emails.total}
            pageSize={pageSize}
            rowsPerPageOptions={[10, 25, 50]}
            onPageSizeChange={newPageSize => setPageSize(newPageSize)}
            onPageChange={newPage => setPageNumber(newPage + 1)}
            pagination
            paginationMode="server"
            checkboxSelection
            disableSelectionOnClick
            autoHeight={true}
            components={{
              ColumnMenuIcon: FilterAltOutlinedIcon,
              Toolbar: CustomToolbar
            }}
          />
        </div>
      </div>

      {/* <CustomPopup trigger={buttonPopup} setTrigger={setButtonPopup}>
        <h3 className='popupTitle'>Email Details</h3>
        {messageData && <table>
          {Object.keys(messageData).map(key => {
            return (
              <>
                {
                  key != "events" ?
                    <tr>
                      <td>{key} :</td>
                      <td>{messageData[key]}</td>
                    </tr>
                    :
                    messageData[key].map(event => {
                      return (
                        <tr>
                          {Object.keys(event).map((subKey) => {
                            return (
                              subKey === "processed" ? <td>{moment(event[subKey]).format('DD/MM/YYYY hh:mm')}</td> : <td>{event[subKey]} :</td>
                            )
                          })}
                        </tr>
                      )
                    })
                }
              </>
            )
          })}
        </table>}
      </CustomPopup> */}
    </div>
  )
}
