import React from 'react'
import CustomPopup from '../../components/Popup/CustomPopup'
import {useState} from 'react'
import {useEffect} from 'react'
import {useAppState} from '../../state'
import {useIdleTimer} from 'react-idle-timer'

export default function TimeContainer() {
  const idleTimeout = 1000 * 60 * 43
  const promptTimeout = 1000 * 60 * 2

  const onPromptTimeout = () => {
    signOut()
  }

  const {getRemainingTime, reset} = useIdleTimer({
    timeout: idleTimeout,
    promptTimeout: promptTimeout,
    onPrompt: () => setIsIdle(true),
    onIdle: onPromptTimeout,
    onActive: () => resetTimer()
  })

  const {signOut, user, refreshUserDetails} = useAppState()
  const [isIdle, setIsIdle] = useState(false)
  const [remaining, setRemaining] = useState(idleTimeout)

  const resetTimer = () => {
    setIsIdle(false)
    reset()
  }

  const getFormatedTime = secs => {
    let sec_num = parseInt(secs, 10)
    let hours = Math.floor(sec_num / 3600)
    let minutes = Math.floor(sec_num / 60) % 60
    let seconds = sec_num % 60

    return [minutes, seconds].map(v => (v < 10 ? '0' + v : v)).join(':') //.filter((v, i) => v !== "00" || i > 0)
  }

  useEffect(() => {
    setRemaining(getRemainingTime())
    // updateUserSubscription();
    refreshUserDetails()
    setInterval(() => {
      setRemaining(getRemainingTime())
    }, 1000)
  }, [])

  return (
    <div className="timerContainer">
      <CustomPopup trigger={isIdle} setTrigger={resetTimer}>
        <h4>
          You will be logged out due to inactivity in
          {getFormatedTime(remaining / 1000)}
        </h4>
        <div className="buttonContainer">
          <button className="keepLoggedInButton" onClick={() => resetTimer()}>
            Keep me Logged in
          </button>
          <button className="logoutButton" onClick={signOut}>
            Logout Now
          </button>
        </div>
      </CustomPopup>
    </div>
  )
}
