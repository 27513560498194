import React, {useState, useEffect} from 'react'
import {useParams, useHistory} from 'react-router-dom'
import './email.css'
import axios from '../../../helpers/request'
import {Grid, Autocomplete, TextField, DialogContent, Dialog} from '@mui/material'
import Switch from '@material-ui/core/Switch'
import {styled} from '@mui/material/styles'
import CheckIcon from '@mui/icons-material/Check'
import Cookies from 'universal-cookie'

import {DateTime as LuxonDateTime} from 'luxon'
import {AdapterLuxon} from '@mui/x-date-pickers/AdapterLuxon'
import {DemoContainer} from '@mui/x-date-pickers/internals/demo'
import {DateTimePicker as MuiDatePicker, LocalizationProvider} from '@mui/x-date-pickers'

import {useAppState} from '../../../state'
import CustomPopup from '../../Popup/CustomPopup'
import {alltimeZones} from '../../common/timezones'
import templatesIcon from '../../images/templates.svg'
import {filterEmailContent} from '../../common/functions'
import {ckeditorConfig} from '../../common/ckeditorConfig'
import Importcontacts from '../Contacts/Import/ImportContacts'
import PreviewMessage from '../PreviewMessage/PreviewMessage'

import Swal from 'sweetalert2'

const SEND_THROUGH_EMAIL_VALUE = 'email'
const BootstrapDialog = styled(Dialog)(({theme}) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
    minwidth: '400px'
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(2)
  }
}))

const greetings = [
  {label: 'No Greetings', value: 'Nogreet'},
  {label: 'Dear @FirstName', value: 'greetDearFirst'},
  {label: 'Dear @FirstName @LastName', value: 'greetDearFirstLast'},
  {label: 'Hi @FirstName', value: 'greetHiFirst'},
  {label: 'Hi @FirstName @LastName', value: 'greetHiFirstLast'},
  {label: 'Hello @FirstName', value: 'greetHelloFirst'},
  {label: 'Hello @FirstName @LastName', value: 'greetHelloFirstLast'}
]
const GREET_DEFAULT_VALUE = greetings[0].value

const currentDateCondition = {minutes: 6}
const minDateCondition = {minutes: 5}
const maxDateCondition = {days: 7}
const EMAIL_SLUG = '##EMAIL_BODY## :'
const SMS_SLUG = '##MESSAGE_TEXT## :'

export default function ScheduleEmail() {
  const {campaignId} = useParams()
  const history = useHistory()
  const isEdit = !!campaignId
  const timeObject = LuxonDateTime.local()
  const defaultScheduleMinDateTime = timeObject.plus(minDateCondition)
  const defaultScheduleMaxDateTime = timeObject.plus(maxDateCondition)
  const defaultScheduleDateTime = timeObject.plus(currentDateCondition)
  let defaultScheduleUTCDateTime = LuxonDateTime.local().setZone('UTC')
  defaultScheduleUTCDateTime = defaultScheduleUTCDateTime.plus(currentDateCondition)

  const [campaign, setCampaign] = useState({
    campaignName: '',
    smsMessageBody: '',
    messageBody: '',
    file: [],
    isBoth: false,
    isBulk: false,
    dateToSend: '',
    greetingText: GREET_DEFAULT_VALUE,
    emailChannelId: '',
    fromEmail: '',
    fromName: '',
    smsChannelId: ''
  })

  const [channelList, setChannelList] = useState([])
  const [contactList, setContactList] = useState([])
  const [selectedContactList, setSelectedContactList] = useState([])
  const [selectedContact, setSelectedContact] = useState(null)
  const [buttonPopup, setButtonPopup] = useState(false)
  const [smsButtonPopup, setSmsButtonPopup] = useState(false)

  const [data, setData] = useState([])
  const [template, setTemplate] = useState('')
  const [smsTemplate, setSmsTemplate] = useState('')
  const [sending, setSending] = useState(false)
  const [opencontactModal, setOpencontactModal] = useState(false)
  const [updateModalSize, setUpdateModalSize] = useState(false)
  const [emailEditor, setEmailEditor] = useState('')
  const [userSettings, setUserSettings] = useState(false)
  const [isEmailTemplateApplied, setIsEmailTemplateApplied] = useState(false)
  const [isSMSTemplateApplied, setIsSMSTemplateApplied] = useState(false)

  const [scheduleMinDateTime, setScheduleMinDateTime] = useState(defaultScheduleMinDateTime)
  const [scheduleMaxDateTime, setScheduleMaxDateTime] = useState(defaultScheduleMaxDateTime)
  const [currentTimezone, setCurrentTimezone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone)
  const [storedUTCDateValue, setStoredUTCDateValue] = useState(!isEdit ? defaultScheduleUTCDateTime : null)
  const [displayDateValue, setDisplayDateValue] = useState(!isEdit ? defaultScheduleDateTime : null)

  const {user: loggedUser, setIsLoading} = useAppState()
  const cookies = new Cookies()
  const userToken = cookies.get('cs_token')
  const companyId = loggedUser.added_by ? loggedUser.added_by : loggedUser.id
  let defaultTimezone = alltimeZones.filter(row => row.label === currentTimezone)
  defaultTimezone = defaultTimezone[0]

  const [emailSenderList, setEmailSenderList] = useState([])

  const [openPreviewModal, setOpenPreviewModal] = useState(false)
  const [previewCampaignData, setPreviewCampaignData] = useState({})

  useEffect(() => {
    if (openPreviewModal) {
      let campaignData = {
        ...campaign,
        emailMessageBody: filterEmailContent(campaign.messageBody),
        companyid: loggedUser.added_by ? loggedUser.added_by : loggedUser.id
      }

      setPreviewCampaignData(campaignData)
    } else {
      setPreviewCampaignData({})
    }
  }, [openPreviewModal])

  const previewMessage = () => {
    campaign.messageBody = emailEditor.getData().trim()
    const {isValid, message, errors} = isValidData()

    if (isValid) {
      setOpenPreviewModal(true)
    } else {
      let errMsg = 'Followings are errors in the form \n'
      errors.map(er => {
        errMsg += '     - ' + er + '\n'
      })
      Swal.fire({title: errMsg, confirmButtonColor: '#3085d6'})
    }
  }

  const getSettings = () => {
    axios.get(`/get-settings/${companyId}`).then(async response => {
      if (response.status === 200 && response.data) {
        let settings = response.data
        setUserSettings(settings)
      } else {
        throw new Error(response?.error)
      }
    })
  }

  const getChannelList = companyId => {
    axios.get(`/channels`).then(async response => {
      if (response.status === 200 && response.data) {
        setChannelList(response.data)
      } else {
        throw new Error(response?.error)
      }
    })
  }

  const getContactList = companyId => {
    axios.get(`/all-contacts/${companyId}`).then(async response => {
      if (response.status === 200 && response.data) {
        setContactList(response.data)
      } else {
        throw new Error(response?.error)
      }
    })
  }

  const loadCKEditor = () => {
    if (window && document) {
      let ckEditorDiv = document.getElementsByClassName('ck-editor')
      if (!ckEditorDiv.length) {
        window.CKEDITOR.ClassicEditor.create(document.getElementById('super-ckeditor'), ckeditorConfig)
          .then(editor => {
            window.editor = editor
            setEmailEditor(editor)
            if (isEdit) {
              editor.data.set(campaign.messageBody)
            }
          })
          .catch(error => {
            console.log('error of ckeditor create=>', error)
            loadCKEditor()
          })
      }
    }
  }

  if (campaign.emailChannelId) {
    loadCKEditor()
  }

  useEffect(() => {
    getSettings()
    if (campaignId) getCampaigns()
    axios
      .post(`${process.env.REACT_APP_API_BASEURL}/userdetails`, {
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      })
      .then(res => {
        if (res.data.length > 0) {
          const cmpny = res.data[0].added_by ? res.data[0].added_by : res.data[0].id
          getChannelList(cmpny)
          getContactList(cmpny)
        }
      })
      .catch(err => {
        console.log('errr', err)
      })
  }, [])

  const getCampaigns = () => {
    axios.get('/analytic-detail/' + campaignId).then(async response => {
      if (response.status === 200 && response.data) {
        console.log(response.data)
        const {smsbody} = response.data[0]
        let messageBody = '',
          smsMessageBody = ''
        let messageParts = smsbody.split(EMAIL_SLUG)
        if (messageParts.length > 1) {
          messageBody = messageParts[1]
        }
        let messageSMSParts = messageParts[0].split(SMS_SLUG)
        if (messageSMSParts.length > 1) {
          smsMessageBody = messageSMSParts[1]
        }
        setCampaign({
          campaignName: response.data[0].campaignname,
          smsMessageBody,
          messageBody,
          file: [],
          isBoth: response.data[0].type === 11,
          isBulk: true,
          dateToSend: response.data[0].datetimesent,
          greetingText: response.data[0].greetingText ?? GREET_DEFAULT_VALUE,
          emailChannelId: response.data[0].emailChannel,
          fromEmail: response.data[0].emailFromData ? response.data[0].emailFromData.split('&&&')[0] : '',
          fromName: response.data[0].emailFromData ? response.data[0].emailFromData.split('&&&')[1] : '',
          smsChannelId: response.data[0].smsChannel
        })
        setStoredUTCDateValue(LuxonDateTime.fromMillis(response.data[0].dateToSendTimestamp * 1000).setZone('UTC'))
        setDisplayDateValue(LuxonDateTime.fromMillis(response.data[0].dateToSendTimestamp * 1000))
      } else {
        throw new Error(response?.error)
      }
    })
  }

  const getSelectedSender = senders => {
    let currentUserObj = senders.find(obj => obj.from_email === loggedUser.email)
    let defaultSenderObj = senders.find(obj => obj.from_email.toLowerCase().includes('noreply'))

    if (currentUserObj) {
      return currentUserObj
    } else if (defaultSenderObj) {
      return defaultSenderObj
    } else {
      return null
    }
  }

  const fetchEmailSenders = emailChannelId => {
    axios.get(`/email-channel/${emailChannelId}/senders`).then(async response => {
      if (response.status === 200 && response.data && response.data.results) {
        // console.log("fetchEmailSenders",response.data.results)
        let sgSenderObj = getSelectedSender(response.data.results)
        // console.log("sgSenderObj",sgSenderObj)

        if (sgSenderObj && !isEdit) {
          setCampaign(prevCampaign => ({
            ...prevCampaign,
            fromName: sgSenderObj.from_name,
            fromEmail: sgSenderObj.from_email
          }))
        }

        setEmailSenderList(response.data.results)
      } else {
        setEmailSenderList([])
      }
    })
  }

  useEffect(() => {
    if (campaign.emailChannelId) {
      fetchEmailSenders(campaign.emailChannelId)
    } else {
      setEmailSenderList([])
    }
  }, [campaign.emailChannelId])

  const openPopup = (event, smsTemp) => {
    event.preventDefault()
    setData([])
    setTemplate('')
    getTemplates()
    if (smsTemp) {
      setSmsButtonPopup(true)
    } else {
      setButtonPopup(true)
    }
  }

  const getTemplates = () => {
    let queryParams = {
      companyId: loggedUser.added_by ? loggedUser.added_by : loggedUser.id
    }
    axios.get('/templates', {params: queryParams}).then(async response => {
      if (response.status === 200 && response.data) {
        const templates = response.data
        setData(templates)
      } else {
        throw new Error(response?.error)
      }
    })
  }

  const applyTemplate = (e, smsTemp) => {
    const selectedTemplate = data.filter(temp => temp.id == (smsTemp ? smsTemplate : template))

    if (selectedTemplate && selectedTemplate[0]) {
      if (smsTemp) {
        setIsSMSTemplateApplied(true)
        setCampaign(prevCampaign => ({
          ...prevCampaign,
          smsMessageBody: selectedTemplate[0].templatebody
        }))
      } else {
        setIsEmailTemplateApplied(true)
        setCampaign(prevCampaign => ({
          ...prevCampaign,
          messageBody: selectedTemplate[0].templatebody
        }))
        emailEditor.setData(selectedTemplate[0].templatebody)
        if (!userSettings.enable_email_template_edit) {
          document.getElementsByClassName('ck-editor')[0].style.pointerEvents = 'none'
          var el = document.querySelectorAll('.ck-widget__type-around__button')
          for (var i = 0; i < el.length; i++) {
            el[i].style.display = 'none'
          }
        }
      }
    }

    setButtonPopup(false)
    setSmsButtonPopup(false)
  }

  const removeAppliedTemplate = type => {
    if (type == 'sms') {
      setIsSMSTemplateApplied(false)
      setCampaign(prevCampaign => ({
        ...prevCampaign,
        smsMessageBody: ''
      }))
    } else {
      setIsEmailTemplateApplied(false)
      emailEditor.setData('')
      document.getElementsByClassName('ck-editor')[0].style.pointerEvents = 'auto'
      setCampaign(prevCampaign => ({
        ...prevCampaign,
        messageBody: ''
      }))
    }
  }

  const handleChange = event => {
    const {name, value} = event.target

    if (name == 'fromEmail') {
      const sgSenderObj = emailSenderList.find(obj => obj.from_email == value)

      setCampaign(prevCampaign => ({
        ...prevCampaign,
        [name]: value,
        fromName: sgSenderObj.from_name
      }))
    } else {
      setCampaign(prevCampaign => ({
        ...prevCampaign,
        [name]: value
      }))
    }
  }

  const handleChangeSwitch = event => {
    const {name, checked} = event.target

    if (name == 'isBoth') {
      if (checked) {
        setCampaign(prevCampaign => ({
          ...prevCampaign,
          [name]: checked
        }))
      } else {
        setCampaign(prevCampaign => ({
          ...prevCampaign,
          smsChannelId: '',
          [name]: checked
        }))
      }
    } else {
      setSelectedContact(null)
      if (checked) {
        setOpencontactModal(true)
        setCampaign(prevCampaign => ({
          ...prevCampaign,
          [name]: checked
        }))
      } else {
        setCampaign(prevCampaign => ({
          ...prevCampaign,
          [name]: checked
        }))
      }
    }
  }

  const resetForm = () => {
    if (emailEditor) {
      emailEditor.setData('')
      document.getElementsByClassName('ck-editor')[0].style.pointerEvents = 'auto'
    }
    setIsSMSTemplateApplied(false)
    setIsEmailTemplateApplied(false)

    setCampaign(prevCampaign => ({
      ...prevCampaign,
      campaignName: '',
      messageBody: '',
      file: [],
      isBoth: false,
      dateToSend: '',
      greetingText: GREET_DEFAULT_VALUE,
      smsChannelId: ''
    }))
  }

  const isValidData = () => {
    const {campaignName, messageBody, smsMessageBody, isBoth, emailChannelId, smsChannelId} = campaign
    let isValid = true

    let errors = []
    if (emailChannelId === '') {
      isValid = false
      errors[errors.length] = 'Please select email channel'
    }

    if (emailChannelId) {
      if (campaignName === '') {
        isValid = false
        errors[errors.length] = 'Campaign name is required'
      }

      if (selectedContactList.length === 0 && !isEdit) {
        isValid = false
        errors[errors.length] = 'Please upload contacts'
      }

      if (messageBody === '') {
        isValid = false
        errors[errors.length] = 'Email body is required'
      }
    }

    if (isBoth) {
      if (smsChannelId === '') {
        isValid = false
        errors[errors.length] = 'Please select SMS channel'
      } else {
        if (smsMessageBody === '') {
          isValid = false
          errors[errors.length] = 'SMS body is required'
        }
      }
    }

    return {isValid, errors}
  }

  const handleSubmit = event => {
    event.preventDefault()
    campaign.messageBody = emailEditor.getData().trim()
    const {isValid, errors} = isValidData()

    if (isValid) {
      const campaigndata = {...campaign}

      campaigndata.emailMessageBody = filterEmailContent(campaign.messageBody)
      campaigndata.dateToSend = storedUTCDateValue.toFormat('yyyy-MM-dd HH:mm:ss')
      campaigndata.dateToSendTimestamp = Math.round(storedUTCDateValue.ts / 1000)
      campaigndata.dateToSendFullDate = storedUTCDateValue.toJSDate()
      setSending(true)
      setIsLoading(true)

      axios
        .post('/schedule-sms-email-channel', {
          data: {
            ...campaigndata,
            isEdit,
            campaignId,
            contactList: selectedContactList,
            companyid: loggedUser.added_by ? loggedUser.added_by : loggedUser.id
          },
          actionBy: loggedUser.fullName
        })
        .then(function (response) {
          setIsLoading(false)
          setSending(false)
          Swal.fire({
            title: `Scheduled emails${campaign.isBoth ? ' and SMS' : ''} successfully`,
            confirmButtonColor: '#3085d6'
          }).then(() => {
            if (isEdit) {
              history.push('/analytics')
            }
          })
          resetForm()
        })
        .catch(function (error) {
          setIsLoading(false)
          setSending(false)
          console.log(error)
          Swal.fire({title: 'Unable to process this request', confirmButtonColor: '#3085d6'})
        })
    } else {
      let errMsg = 'Followings are errors in the form \n'
      errors.map(er => {
        errMsg += '     - ' + er + '\n'
      })
      Swal.fire({title: errMsg, confirmButtonColor: '#3085d6'})
    }
  }

  const handleImportModalclose = () => {
    if (selectedContactList.length === 0) {
      setCampaign(prevCampaign => ({
        ...prevCampaign,
        isBulk: false
      }))
    }
    setOpencontactModal(false)
  }

  const getUploadedContactData = dataArray => {
    setOpencontactModal(false)
    setSelectedContactList(dataArray)
  }

  return (
    <div className="mainContentWrapper">
      <Grid container spacing={2} className="ptb-0 main-grid-container">
        <Grid container item spacing={2} xs={12} md={12} lg={12} className="ptb-0">
          <Grid item xs={12} md={campaign.isBoth ? 4 : 6} lg={campaign.isBoth ? 4 : 6}>
            <div className="sendNowItem">
              <label>Please select a channel</label>
              <select
                name="emailChannelId"
                value={campaign.emailChannelId}
                className="popupDropdown"
                onChange={event => {
                  handleChange(event)
                }}
              >
                <option value="" disabled>
                  Select channel
                </option>
                {channelList
                  .filter(v => ['sendgrid-mail-service'].includes(v.type))
                  .map(chan => (
                    <option key={chan.id} value={chan.id}>
                      {chan.label}
                    </option>
                  ))}
              </select>
            </div>
          </Grid>

          {campaign.emailChannelId && (
            <Grid item xs={12} md={campaign.isBoth ? 4 : 6} lg={campaign.isBoth ? 4 : 6}>
              <div className="sendNowItem">
                <label>
                  Please select email sender <span className="mandatory">*</span>
                </label>
                <select
                  name="fromEmail"
                  value={campaign.fromEmail}
                  id="smsdrop"
                  className="popupDropdown"
                  onChange={handleChange}
                >
                  <option value="" disabled>
                    {' '}
                    Select sender{' '}
                  </option>
                  {emailSenderList.map(sender => (
                    <option key={sender.from_email} value={sender.from_email}>
                      {sender.from_email}
                    </option>
                  ))}
                </select>
              </div>
            </Grid>
          )}

          {campaign.isBoth && (
            <Grid item xs={12} md={4} lg={4}>
              <div className="sendNowItem">
                <label>
                  {' '}
                  Please select sms channel <span className="mandatory">*</span>{' '}
                </label>
                <select
                  name="smsChannelId"
                  value={campaign.smsChannelId}
                  id="smsdrop"
                  className="popupDropdown"
                  onChange={event => {
                    handleChange(event)
                  }}
                >
                  <option value="" disabled>
                    Select channel
                  </option>
                  {channelList
                    .filter(v => ['twilio-messaging-service', 'twilio-sms'].includes(v.type))
                    .map(chan => (
                      <option key={chan.id} value={chan.id}>
                        {chan.label}
                      </option>
                    ))}
                </select>
              </div>
            </Grid>
          )}

          {/* Email body and inputs START */}
          {campaign.emailChannelId && (
            <>
              <Grid container item xs={12} md={12} lg={12} spacing={2}>
                <div className="ml-15">
                  <h5> Email Details </h5>
                </div>
              </Grid>
              <Grid container item xs={12} md={12} lg={12} spacing={2} className="sub-container hidden-container ptb-0">
                <Grid item xs={12} md={12} lg={12}>
                  <div className="sendNowItem">
                    <nobr>
                      <span className="flex-row float-right form-label">
                        Send Both Email/SMS
                        <Switch checked={campaign.isBoth} onChange={handleChangeSwitch} name="isBoth" />
                      </span>
                    </nobr>
                  </div>
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                  <div className="sendNowItem">
                    <nobr>
                      <span className="form-label">{campaign.isBoth ? 'Campaign Name  / Email Subject' : 'Email Subject'}</span>
                      <span className="mandatory">*</span>
                      <span className="flex-row float-right form-label">
                        Upload Contacts
                        <Switch checked={campaign.isBulk ? 'checked' : ''} onChange={handleChangeSwitch} name="isBulk" />
                      </span>
                    </nobr>
                    <input
                      value={campaign.campaignName}
                      onChange={handleChange}
                      name="campaignName"
                      type="text"
                      placeholder="Enter email subject here"
                      className="nameimage pl-60"
                    />
                  </div>
                </Grid>

                {selectedContactList.length >= 1 && (
                  <Grid item xs={12} md={12} lg={12} className="mb-0">
                    <div className="contacts-selected-div pb-10">
                      <span className="form-label border-box">
                        {selectedContactList.length} contacts selected
                        <CheckIcon color="success" className="ml-15" />
                      </span>
                    </div>
                  </Grid>
                )}

                <Grid item xs={12} md={12} lg={12}>
                  <div className="sendNowItem">
                    <label>
                      Choose Greetings <span className="mandatory">*</span>
                    </label>
                    <select value={campaign.greetingText} onChange={handleChange} name="greetingText" className="popupDropdown">
                      <option value="" disabled>
                        Select Greeting
                      </option>
                      {greetings.map(greet => (
                        <option key={greet.value} value={greet.value}>
                          {greet.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </Grid>

                <Grid item xs={12} md={12} lg={12} className="pb-25">
                  <div className="templateButtonContainer">
                    <label className="form-label pt-35">
                      Enter Email Body Text <span className="mandatory">*</span>
                    </label>
                    <label className="d-flex justify-content-between">
                      <div className="select_template_div pt-10">
                        {!isEmailTemplateApplied ? (
                          <button className="templateElementButton template-button" onClick={openPopup}>
                            <img src={templatesIcon} className="filter-red" />
                            <span className="templateButtonText">Select Template</span>
                          </button>
                        ) : (
                          <button className="btn btn-white-red mr-20" onClick={() => removeAppliedTemplate('email')}>
                            <span className="templateButtonText">Remove Template</span>
                          </button>
                        )}
                      </div>
                    </label>
                  </div>
                </Grid>
                <Grid item xs={12} md={12} lg={12} className="ckeditor-grid pb-35">
                  <div id="super-ckeditor"></div>
                </Grid>

                {!campaign.isBoth || (campaign.isBoth && campaign.smsChannelId === '') ? (
                  <>
                    <Grid item xs={12} md={12} lg={12}>
                      <div className="sendNowItem">
                        <label>
                          Schedule Date /Time <span className="mandatory">*</span>
                        </label>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <div className="sendNowItem">
                        <Autocomplete
                          id="grouped-demo"
                          options={alltimeZones}
                          groupBy={option => option.group}
                          getOptionLabel={option => option.label}
                          renderInput={params => <TextField {...params} />}
                          value={defaultTimezone}
                          onChange={(event, newValue) => {
                            if (newValue !== null && newValue !== '') {
                              setCurrentTimezone(newValue.label)
                              let newTimezone = LuxonDateTime.local().setZone(newValue.label)
                              newTimezone = newTimezone.plus(currentDateCondition)
                              let utcTimezone = LuxonDateTime.local().setZone('UTC')
                              utcTimezone = utcTimezone.plus(currentDateCondition)
                              let newMinDate = LuxonDateTime.local().setZone(newValue.label)
                              newMinDate = newMinDate.plus(minDateCondition)
                              let newMaxDate = LuxonDateTime.local().setZone(newValue.label)
                              newMaxDate = newMaxDate.plus(maxDateCondition)
                              setDisplayDateValue(newTimezone)
                              setStoredUTCDateValue(utcTimezone)
                              setScheduleMinDateTime(newMinDate)
                              setScheduleMaxDateTime(newMaxDate)
                            }
                          }}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <div className="muidatepicker" style={{width: '96%'}}>
                        <LocalizationProvider dateAdapter={AdapterLuxon}>
                          <DemoContainer components={['DateTimePicker']}>
                            <MuiDatePicker
                              value={displayDateValue}
                              minDateTime={scheduleMinDateTime}
                              maxDateTime={scheduleMaxDateTime}
                              onChange={newValue => {
                                let sdate = new Date(
                                  displayDateValue.c.year +
                                    '-' +
                                    displayDateValue.c.month +
                                    '-' +
                                    displayDateValue.c.day +
                                    ' ' +
                                    displayDateValue.c.hour +
                                    ':' +
                                    displayDateValue.c.minute +
                                    ':' +
                                    displayDateValue.c.second
                                )
                                let edate = new Date(
                                  newValue.c.year +
                                    '-' +
                                    newValue.c.month +
                                    '-' +
                                    newValue.c.day +
                                    ' ' +
                                    newValue.c.hour +
                                    ':' +
                                    newValue.c.minute +
                                    ':' +
                                    newValue.c.second
                                )
                                let diff = (edate.getTime() - sdate.getTime()) / 1000
                                diff /= 60
                                let diffInMinutes = Math.abs(Math.round(diff))
                                let newutcValue = ''
                                if (sdate.getTime() >= edate.getTime()) {
                                  newutcValue = storedUTCDateValue.minus({minutes: diffInMinutes})
                                } else {
                                  newutcValue = storedUTCDateValue.plus({minutes: diffInMinutes})
                                }
                                setDisplayDateValue(newValue)
                                setStoredUTCDateValue(newutcValue)
                              }}
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                      </div>
                    </Grid>

                    <Grid item xs={12} md={12} lg={12} className="pb-25">
                      <div className="templateButtonContainerWithPreview flex-row-reverse">
                        <button disabled={sending} className="sendNowButton" onClick={handleSubmit}>
                          Schedule Now
                        </button>
                        <button disabled={sending} className="previewButton" onClick={previewMessage}>
                          Preview Message
                        </button>
                      </div>
                    </Grid>
                  </>
                ) : (
                  ''
                )}
              </Grid>
            </>
          )}
          {/* Email body and inputs END */}

          {/* SMS body and inputs START */}
          {campaign.isBoth && campaign.smsChannelId && campaign.emailChannelId && (
            <>
              <Grid container item xs={12} md={12} lg={12} spacing={2}>
                <div className="ml-15">
                  <h5> SMS Details </h5>
                </div>
              </Grid>
              <Grid container item xs={12} md={12} lg={12} spacing={2} className="sub-container hidden-container ptb-0">
                <Grid item xs={12} md={12} lg={12}>
                  <div className="templateButtonContainer">
                    <label className="form-label pt-35 pb-15">
                      Enter SMS Body Text <span className="mandatory">*</span>
                    </label>
                    <label className="d-flex justify-content-between">
                      <div className="select_template_div pt-10">
                        {!isSMSTemplateApplied ? (
                          <button className="templateElementButton template-button" onClick={e => openPopup(e, true)}>
                            <img src={templatesIcon} className="filter-red" />
                            <span className="templateButtonText">Select Template</span>
                          </button>
                        ) : (
                          <button className="btn btn-white-red mr-20" onClick={() => removeAppliedTemplate('sms')}>
                            <span className="templateButtonText">Remove Template</span>
                          </button>
                        )}
                      </div>
                    </label>
                  </div>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <div className="sendNowItem">
                    <textarea
                      value={campaign.smsMessageBody}
                      onChange={handleChange}
                      name="smsMessageBody"
                      placeholder="Enter the body of your SMS message here"
                      disabled={isSMSTemplateApplied && !userSettings.enable_sms_template_edit}
                    ></textarea>
                  </div>
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                  <div className="sendNowItem">
                    <label>
                      Schedule Date /Time <span className="mandatory">*</span>
                    </label>
                  </div>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <div className="sendNowItem">
                    <Autocomplete
                      id="grouped-demo"
                      options={alltimeZones}
                      groupBy={option => option.group}
                      getOptionLabel={option => option.label}
                      renderInput={params => <TextField {...params} />}
                      value={defaultTimezone}
                      onChange={(event, newValue) => {
                        if (newValue !== null && newValue !== '') {
                          setCurrentTimezone(newValue.label)
                          let newTimezone = LuxonDateTime.local().setZone(newValue.label)
                          newTimezone = newTimezone.plus(currentDateCondition)
                          let utcTimezone = LuxonDateTime.local().setZone('UTC')
                          utcTimezone = utcTimezone.plus(currentDateCondition)
                          let newMinDate = LuxonDateTime.local().setZone(newValue.label)
                          newMinDate = newMinDate.plus(minDateCondition)
                          let newMaxDate = LuxonDateTime.local().setZone(newValue.label)
                          newMaxDate = newMaxDate.plus(maxDateCondition)
                          setDisplayDateValue(newTimezone)
                          setStoredUTCDateValue(utcTimezone)
                          setScheduleMinDateTime(newMinDate)
                          setScheduleMaxDateTime(newMaxDate)
                        }
                      }}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <div className="muidatepicker" style={{width: '96%'}}>
                    <LocalizationProvider dateAdapter={AdapterLuxon}>
                      <DemoContainer components={['DateTimePicker']}>
                        <MuiDatePicker
                          value={displayDateValue}
                          minDateTime={scheduleMinDateTime}
                          maxDateTime={scheduleMaxDateTime}
                          onChange={newValue => {
                            let sdate = new Date(
                              displayDateValue.c.year +
                                '-' +
                                displayDateValue.c.month +
                                '-' +
                                displayDateValue.c.day +
                                ' ' +
                                displayDateValue.c.hour +
                                ':' +
                                displayDateValue.c.minute +
                                ':' +
                                displayDateValue.c.second
                            )
                            let edate = new Date(
                              newValue.c.year +
                                '-' +
                                newValue.c.month +
                                '-' +
                                newValue.c.day +
                                ' ' +
                                newValue.c.hour +
                                ':' +
                                newValue.c.minute +
                                ':' +
                                newValue.c.second
                            )
                            let diff = (edate.getTime() - sdate.getTime()) / 1000
                            diff /= 60
                            let diffInMinutes = Math.abs(Math.round(diff))
                            let newutcValue = ''
                            if (sdate.getTime() >= edate.getTime()) {
                              newutcValue = storedUTCDateValue.minus({minutes: diffInMinutes})
                            } else {
                              newutcValue = storedUTCDateValue.plus({minutes: diffInMinutes})
                            }
                            setDisplayDateValue(newValue)
                            setStoredUTCDateValue(newutcValue)
                          }}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </div>
                </Grid>
                <Grid item xs={12} md={12} lg={12} className="pb-25">
                  <div className="templateButtonContainerWithPreview flex-row-reverse">
                    <button disabled={sending} className="sendNowButton" onClick={handleSubmit}>
                      Schedule Now
                    </button>
                    <button disabled={sending} className="previewButton" onClick={previewMessage}>
                      Preview Message
                    </button>
                  </div>
                </Grid>
              </Grid>
            </>
          )}
          {/* SMS body and inputs END */}
        </Grid>
      </Grid>
      <BootstrapDialog
        onClose={handleImportModalclose}
        aria-labelledby="customized-dialog-title-1"
        open={opencontactModal}
        className={updateModalSize ? 'importContactInModal extendModalSize' : 'importContactInModal '}
      >
        <DialogContent dividers>
          <Grid container>
            <Grid
              item
              xs={12}
              md={12}
              lg={12}
              spacing={2}
              className="dialogTitle-component import-contact-header-grid popup-inner"
            >
              Upload Contacts
              <button id="mypopup" className="popupElementButton close-btn" onClick={handleImportModalclose}>
                <span className="templateButtonText">X</span>
              </button>
            </Grid>
            <Grid item xs={12} md={12} lg={12} spacing={2}>
              <Importcontacts
                componentCalledSource={campaign.isBoth ? 'sms_emailChannel' : 'emailChannel'}
                getUploadedContactData={getUploadedContactData}
                setUpdateModalSize={setUpdateModalSize}
              />
            </Grid>
          </Grid>
        </DialogContent>
      </BootstrapDialog>

      <BootstrapDialog
        onClose={() => setOpenPreviewModal(false)}
        aria-labelledby="customized-dialog-title-1"
        open={openPreviewModal}
      >
        <DialogContent dividers>
          <Grid container>
            <Grid
              item
              xs={12}
              md={12}
              lg={12}
              spacing={2}
              className="dialogTitle-component import-contact-header-grid popup-inner"
            >
              Preview Details
              <button id="mypopup" className="popupElementButton close-btn" onClick={() => setOpenPreviewModal(false)}>
                <span className="templateButtonText">X</span>
              </button>
            </Grid>
            <Grid item xs={12} md={12} lg={12} spacing={2}>
              <PreviewMessage
                campaign={previewCampaignData}
                isOpen={openPreviewModal}
                closeModal={() => setOpenPreviewModal(false)}
              />
            </Grid>
          </Grid>
        </DialogContent>
      </BootstrapDialog>

      <CustomPopup trigger={buttonPopup} setTrigger={setButtonPopup}>
        <h3 className="popupTitle">Please select your SMS Template</h3>
        <select value={template} onChange={event => setTemplate(event.target.value)} className="popupDropdown">
          <option value="" disabled>
            Select Template
          </option>
          {data
            .filter(template => template.type === SEND_THROUGH_EMAIL_VALUE)
            .map(template => (
              <option key={template.id} value={template.id}>
                {template.templatename}
              </option>
            ))}
        </select>
        <button disabled={template === ''} onClick={applyTemplate} className="sendNowButton">
          Submit
        </button>
      </CustomPopup>
      <CustomPopup trigger={smsButtonPopup} setTrigger={setSmsButtonPopup}>
        <h3 className="popupTitle">Please select your message template</h3>
        <select value={smsTemplate} className="popupDropdown" onChange={event => setSmsTemplate(event.target.value)}>
          <option value="" disabled>
            Select SMS Template
          </option>
          {data
            .filter(template => template.type !== SEND_THROUGH_EMAIL_VALUE)
            .map(template => (
              <option key={template.id} value={template.id}>
                {template.templatename}
              </option>
            ))}
        </select>
        <button className="sendNowButton" disabled={smsTemplate === ''} onClick={e => applyTemplate(e, true)}>
          Apply
        </button>
      </CustomPopup>
    </div>
  )
}
