import React, {createContext, useContext, useEffect, useState, useCallback} from 'react'
import {useHistory} from 'react-router-dom'
import axios from 'axios'
import CryptoJS from 'crypto-js'

import Cookies from 'universal-cookie'
const cookies = new Cookies()

export const StateContext = createContext(null)

// export function getToken() {
//   return Cookies.get('cs_token');
// }

// export function getUser() {
//   const userData = JSON.parse(Cookies.get('cs_user'));
//   return userData
// }

export function login(username, password) {
  return fetch(process.env.REACT_APP_API_BASEURL + '/login', {
    method: 'POST',
    headers: {
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      username: username,
      password: password
    })
  })
}

export const encryptEmail = email => {
  const encryptedEmail = CryptoJS.AES.encrypt(email, process.env.REACT_APP_ENCRYPT).toString()
  return encryptedEmail
}

export const decryptEmail = encryptedEmail => {
  const bytes = CryptoJS.AES.decrypt(encryptedEmail, process.env.REACT_APP_ENCRYPT)
  const decryptedEmail = bytes.toString(CryptoJS.enc.Utf8)
  return decryptedEmail
}

export default function AppStateProvider(props) {
  const history = useHistory()

  const [user, setUser] = useState(null)
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  // const [token, setToken] = React.useState(null);

  const token = cookies.get('cs_token') //getToken();
  useEffect(() => {
    const user = cookies.get('cs_user') // ? JSON.parse(cookies.get('cs_user')) : ''; //getUser();

    if (token && user) {
      setIsLoggedIn(true)
      setUser(user)
    } else {
      cookies.remove('cs_token')
      cookies.remove('cs_user')
      cookies.remove('cs_retoken')
      setIsLoggedIn(false)
      // history.replace('/');
    }
  }, [history])

  const signIn = useCallback(async (loginResponse, jsonResponse, verifyOTPResponse) => {
    setIsLoading(true)
    console.log(jsonResponse, 'jsonResponse')
    if (loginResponse.ok && jsonResponse) {
      setUser(jsonResponse.user)
      setIsLoggedIn(true)
      setIsLoading(false)

      // setToken(jsonResponse.token)
      cookies.set('cs_token', jsonResponse?.token)
      cookies.set('cs_retoken', jsonResponse?.refreshToken)
      cookies.set('cs_user', JSON.stringify(jsonResponse?.user))

      cookies.set('cs_isPopUp', verifyOTPResponse?.isPopUp)
    } else {
      alert(jsonResponse?.message)
      throw new Error(jsonResponse?.message)
    }
    return true
    /* return login(username, password).then(async (response) => {
      setIsLoading(false);
      const jsonResponse = await response.json();
      if (response.ok && jsonResponse) {
        setUser(jsonResponse.user);
        setIsLoggedIn(true);
        // setToken(jsonResponse.token)
        cookies.set("cs_token", jsonResponse.token);
        cookies.set("cs_retoken", jsonResponse.refreshToken);
        cookies.set("cs_user", JSON.stringify(jsonResponse.user));
      } else {
        alert(jsonResponse?.message);
        throw new Error(jsonResponse?.message);
      }
    }); */
  }, [])
  const refreshUserDetails = useCallback(() => {
    axios
      .post(`${process.env.REACT_APP_API_BASEURL}/userdetails`, null, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(res => {
        if (res.data.length > 0) {
          setUser(res.data[0])
          cookies.set('cs_user', JSON.stringify(res.data[0]))
        }
      })
      .catch(err => {
        console.log('errr', err)
      })
  }, [token])

  const signOut = useCallback(() => {
    setUser(null)
    setIsLoggedIn(false)
    cookies.remove('cs_token')
    cookies.remove('cs_user')
    cookies.remove('cs_retoken')
    cookies.remove('cs_isPopUp')

    history.replace('/')
  }, [])

  return (
    <StateContext.Provider
      value={{
        user,
        isLoggedIn,
        signIn,
        signOut,
        refreshUserDetails,
        isLoading,
        setIsLoading
      }}
    >
      {props.children}
    </StateContext.Provider>
  )
}

export function useAppState() {
  const context = useContext(StateContext)
  if (!context) {
    throw new Error('useAppState must be used within the AppStateProvider')
  }
  return context
}
