import './users.css'
import {GroupAddOutlined} from '@material-ui/icons'
import SuperAdmins from '../../superAdmins/SuperAdmins'
import Admins from '../../admins/Admins'
import Ambassadors from '../../ambassadors/Ambassadors'
import {useAppState} from '../../../state'
import Cookies from 'universal-cookie'
import axios from '../../../helpers/request'
import {useState} from 'react'
import {useEffect} from 'react'
import Companies from '../../companies/Companies'

export default function Users() {
  const {user: loggedUser} = useAppState()
  const [userDetails, setUserDetails] = useState([])

  const cookies = new Cookies()
  const userToken = cookies.get('cs_token')

  function getUserdetails(ab) {
    const a = {
      id: ab[0].id,
      role: ab[0].role,
      added_by: ab[0].added_by
    }

    axios
      .post(`${process.env.REACT_APP_API_BASEURL}/getusers`, a)
      .then(res => {
        if (res.data.length > 0) {
          setUserDetails(res.data)
        }
      })
      .catch(err => {
        console.log('errr', err)
      })
  }

  function userDetail() {
    axios
      .post(`${process.env.REACT_APP_API_BASEURL}/userdetails`, {
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      })
      .then(res => {
        if (res.data.length > 0) {
          getUserdetails(res.data)
        }
      })
      .catch(err => {
        console.log('errr', err)
      })
  }

  useEffect(() => {
    userDetail()
  }, [])

  return (
    <div>
      {/* <PopupExample /> */}
      <div className="userTables">
        {loggedUser && loggedUser.role === 'superadmin' && (
          <div className="dashboardbox">
            <div className="userTablesTop">
              <SuperAdmins />
            </div>
          </div>
        )}
        {loggedUser && loggedUser.role === 'superadmin' && (
          <div className="dashboardbox">
            <div className="userTablesTop">
              <Companies />
            </div>
          </div>
        )}
        {loggedUser && (loggedUser.role === 'superadmin' || loggedUser.role === 'admin') && (
          <div className="dashboardbox">
            <div className="userTablesBottom">
              <Admins userDetails={userDetails} />
            </div>
          </div>
        )}

        <div className="dashboardbox">
          <div className="userTablesBottom">
            <Ambassadors userDetails={userDetails} />
          </div>
        </div>
      </div>
    </div>
  )
}
