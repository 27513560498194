import {useState, useEffect} from 'react'
import {useLocation, useHistory, Link} from 'react-router-dom'

// import axios from "axios";
/* import or from '../images/or.svg' */
// import {templatesRows} from '../../dummyData';

import './login.css'
import logo from '../images/ProntobyKaptea.svg'
import graphic from '../images/logingraphic2023.svg'
import CustomPopup from '../Popup/CustomPopup'
import {decryptEmail, encryptEmail, useAppState} from '../../state'
import {useGoogleLogin} from '@moeindana/google-oauth'
import axios from 'axios'

import Swal from 'sweetalert2'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import Cookies from 'universal-cookie'
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone'
import MailIcon from '@mui/icons-material/Mail'

function Login() {
  const {signIn, user, isLoggedIn} = useAppState()

  const history = useHistory()
  const location = useLocation()

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [Guser, setGUser] = useState()
  const [profile, setProfile] = useState([])
  const [modelName, setModelName] = useState('')
  const [loginResponse, setLoginResponse] = useState('')
  const [loginJson, setLoginJson] = useState('')
  const [emailAddress, setEmailAddress] = useState('')
  const [otp, setOtp] = useState('')
  const [forgotPasswordPopup, setforgotPasswordPopup] = useState(false)
  const [step, setStep] = useState(0)
  const [fieldName, setFieldName] = useState('')
  const [phone, setPhoneNo] = useState('')
  const [countryCode, setCountryCode] = useState('')
  const [isChecked, setIsChecked] = useState(false)
  const [isMethodChange, setIsMethodChanges] = useState(false)
  /* const [loginVerify, setLoginVerify] = useState(false) */

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(location.search)
    const params = Object.fromEntries(urlSearchParams.entries())
    if (params && params['verification-success']) {
      history.push('/login')
      Swal.fire({
        title: 'Thank you for verifying your mail, you can now login',
        confirmButtonColor: '#3085d6'
      })
    }
  }, [])

  const cookies = new Cookies()

  const openForgotPasswordPopup = event => {
    event.preventDefault()
    setforgotPasswordPopup(true)
  }

  const closeForgotPasswordPopup = () => {
    setforgotPasswordPopup(false)
    setStep(0)
    setEmailAddress('')
    setOtp('')
    setPhoneNo('')
    setFieldName('')
    setModelName('')
  }

  const verifyEmail = (email, phone, fieldName, modelName) => {
    return fetch(process.env.REACT_APP_API_BASEURL + '/verify-email', {
      method: 'POST',
      headers: {
        'content-type': 'application/json'
      },
      body: JSON.stringify({
        email: email,
        phone: phone,
        type: fieldName,
        modelName: modelName
      })
    })
  }

  /**
   *
   * @param {*} email
   * @param {*} phone
   * @param {*} otp
   * @param {*} fieldName
   * @returns
   */
  const verifyOTP = (email, phone, otp, fieldName) => {
    return fetch(process.env.REACT_APP_API_BASEURL + '/verify-otp', {
      method: 'POST',
      headers: {
        'content-type': 'application/json'
      },
      body: JSON.stringify({
        email: email,
        otp: otp,
        phone: phone,
        type: fieldName
      })
    })
  }

  const goToNextStep = () => {
    // this function is for reset password
    // resetPassword param is for maintain condition based template while verifyEmail
    verifyEmail(emailAddress, '', '', 'resetPassword').then(async response => {
      const jsonResponse = await response.json()
      if (response.ok) {
        setStep(1)
      } else {
        if (jsonResponse?.message) {
          Swal.fire({
            title: jsonResponse.message,
            confirmButtonColor: '#3085d6'
          })
        }
        throw new Error(response?.message)
      }
    })
  }

  const verifyOtp = () => {
    // Below condition is for Two-factor authentication with email or phone number
    const type = fieldName === 'phoneVerify' ? 'phone' : 'email'
    if (otp) {
      verifyOTP(emailAddress || username, phone, otp, type).then(async response => {
        const jsonResponse = await response.json()

        if (response.ok) {
          closeForgotPasswordPopup()

          // This condition for check that verify-otp is for reset password or login.
          if (modelName === '') {
            history.push({
              pathname: '/passwordreset',
              state: {token: jsonResponse.token}
            })
          } else {
            const IsTrue = signIn(loginResponse, loginJson, jsonResponse)
            IsTrue && history.replace(location?.state?.from || {pathname: `/`})
          }
        } else {
          if (jsonResponse?.message) {
            Swal.fire({
              title: jsonResponse.message,
              confirmButtonColor: '#3085d6'
            })
          }
          /* throw new Error(response?.message) */
        }
      })
    } else {
      Swal.fire({
        title: `Please enter one time passcode we've sent you on you mail`,
        confirmButtonColor: '#3085d6'
      })
    }
  }

  const loginAPI = (username, phone = '', password) => {
    return fetch(process.env.REACT_APP_API_BASEURL + '/login', {
      method: 'POST',
      headers: {
        'content-type': 'application/json'
      },
      body: JSON.stringify({
        username: username,
        password: password,
        phone: phone
      })
    })
  }
  const getSMSOtp = async () => {
    const checkPhone = phone.replace(countryCode, '')

    if (phone !== '' && checkPhone !== '') {
      verifyEmail('', phone, fieldName, 'login').then(async response => {
        const jsonResponse = await response.json()

        if (response.ok) {
          setFieldName('phoneVerify')
        } else {
          if (jsonResponse?.message) {
            Swal.fire({
              title: jsonResponse.message,
              confirmButtonColor: '#3085d6'
            })
          }
          //throw new Error(response?.message);
        }
      })
    } else {
      Swal.fire({
        title: 'Please enter you phone number',
        confirmButtonColor: '#3085d6'
      })
    }
  }

  const tryOtherMethodWithPhone = async () => {
    const checkPhone = phone.replace(countryCode, '')

    if (phone !== '' && checkPhone !== '') {
      verifyEmail('', phone, 'phone', 'login').then(async response => {
        const jsonResponse = await response.json()

        if (response.ok) {
          setFieldName('phoneVerify')
        } else {
          if (jsonResponse?.message) {
            Swal.fire({
              title: jsonResponse.message,
              confirmButtonColor: '#3085d6'
            })
          }
          //throw new Error(response?.message);
        }
      })
    } else {
      Swal.fire({
        title: 'Please enter you phone number',
        confirmButtonColor: '#3085d6'
      })
    }
  }

  /**
   * This function is used for login user
   * @param {*} event
   */
  const login = event => {
    event.preventDefault()
    loginAPI(username, phone, password).then(async response => {
      const LoginJsonResponse = await response.json()
      setLoginResponse(response)
      setLoginJson(LoginJsonResponse)
      if (response.ok && process.env.REACT_APP_DEVELOPMENT_ENV) {
        const IsTrue = signIn(response, LoginJsonResponse);
        IsTrue && history.replace(location?.state?.from || { pathname: `/` });
        return
      } 
      if (response.ok) {
        if (LoginJsonResponse?.isPhone === true) {
          setFieldName('phone')
        } else {
          setEmailAddress(username)
          setFieldName('email')
          verifyEmail(username, '', 'email', 'login').then(async response => {
            const jsonResponse = await response.json()

            if (response.ok) {
              /* setEmailAddress(username);
                setLoginVerify(true);
                setModelName("login"); */
              setFieldName('email')
            } else {
              if (jsonResponse?.message) {
                Swal.fire({
                  title: jsonResponse.message,
                  confirmButtonColor: '#3085d6'
                })
              }
              throw new Error(response?.message)
            }
          })
        }
      } else {
        Swal.fire({
          title: LoginJsonResponse.message,
          confirmButtonColor: '#3085d6'
        })
      }

      setModelName('login')
    })
  }

  const optionMethodMailVerify = async () => {
    verifyEmail(username, '', 'email', 'login').then(async response => {
      const jsonResponse = await response.json()

      if (response.ok) {
        /* setEmailAddress(username);
          setLoginVerify(true);
          setModelName("login"); */
        setFieldName('email')
        setIsMethodChanges(false)
      } else {
        if (jsonResponse?.message) {
          Swal.fire({
            title: jsonResponse.message,
            confirmButtonColor: '#3085d6'
          })
        }
        throw new Error(response?.message)
      }
    })
  }
  const getRememberMe = cookies.get('IsRememberMe')
  const rememberMeFunction = e => {
    if (username || password) {
      setIsChecked(e.target.checked)
      if (e.target.checked === true) {
        cookies.set('IsRememberMe', 1)
        const storeEmail = encryptEmail(username)
        const storePassword = encryptEmail(password)
        cookies.set('email', storeEmail)
        cookies.set('password', storePassword)
      } else {
        cookies.remove('IsRememberMe')
        cookies.remove('email')
        cookies.remove('password')
      }
    }
  }

  useEffect(() => {
    if (getRememberMe === '1') {
      setIsChecked(true)
      const getEmail = cookies.get('email')
      const getPassword = cookies.get('password')
      const originalEmail = decryptEmail(getEmail)
      const originalPassword = decryptEmail(getPassword)
      setUsername(originalEmail)
      setPassword(originalPassword)
    }
  }, [])

  const handleCredentialResponse = response => {
    signIn(response).then(response => {
      if (response.success) {
        history.replace(location?.state?.from || {pathname: `/`})
      }
    })
  }

  if (user || isLoggedIn) {
    history.replace('/')
    // navigate('/');
  }

  // useEffect(() => {
  //   /* global google */
  //   google.accounts.id.initialize({
  //     client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
  //     callback: handleCredentialResponse
  //   })

  //   google.accounts.id.renderButton(
  //     document.getElementById("gSigninButton"),
  //     { theme: 'outline', size: 'large' }
  //   )
  // }, []);

  const Glogin = useGoogleLogin({
    onSuccess: codeResponse => {
      signIn(codeResponse.email, codeResponse.id).then(() => {
        history.replace(location?.state?.from || {pathname: `/`})
      })
    },
    onError: error => console.log('Login Failed:', error)
  })
  useEffect(() => {
    if (user) {
      axios
        .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
          headers: {
            Authorization: `Bearer ${user.access_token}`,
            Accept: 'application/json'
          }
        })
        .then(res => {
          setProfile(res.data)
        })
        .catch(err => console.log(err))
    }
  }, [Guser])

  const logOut = () => {
    // googleLogout();
    setProfile(null)
  }

  return (
    <>
      <div className="userlogin">
        <div className="userLoginContainer">
          <div className="userloginLeft">
            <span className="logoadmin">
              <div className="logoadminbottom">
                <img src={logo} alt="" className="logo" />
                <img src={graphic} className="logo-two" alt="" />
              </div>
            </span>
          </div>
          <div className="userLoginRight">
            <div className="pageHeaderLogin">
              <div className="pageHeaderText">
                <span className="pageHeaderTitle">Login</span>
                <span className="pageHeaderDescription">Welcome back, please enter your details to login.</span>
              </div>
            </div>
            <form className="loginForm">
              <div className="loginItem">
                <label>Email</label>
                <input
                  type="email"
                  placeholder="Enter your email address"
                  value={username}
                  onChange={e => {
                    setIsChecked(false)
                    cookies.remove('IsRememberMe')
                    cookies.remove('email')
                    cookies.remove('password')
                    setUsername(e.target.value)
                  }}
                />
              </div>

              <div className="loginItem">
                <label>Password</label>
                <input
                  type="password"
                  placeholder="xxxxxxxxxx"
                  value={password}
                  onChange={e => {
                    setIsChecked(false)
                    cookies.remove('IsRememberMe')
                    cookies.remove('email')
                    cookies.remove('password')
                    setPassword(e.target.value)
                  }}
                />

                <div className="resetoptionsContainer">
                  <div class="checkbox-container">
                    <input
                      type="checkbox"
                      id="checkbox"
                      name="rememberMe"
                      checked={isChecked === true ? 'checked' : ''}
                      disabled={!username && !password}
                      style={{
                        cursor: !username && !password ? 'default' : 'pointer'
                      }}
                      onChange={e => {
                        rememberMeFunction(e)
                      }}
                    />
                    <label
                      for="checkbox"
                      class="round"
                      style={{
                        cursor: !username && !password ? 'default' : 'pointer'
                      }}
                    ></label>
                    <span style={{marginLeft: '10px', color: '#3e3e3e'}}>Remember me</span>
                  </div>

                  <div className="resetoptionsRight">
                    <button type="button" className="forgotpasswordbtn" onClick={openForgotPasswordPopup}>
                      Forgot Password?
                    </button>
                  </div>
                </div>
                <button disabled={username === '' && password === ''} className="loginButton" onClick={login}>
                  Login
                </button>
              </div>
            </form>
            {/* <div className="divider">
              <img src={or} alt="" />
            </div> */}

            {/* <div class="google-btn">
  <div class="google-icon-wrapper">
    <img class="google-icon" src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg"/>
  </div>
  <p class="btn-text" onClick={() => Glogin()}>Login with Google</p>
</div> */}

            {/* <div className="resetoptionsRight">
              <Link type="submit" className="needsanaccount" to={"/signup"}>
                Dont have an account? <span>Sign Up</span>
              </Link>
            </div> */}
          </div>
        </div>

        <CustomPopup trigger={forgotPasswordPopup} setTrigger={() => closeForgotPasswordPopup()}>
          {step === 0 ? (
            <>
              <h3 className="popupTitle">Please enter your email address</h3>
              <input
                value={emailAddress}
                onChange={e => {
                  setEmailAddress(e.target.value)
                }}
                type="email"
                className="popupText"
                placeholder="Example: John@gmail.com"
              />
              <button
                disabled={emailAddress === ''}
                className="loginButton"
                onClick={emailAddress === '' ? () => {} : goToNextStep}
              >
                Next
              </button>
            </>
          ) : step === 1 ? (
            <>
              <h3 className="popupTitle">Please check your email, we have just sent you a one time pass code</h3>
              <input
                value={otp}
                onChange={e => {
                  setOtp(e.target.value)
                }}
                type="text"
                className="popupText"
                placeholder="xxxxxx"
              />
              <button disabled={otp === ''} className="loginButton" onClick={otp === '' ? () => {} : verifyOtp}>
                Verify
              </button>
            </>
          ) : (
            ''
          )}
        </CustomPopup>

        <CustomPopup
          trigger={fieldName === 'email' || fieldName === 'phoneVerify' ? true : false}
          setTrigger={() => closeForgotPasswordPopup()}
        >
          <>
            <h2 style={{marginBottom: '30px'}}>Two-Factor Authentication</h2>
            <h5 className="popupTitle" style={{width: '550px', fontSize: '16px'}}>
              {`${
                fieldName === 'phoneVerify'
                  ? `Your account is protected with two-factor authentication. Please enter One Time Password(OTP) that has been sent to a phone number ending in ${phone.substring(
                      phone.length - 3
                    )}`
                  : "Your account is protected with two-factor authentication. Please check your email for a one-time passcode we've just sent."
              }`}
            </h5>
            <label style={{color: 'black', fontWeight: 'bold'}}>
              <span style={{color: 'red'}}>*</span>Two-Factor Code
            </label>
            <input
              value={otp}
              onChange={e => {
                setOtp(e.target.value)
              }}
              type="text"
              className="popupText"
              placeholder="xxxxxx"
            />
            <button
              disabled={otp === ''}
              className="loginButton"
              style={{
                opacity: otp === '' ? '0.4' : 1,
                cursor: otp === '' ? 'default' : 'pointer'
              }}
              onClick={otp === '' ? () => {} : verifyOtp}
            >
              Verify
            </button>
            {fieldName === 'phoneVerify' && (
              <div className="try-btn-p">
                <button type="button" className="try-button" onClick={() => setIsMethodChanges(!isMethodChange)}>
                  Try another method
                </button>
              </div>
            )}
          </>
        </CustomPopup>

        <CustomPopup trigger={isMethodChange} setTrigger={() => setIsMethodChanges(!isMethodChange)}>
          <div className="identity-cls">
            <div className="identity-cls-one">
              <div className="identity-back" onClick={() => setIsMethodChanges(!isMethodChange)}>{`<`}</div>
              <div className="identity-text">Select a method to verify your identity</div>
            </div>
            <div className="identity-option">
              <div>
                <div
                  className="identity-phone"
                  onClick={() => {
                    setIsMethodChanges(!isMethodChange)
                    tryOtherMethodWithPhone()
                  }}
                >
                  <PhoneIphoneIcon style={{marginRight: '15px'}} />
                  Phone
                  <div className="phone-method">{`>`}</div>
                </div>
              </div>

              <div className="identity-email" onClick={() => optionMethodMailVerify()}>
                <MailIcon style={{marginRight: '15px'}} />
                Email
                <div className="email-method">{`>`}</div>
              </div>
            </div>
          </div>
        </CustomPopup>

        <CustomPopup trigger={fieldName === 'phone' ? true : false} setTrigger={() => closeForgotPasswordPopup()}>
          <>
            <h3 className="popupTitle">Please Enter your Phone number to get One Time Password(OTP)</h3>
            <div className="twoFaFields" style={{display: 'flex', flexDirection: 'column'}}>
              <label className="twoFa">Phone</label>
              <PhoneInput
                inputStyle={{
                  width: '600px',
                  height: '50px'
                }}
                country={'ie'}
                value={phone}
                onChange={(data, country) => {
                  setPhoneNo(data)
                  setCountryCode(country?.dialCode)
                }}
              />
            </div>

            <button
              className="loginButton"
              disabled={phone === ''}
              style={{
                opacity: phone === '' ? '0.4' : 1,
                cursor: phone === '' ? 'default' : 'pointer'
              }}
              onClick={() => getSMSOtp()}
            >
              Send OTP
            </button>
          </>
        </CustomPopup>
      </div>
    </>
  )
}

export default Login
