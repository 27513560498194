import './channel.css'
import React, {useState, useEffect} from 'react'
import {useParams, useLocation, Link} from 'react-router-dom'
import {ScheduleOutlined, KeyboardReturnOutlined} from '@material-ui/icons'
import axios from '../../../helpers/request'
import {Breadcrumbs, Typography} from '@material-ui/core'
import Swal from 'sweetalert2'

export default function SingleChannelAuth() {
  const [channelData, setData] = useState({
    sid: '',
    token: '',
    label: '',
    type: '',
    fromdata: ''
  })

  const {channelId} = useParams()
  const location = useLocation()
  const isEditable = location.pathname.split('/').includes('edit')

  const [adding, setAdding] = useState(false)

  const getChannelDetails = channelId => {
    axios.get('/channel/' + channelId).then(async response => {
      if (response.status === 200 && response.data && response.data.id) {
        setData(response.data)
      } else {
        throw new Error(response?.error)
      }
    })
  }

  useEffect(() => {
    getChannelDetails(channelId)
  }, [channelId])

  const handleChange = event => {
    const {name, value} = event.target
    setData(channelData => ({
      ...channelData,
      [name]: value
    }))
  }

  const isValidData = () => {
    const {sid, token, label, type} = channelData
    let isValid = true
    let message = ''
    if (sid === '' || token === '' || label === '' || type === '') {
      isValid = false
      message = 'All fields are mandatory'
    }
    return {isValid, message}
  }

  const handleSubmit = event => {
    event.preventDefault()
    const {isValid, message} = isValidData()
    if (isValid) {
      setAdding(true)
      axios
        .put('/channel/' + channelId, {data: channelData})
        .then(function (response) {
          setAdding(false)
          getChannelDetails(channelId)
          Swal.fire({title: 'Channel updated successfully', confirmButtonColor: '#3085d6'})
        })
        .catch(function (error) {
          setAdding(false)
          Swal.fire({title: error?.response?.data?.message || 'Unable to process this request', confirmButtonColor: '#3085d6'})
        })
    } else {
      Swal.fire({title: message, confirmButtonColor: '#3085d6'})
    }
  }

  return (
    <div>
      <Breadcrumbs aria-label="breadcrumb">
        <Link to="/Channels">Channels</Link>
        <Link to={'/channel-edit/' + channelId}>Edit Channel</Link>
        <Typography color="text.primary">Edit Auth Details</Typography>
      </Breadcrumbs>
      <form className="newUserForm">
        <h2>Channel Information</h2>

        {channelData.type !== 'sendgrid-mail-service' ? (
          <div className="newUserItem">
            <label>
              Account SID <span className="mandatory">*</span>
            </label>
            <input
              disabled={true}
              value={channelData.sid}
              onChange={handleChange}
              name="sid"
              type="text"
              placeholder="Account SID"
            />
          </div>
        ) : null}
        <div className="newUserItem">
          <label>
            Auth Token <span className="mandatory">*</span>
          </label>
          <input value={channelData.token} onChange={handleChange} name="token" type="text" placeholder="Auth Token" />
        </div>
        <div className="newUserItem">
          <button disabled={adding} onClick={handleSubmit} className="newChannelButton align-self-end">
            Update
          </button>
        </div>
      </form>
    </div>
  )
}
