import './logs.css'
import {DataGrid} from '@material-ui/data-grid'
import {DashboardOutlined} from '@material-ui/icons'
import {useState, useEffect} from 'react'
import axios from '../../../helpers/request'
import {useAppState} from '../../../state'

export default function Logs() {
  const [data, setData] = useState({
    data: [],
    total: 0
  })
  const [pageSize, setPageSize] = useState(25)
  const [pageNumber, setPageNumber] = useState(1)
  const {user} = useAppState()

  const getLogs = () => {
    console.log('USER::', user)
    let queryParams = {
      companyId: user.added_by ? user.added_by : user.id
    }
    setData({
      data: [],
      total: 0
    })
    let searchParams = {
      page_number: pageNumber,
      page_size: pageSize
    }
    axios.get('/logs', {params: searchParams}).then(async response => {
      if (response.status === 200 && response.data) {
        setData(response.data)
      } else {
        throw new Error(response?.error)
      }
    })
  }

  useEffect(() => {
    getLogs()
  }, [pageSize, pageNumber])

  const [sortModel, setSortModel] = useState([
    {
      field: 'id',
      sort: 'desc'
    }
  ])

  const columns = [
    {field: 'id', headerName: 'ID', width: 80, sortable: false},
    {field: 'fullName', headerName: 'Name', width: 150, sortable: false},
    {
      field: 'date',
      headerName: 'Date',
      width: 130,
      sortable: true,
      type: 'date',
      valueGetter: ({value}) => value && new Date(value),
      options: {sortDirection: 'desc'}
    },
    {field: 'time', headerName: 'Time', width: 130, sortable: false},
    {
      field: 'action',
      sortable: false,
      headerName: 'Action Taken',
      flex: 1
    }
  ]

  return (
    <div>
      <DataGrid
        columns={columns}
        rows={data.data}
        rowCount={data.total}
        pageSize={pageSize}
        rowsPerPageOptions={[10, 25, 50]}
        onPageSizeChange={newPageSize => setPageSize(newPageSize)}
        onPageChange={newPage => setPageNumber(newPage + 1)}
        pagination
        paginationMode="server"
        checkboxSelection
        disableSelectionOnClick
        autoHeight={true}
      />
    </div>
  )
}
