import axios from '../../helpers/request'
import './header.css'
import {DataGrid} from '@material-ui/data-grid'
import {
  DeleteOutlined,
  EditOutlined,
  VisibilityOutlined,
  AddOutlined,
  LayersOutlined,
  NotificationsNone
} from '@material-ui/icons'
import {Link} from 'react-router-dom'
import {useState, useEffect} from 'react'
import {useAppState} from '../../state'
import logoutIcon from '../../components/images/logoutivon.svg'
import settingsIcon from '../../components/images/settings.svg'
import profileIcon from '../../components/images/profile.svg'
import helpIcon from '../../components/images/help.svg'
import pricingIcon from '../../components/images/pricingicon.svg'
import feedbackIcon from '../../components/images/feedback.svg'
import channelIcon from '../../components/images/channels.svg'
import activityIcon from '../../components/images/activity.svg'
import userIcon from '../../components/images/usermainicon.svg'

export default function Headermenus() {
  const {user, signOut} = useAppState()

  return (
    <div className="headerMenus">
      <ul>
        <li className="header-submenu-clickable">
          <Link className="header-submenu-clickable" to="/profile">
            {' '}
            <img src={profileIcon} className="filter-red header-submenu-clickable" />
            Profile
          </Link>
        </li>
        {/* <li className='header-submenu-clickable'><Link className='header-submenu-clickable' to="/Channels"> <img src={channelIcon} className="filter-red header-submenu-clickable" />Channels</Link></li>  */}
        <li className="header-submenu-clickable">
          <Link className="header-submenu-clickable" to="/Logs">
            {' '}
            <img src={activityIcon} className="filter-red header-submenu-clickable" />
            Activity Log
          </Link>
        </li>
        <li className="header-submenu-clickable">
          <Link className="header-submenu-clickable" to="/users">
            {' '}
            <img src={userIcon} className="filter-red header-submenu-clickable" />
            User Management
          </Link>
        </li>
        {/* <li className='header-submenu-clickable'><Link className='header-submenu-clickable' to="/plans"> <img src={pricingIcon} className="filter-red header-submenu-clickable" />Pricing</Link></li> */}
        <li className="header-submenu-clickable">
          <Link className="header-submenu-clickable" to="/support">
            {' '}
            <img src={helpIcon} className="filter-red header-submenu-clickable" />
            Help & Support
          </Link>
        </li>
        <li className="header-submenu-clickable">
          <Link className="header-submenu-clickable" to="/settings">
            {' '}
            <img src={settingsIcon} className="filter-red header-submenu-clickable" />
            Settings
          </Link>
        </li>
        <li className="header-submenu-clickable" onClick={signOut}>
          <a className="header-submenu-clickable">
            <img src={logoutIcon} className="filter-red header-submenu-clickable" /> logout
          </a>
        </li>
      </ul>
    </div>
  )
}
