import './importcontacts.css'
import React, {useState} from 'react'

import copyIcon from '../../../images/copy-paste.svg'

export default function DragDropFile(props) {
  const [files, setFiles] = useState([])

  const handleDrop = event => {
    event.preventDefault()
    const {files} = event.dataTransfer
    if (files.length > 0) {
      setFiles([...files])
      props.setFile(files[0])
    }
  }

  const handleDragOver = event => {
    event.preventDefault()
  }

  const handleDragStart = event => {
    event.dataTransfer.setData('text/plain', event.target.id)
  }

  return (
    <>
      <div className="import-contact-p" onDragOver={handleDragOver} onDrop={handleDrop}>
        <img src={copyIcon} className="folder-icon" />
        &nbsp;&nbsp;Drag and drop file here
      </div>
    </>
  )
}
