import CustomImageUploadAdapter from './ckeditorImageUploadAdaptor'

export const ckeditorConfig = {
  extraPlugins: [CustomImageUploadAdapter],
  // https://ckeditor.com/docs/ckeditor5/latest/features/toolbar/toolbar.html#extended-toolbar-configuration-format
  toolbar: {
    items: [
      'exportPDF',
      'exportWord',
      '|',
      'findAndReplace',
      'selectAll',
      '|',
      'heading',
      '|',
      'bold',
      'italic',
      'strikethrough',
      'underline',
      'code',
      'subscript',
      'superscript',
      'removeFormat',
      '|',
      'bulletedList',
      'numberedList',
      'todoList',
      '|',
      'outdent',
      'indent',
      '|',
      'undo',
      'redo',
      '-',
      'fontSize',
      'fontFamily',
      'fontColor',
      'fontBackgroundColor',
      'highlight',
      '|',
      'alignment',
      '|',
      'link',
      'insertImage',
      'blockQuote',
      'insertTable',
      'mediaEmbed',
      'codeBlock',
      'htmlEmbed',
      '|',
      'specialCharacters',
      'horizontalLine',
      'pageBreak',
      '|',
      'textPartLanguage',
      '|',
      'sourceEditing'
    ],
    shouldNotGroupWhenFull: true
  },
  // Changing the language of the interface requires loading the language file using the <script> tag.
  // language: 'es',
  list: {
    properties: {
      styles: true,
      startIndex: true,
      reversed: true
    }
  },
  // https://ckeditor.com/docs/ckeditor5/latest/features/headings.html#configuration
  heading: {
    options: [
      {model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph'},
      {model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1'},
      {model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2'},
      {model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3'},
      {model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4'},
      {model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5'},
      {model: 'heading6', view: 'h6', title: 'Heading 6', class: 'ck-heading_heading6'}
    ]
  },
  // https://ckeditor.com/docs/ckeditor5/latest/features/editor-placeholder.html#using-the-editor-configuration
  placeholder: '',
  // https://ckeditor.com/docs/ckeditor5/latest/features/font.html#configuring-the-font-family-feature
  fontFamily: {
    options: [
      'default',
      'Arial, Helvetica, sans-serif',
      'Courier New, Courier, monospace',
      'Georgia, serif',
      'Lucida Sans Unicode, Lucida Grande, sans-serif',
      'Tahoma, Geneva, sans-serif',
      'Times New Roman, Times, serif',
      'Trebuchet MS, Helvetica, sans-serif',
      'Verdana, Geneva, sans-serif'
    ],
    supportAllValues: true
  },
  // https://ckeditor.com/docs/ckeditor5/latest/features/font.html#configuring-the-font-size-feature
  fontSize: {
    options: [10, 12, 14, 'default', 18, 20, 22],
    supportAllValues: true
  },
  // Be careful with the setting below. It instructs CKEditor to accept ALL HTML markup.
  // https://ckeditor.com/docs/ckeditor5/latest/features/general-html-support.html#enabling-all-html-features
  htmlSupport: {
    allow: [
      {
        name: /.*/,
        attributes: true,
        classes: true,
        styles: true
      }
    ]
  },
  // Be careful with enabling previews
  // https://ckeditor.com/docs/ckeditor5/latest/features/html-embed.html#content-previews
  htmlEmbed: {
    showPreviews: true
  },
  // https://ckeditor.com/docs/ckeditor5/latest/features/link.html#custom-link-attributes-decorators
  link: {
    decorators: {
      addTargetToExternalLinks: true,
      defaultProtocol: 'https://',
      toggleDownloadable: {
        mode: 'manual',
        label: 'Downloadable',
        attributes: {
          download: 'file'
        }
      }
    }
  },
  // https://ckeditor.com/docs/ckeditor5/latest/features/mentions.html#configuration
  mention: {
    feeds: [
      {
        marker: '@',
        feed: [
          '@apple',
          '@bears',
          '@brownie',
          '@cake',
          '@cake',
          '@candy',
          '@canes',
          '@chocolate',
          '@cookie',
          '@cotton',
          '@cream',
          '@cupcake',
          '@danish',
          '@donut',
          '@dragée',
          '@fruitcake',
          '@gingerbread',
          '@gummi',
          '@ice',
          '@jelly-o',
          '@liquorice',
          '@macaroon',
          '@marzipan',
          '@oat',
          '@pie',
          '@plum',
          '@pudding',
          '@sesame',
          '@snaps',
          '@soufflé',
          '@sugar',
          '@sweet',
          '@topping',
          '@wafer'
        ],
        minimumCharacters: 1
      }
    ]
  },
  // The "super-build" contains more premium features that require additional configuration, disable them below.
  licenseKey: 'MUhJZGJuTWlIbFZQUzM4bkJia3JRamkwYjU4eWk1akZnQzRicTVpak5VcjhPOG9Oc0o2NklUYjBBTlNtLU1qQXlNekV4TVRrPQ==',

  // Do not turn them on unless you read the documentation and know how to configure them and setup the editor.
  removePlugins: [
    // These two are commercial, but you can try them out without registering to a trial.
    // 'ExportPdf',
    // 'ExportWord',
    'CKBox',
    'CKFinder',
    'EasyImage',
    // This sample uses the Base64UploadAdapter to handle image uploads as it requires no configuration.
    // https://ckeditor.com/docs/ckeditor5/latest/features/images/image-upload/base64-upload-adapter.html
    // Storing images as Base64 is usually a very bad idea.
    // Replace it on production website with other solutions:
    // https://ckeditor.com/docs/ckeditor5/latest/features/images/image-upload/image-upload.html
    'Base64UploadAdapter',
    'RealTimeCollaborativeComments',
    'RealTimeCollaborativeTrackChanges',
    'RealTimeCollaborativeRevisionHistory',
    'PresenceList',
    'Comments',
    'TrackChanges',
    'TrackChangesData',
    'RevisionHistory',
    'Pagination',
    'WProofreader',
    // Careful, with the Mathtype plugin CKEditor will not load when loading this sample
    // from a local file system (file://) - load this site via HTTP server if you enable MathType.
    'MathType',
    // The following features are part of the Productivity Pack and require additional license.
    'SlashCommand',
    'Template',
    'DocumentOutline',
    'FormatPainter',
    'TableOfContents',
    'PasteFromOfficeEnhanced'
  ],
  fontColor: {
    colors: [
      {color: '#000000', label: 'Black'},
      {color: '#4d4d4d', label: 'Dim grey'},
      {color: '#999999', label: 'Grey'},
      {color: '#e6e6e6', label: 'Light grey'},
      {color: '#ffffff', label: 'White', hasBorder: true},
      {color: '#e64c4c', label: 'Red'},
      {color: '#e6994c', label: 'Orange'},
      {color: '#e6e64c', label: 'Yellow'},
      {color: '#99e64c', label: 'Light green'},
      {color: '#4ce64c', label: 'Green'},
      {color: '#4ce699', label: 'Aquamarine'},
      {color: '#4ce6e6', label: 'Turquoise'},
      {color: '#4c99e6', label: 'Light blue'},
      {color: '#4c4ce6', label: 'Blue'},
      {color: '#994ce6', label: 'Purple'}
    ],
    colorPicker: {
      format: 'hex'
    }
  },
  fontBackgroundColor: {
    colors: [
      {color: '#000000', label: 'Black'},
      {color: '#4d4d4d', label: 'Dim grey'},
      {color: '#999999', label: 'Grey'},
      {color: '#e6e6e6', label: 'Light grey'},
      {color: '#ffffff', label: 'White', hasBorder: true},
      {color: '#e64c4c', label: 'Red'},
      {color: '#e6994c', label: 'Orange'},
      {color: '#e6e64c', label: 'Yellow'},
      {color: '#99e64c', label: 'Light green'},
      {color: '#4ce64c', label: 'Green'},
      {color: '#4ce699', label: 'Aquamarine'},
      {color: '#4ce6e6', label: 'Turquoise'},
      {color: '#4c99e6', label: 'Light blue'},
      {color: '#4c4ce6', label: 'Blue'},
      {color: '#994ce6', label: 'Purple'}
    ],
    colorPicker: {
      format: 'hex'
    }
  },
  table: {
    tableCellProperties: {
      borderColors: [
        {color: '#000000', label: 'Black'},
        {color: '#4d4d4d', label: 'Dim grey'},
        {color: '#999999', label: 'Grey'},
        {color: '#e6e6e6', label: 'Light grey'},
        {color: '#ffffff', label: 'White', hasBorder: true},
        {color: '#e64c4c', label: 'Red'},
        {color: '#e6994c', label: 'Orange'},
        {color: '#e6e64c', label: 'Yellow'},
        {color: '#99e64c', label: 'Light green'},
        {color: '#4ce64c', label: 'Green'},
        {color: '#4ce699', label: 'Aquamarine'},
        {color: '#4ce6e6', label: 'Turquoise'},
        {color: '#4c99e6', label: 'Light blue'},
        {color: '#4c4ce6', label: 'Blue'},
        {color: '#994ce6', label: 'Purple'}
      ],
      backgroundColors: [
        {color: '#000000', label: 'Black'},
        {color: '#4d4d4d', label: 'Dim grey'},
        {color: '#999999', label: 'Grey'},
        {color: '#e6e6e6', label: 'Light grey'},
        {color: '#ffffff', label: 'White', hasBorder: true},
        {color: '#e64c4c', label: 'Red'},
        {color: '#e6994c', label: 'Orange'},
        {color: '#e6e64c', label: 'Yellow'},
        {color: '#99e64c', label: 'Light green'},
        {color: '#4ce64c', label: 'Green'},
        {color: '#4ce699', label: 'Aquamarine'},
        {color: '#4ce6e6', label: 'Turquoise'},
        {color: '#4c99e6', label: 'Light blue'},
        {color: '#4c4ce6', label: 'Blue'},
        {color: '#994ce6', label: 'Purple'}
      ]
    },
    tableProperties: {
      borderColors: [
        {color: '#000000', label: 'Black'},
        {color: '#4d4d4d', label: 'Dim grey'},
        {color: '#999999', label: 'Grey'},
        {color: '#e6e6e6', label: 'Light grey'},
        {color: '#ffffff', label: 'White', hasBorder: true},
        {color: '#e64c4c', label: 'Red'},
        {color: '#e6994c', label: 'Orange'},
        {color: '#e6e64c', label: 'Yellow'},
        {color: '#99e64c', label: 'Light green'},
        {color: '#4ce64c', label: 'Green'},
        {color: '#4ce699', label: 'Aquamarine'},
        {color: '#4ce6e6', label: 'Turquoise'},
        {color: '#4c99e6', label: 'Light blue'},
        {color: '#4c4ce6', label: 'Blue'},
        {color: '#994ce6', label: 'Purple'}
      ],
      backgroundColors: [
        {color: '#000000', label: 'Black'},
        {color: '#4d4d4d', label: 'Dim grey'},
        {color: '#999999', label: 'Grey'},
        {color: '#e6e6e6', label: 'Light grey'},
        {color: '#ffffff', label: 'White', hasBorder: true},
        {color: '#e64c4c', label: 'Red'},
        {color: '#e6994c', label: 'Orange'},
        {color: '#e6e64c', label: 'Yellow'},
        {color: '#99e64c', label: 'Light green'},
        {color: '#4ce64c', label: 'Green'},
        {color: '#4ce699', label: 'Aquamarine'},
        {color: '#4ce6e6', label: 'Turquoise'},
        {color: '#4c99e6', label: 'Light blue'},
        {color: '#4c4ce6', label: 'Blue'},
        {color: '#994ce6', label: 'Purple'}
      ]
    }
  }
}
