import * as React from 'react'
import {useState, useEffect} from 'react'
import Cookies from 'universal-cookie'

import clsx from 'clsx'
import {makeStyles, useTheme, withStyles} from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Drawer from '@material-ui/core/Drawer'
import CssBaseline from '@material-ui/core/CssBaseline'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import Collapse from '@material-ui/core/Collapse'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Tooltip from '@material-ui/core/Tooltip'

import Headermenus from '../headermenus/Headermenus'
import logoutIcon from '../../components/images/logoutivon.svg'
import Headernotifications from '../headermenus/HeaderNotifications'
import Headermessages from '../headermenus/HeaderMessages'
import {monthlyPaymentArray} from '../../helpers/paymentArray'

import './sidebar.css'

import {
  ExitToAppOutlined,
  DirectionsWalkOutlined,
  DashboardOutlined,
  Close,
  GroupOutlined,
  BarChartOutlined,
  GroupAddOutlined,
  ScheduleOutlined,
  SendOutlined,
  MailOutlineOutlined,
  LayersOutlined,
  Euro,
  NotificationsNoneOutlined,
  ExpandMore,
  ExpandLess
} from '@material-ui/icons'
import {Link, useLocation} from 'react-router-dom'
import logo from '../../components/images/ProntobyKaptea.svg'
import icon from '../../components/images/icon.svg'
import companyLogo from '../../components/images/icon.svg'
import notificationsbell from '../../components/images/notificationbell.svg'
import messagesbell from '../../components/images/messagesbell.svg'

import {useAppState} from '../../state'
import {setOptions} from 'highcharts'
import HeaderNotifications from '../headermenus/HeaderNotifications'
import HeaderMessages from '../headermenus/HeaderMessages'

import LinearProgress from '@material-ui/core/LinearProgress'

const BorderLinearProgress = withStyles(theme => ({
  root: {
    height: 10,
    borderRadius: 5
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 300 : 800]
  },
  bar: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#004487' : '#308fe8'
  }
}))(LinearProgress)

const ListItemBody = ({config}) => {
  return (
    <>
      {/* <Tooltip title={config.tooltipContent} placement="right"> */}
      <ListItemIcon>{config.icon}</ListItemIcon>
      <ListItemText primary={config.title} />
      {/* </Tooltip> */}
    </>
  )
}

const MenuItem = ({config, currentPath}) => {
  return (
    <Tooltip title={config.tooltipContent ? config.tooltipContent : ''} placement="right">
      <Link to={config.path} key={config.title} id={config.id} className={'sidebar-' + config.id}>
        <ListItem button className={currentPath === config.path ? 'active ' : ''}>
          <ListItemBody config={config} />
        </ListItem>
      </Link>
    </Tooltip>
  )
}

const ExpandableMenuItem = React.memo(({config, currentPath, handleClick, menuId}) => {
  const [open, setOpen] = useState(false)
  let activeNavClass = 'nav ' + config.id + '-menu'
  let nonactiveNavClass = 'nav normal-' + config.id + '-menu'
  return (
    <div className={config.includedpaths.includes(currentPath) ? activeNavClass : nonactiveNavClass}>
      <Tooltip title={config.tooltipContent ? config.tooltipContent : ''} placement="right" className="mytooltip">
        <ListItem
          button
          onClick={() => handleClick(config.id)}
          id={config.id}
          className={config.includedpaths.includes(currentPath) ? 'active' : ''}
        >
          <ListItemBody config={config} />
          {config.id === menuId ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
      </Tooltip>
      <Collapse in={config.id === menuId} timeout="auto" unmountOnExit>
        {config.items.map(route => {
          return <MenuItem config={route} key={route.title} currentPath={currentPath} />
        })}
      </Collapse>
    </div>
  )
})

const drawerWidth = 302

const primaryColor = '#ffffff'
const secondaryColor = '#ffffff'

const useStyles = makeStyles(theme => ({
  appBar: {
    // zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    backgroundColor: primaryColor
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: 0
  },
  hide: {
    display: 'none'
  },
  fullWidth: {
    width: '100%'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap'
  },
  drawerOpen: {
    width: drawerWidth,
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    backgroundColor: secondaryColor
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: theme.spacing(6) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(8) + 1
    },
    backgroundColor: secondaryColor
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  },
  list: {
    '& a': {
      textDecoration: 'none',
      color: 'inherit'
    },
    '& .MuiListItem-button': {
      color: 'white',
      '& svg': {
        color: 'white'
      }
    },
    '& .MuiListItem-button.active': {
      backgroundColor: 'white',
      color: secondaryColor,
      '& svg': {
        color: secondaryColor
      }
    },
    '& .MuiListItem-button:hover': {
      //backgroundColor: 'white',
      color: secondaryColor,
      '& svg': {
        color: secondaryColor
      }
    },
    '& .MuiCollapse-entered': {
      overflowX: 'hidden',
      overflowY: 'hidden'
    }
  }
}))

export default function PersistentDrawerLeft({routes, path, headerTitle, headerDescription, trialExpired, open, setOpen}) {
  const [openProfile, setOpenProfile] = useState(false)
  const [openMessages, setOpenMessages] = useState(false)
  const [openNotifications, setOpenNotifications] = useState(false)
  const [openSubscription, setOpenSubscription] = useState(false)
  const {signOut, user, refreshUserDetails} = useAppState()
  // console.log("SIDEBAR----USER::",user)
  const {settings = {}} = user
  const {enable_contacts = false} = settings

  const cookies = new Cookies()
  const USER_DATA = cookies.get('cs_user') || ''

  const chosenServices = USER_DATA.chosenServices ? JSON.parse(USER_DATA.chosenServices) : ''
  //const sidebarRoutesss = routes.filter((route) => !!route.icon);
  let sidebarRoutes = routes.filter(route => !!route.icon)

  if (!enable_contacts) {
    sidebarRoutes = sidebarRoutes.filter(route => route.group != 'contact')
  }

  /*const sidenav = () => {
    if (USER_DATA.role !== 'superadmin' && chosenServices !== null) {
      return sidebarRoutesss?.filter((role) => chosenServices?.some(el => el.label === role.title || ((el.label === "WhatsApp") && (role.title === "WhatsApp archives")) || role.title === "Users" || role.title === "Overview" || role.title === "Analytics" || role.title === "Logs" || role.title === "Templates" || role.title === "Contacts" || role.title === "Template" || role.title === "Channels" || role.title === "Pricing"));
    } else if (USER_DATA.role !== 'superadmin' && chosenServices === null) {
      return sidebarRoutesss?.filter((role) => role.title === "Users" || role.title === "Overview" || role.title === "Analytics" || role.title === "Logs" || role.title === "Templates" || role.title === "Contacts" || role.title === "Template" || role.title === "Channels" || role.title === "Pricing");
    } else {
      return sidebarRoutesss;
    }
  }*/

  //const sidebarRoutes = sidenav();

  console.log('sidebarRoutes', sidebarRoutes)

  // const { user, signOut } = useAppState();
  const location = useLocation()

  const theme = useTheme()

  const [menuId, setMenuId] = useState('')

  const classes = useStyles()

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  const closeOpenMenus = e => {
    let classList = e.target.classList
    console.log('classList => ', classList)
    if (
      classList.value !== 'pageHeaderNamesWrapper' &&
      classList.value !== 'companyLogo' &&
      classList.value !== 'profileRole' &&
      classList.value !== 'profileTitle' &&
      classList.value !== 'header-submenu-clickable'
    ) {
      setOpenProfile(false)
    }
  }

  useEffect(() => {
    handleDrawerClose()
    document.addEventListener('mousedown', closeOpenMenus)

    return () => {
      document.removeEventListener('mousedown', closeOpenMenus)
    }
  }, [window.location.href])

  const headerRoutesConfig = [
    {
      path: ' ',
      headerTitle: 'Activity Dashboard',
      headerDescription: 'View all recent application activity.'
    },
    {
      path: 'plans',
      headerTitle: 'Activity Plans',
      headerDescription: 'View all plans'
    },
    // {
    //   path: "Logs",
    //   headerTitle: "User Activity Log",
    //   headerDescription: "View user activity.",
    // },
    {
      path: 'profile',
      headerTitle: 'User Profile',
      headerDescription: 'View user profile.'
    },
    {
      path: 'settings',
      headerTitle: 'Account Settings',
      headerDescription: 'View user settings.'
    },
    {
      path: 'feedback',
      headerTitle: 'Leave Feedback',
      headerDescription: 'Leave user feedback.'
    },
    {
      path: 'support',
      headerTitle: 'Support & Help',
      headerDescription: 'Get support and help.'
    },
    {
      path: 'Channels',
      headerTitle: 'Your Channels',
      headerDescription: 'View Channels.'
    },
    {
      path: 'NewChannel',
      headerTitle: 'Add Channels',
      headerDescription: 'Add a new channel.'
    },
    {
      path: 'SMS',
      headerTitle: 'SMS Management',
      headerDescription: 'SMS Area.'
    },
    {
      path: 'Email',
      headerTitle: 'Email Management',
      headerDescription: 'Email Area.'
    },
    {
      path: 'SMS-Channel',
      headerTitle: 'SMS Channel Management',
      headerDescription: 'SMS Channels.'
    },
    {
      path: 'EmailChannel',
      headerTitle: 'Email Channel Management',
      headerDescription: 'Email Channels.'
    },
    {
      path: 'scheduleSMSChannel',
      headerTitle: 'Schedule SMS Channel'
      // headerDescription: "Add, Edit and View Super Admins and Admins.",
    },
    {
      path: 'sendSMSChannel',
      headerTitle: 'Send SMS'
      // headerDescription: "Add, Edit and View Super Admins and Admins.",
    },
    {
      path: 'scheduleEmailChannel',
      headerTitle: 'Schedule Email'
      // headerDescription: "Add, Edit and View Super Admins and Admins.",
    },
    {
      path: 'sendEmailChannel',
      headerTitle: 'Send Email'
      // headerDescription: "Add, Edit and View Super Admins and Admins.",
    },
    {
      path: 'SMS',
      headerTitle: 'SMS Management',
      headerDescription: 'SMS Area.'
    },
    {
      path: 'whatsapp',
      headerTitle: 'WhatsApp Dashboard',
      headerDescription: 'SMS Area.'
    },
    {
      path: 'whatsapp-archives',
      headerTitle: 'WhatsApp Archives',
      headerDescription: 'SMS Area.'
    },
    {
      path: 'WhatsappUI',
      headerTitle: 'WhatsApp ui'
      // headerDescription: "Add, Edit and View Super Admins and Admins.",
    },
    // {
    //   path: "Chat",
    //   headerTitle: "Web Chat",
    //   // headerDescription: "Add, Edit and View Super Admins and Admins.",
    // },
    {
      path: 'users',
      headerTitle: 'User Management'
      // headerDescription: "Add, Edit and View Super Admins and Admins.",
    },
    {
      path: 'user-edit/',
      headerTitle: 'Edit User'
      // headerDescription: "Add, Edit and View Super Admins and Admins.",
    },
    {
      path: 'user-view/',
      headerTitle: 'View User'
      // headerDescription: "View Super Admins, Admins and Staff User Accounts.",
    },
    {
      path: 'templates',
      headerTitle: 'SMS & Email Templates',
      headerDescription: 'Health and safety laws apply to all employers, self-employed people and employees in their workplaces.'
    },
    {
      path: 'newUser',
      headerTitle: 'Add New User'
      // headerDescription:
      //   "Add and Edit Super Admins, Admins and Staff User Accounts.",
    },
    {
      path: 'sendSMS',
      headerTitle: 'Send Bulk & Single SMS'
      // headerDescription:
      //   "Select the fire safety measures applicable to your business.",
    },
    {
      path: 'scheduleSMS',
      headerTitle: 'Schedule Bulk & Single SMS'
      // headerDescription:
      //   "Select the fire safety measures applicable to your business.",
    },
    {
      path: 'sendEmail',
      headerTitle: 'Send Bulk & Single Email'
      // headerDescription:
      //   "Select the fire safety measures applicable to your business.",
    },
    {
      path: 'scheduleEmail',
      headerTitle: 'Schedule Bulk & Single Emails'
      // headerDescription:
      //   "Select the fire safety measures applicable to your business.",
    },
    {
      path: 'schedule',
      headerTitle: 'Schedule Emails'
      // headerDescription:
      //   "Based on the Principles of Hazard Analysis and Critical Control Point (HACCP)",
    },
    {
      path: 'rams',
      headerTitle: 'Risk Assesment Method Statement(RAMS)'
      // headerDescription:
      //   "Health and safety laws apply to all employers, self-employed people and employees in their workplaces.",
    },
    {
      path: 'analytics',
      headerTitle: 'Analytics',
      headerDescription: 'View all sent campaigns.',
      menuIcon: <BarChartOutlined />
    },
    {
      path: 'campaigns-view/',
      headerTitle: 'View Campaign',
      headerDescription: 'View SMS campaign and sent sms.',
      menuIcon: <BarChartOutlined />
    },
    {
      path: 'email-campaign/',
      headerTitle: 'View Campaign',
      headerDescription: 'View email campaign and sent emails.',
      menuIcon: <BarChartOutlined />
    },
    {
      path: 'voice-channel',
      headerTitle: 'Voice Channel'
    },

    {
      path: 'newtemplate',
      headerTitle: 'Add New Template',
      headerDescription: 'Create default SMS templates.'
    },
    {
      path: 'template-edit/',
      headerTitle: 'Edit Template',
      headerDescription: 'Add, Edit and View Default SMS Templates.'
    },
    {
      path: 'template-view/',
      headerTitle: 'View Template',
      headerDescription: 'Add, Edit and View Default SMS Templates.'
    },
    /* {
       path: "contacts",
       headerTitle: "Contact List",
       headerDescription: "View Contact List.",
     },
     {
       path: "create-contact-list",
       headerTitle: "Contact List",
       headerDescription: "Add, Edit and View Contact List.",
     },
     {
       path: "edit-contact-list/",
       headerTitle: "Contact List",
       headerDescription: "Add, Edit and View Contact List.",
     },
     {
       path: "newContact",
       headerTitle: "Contact",
       headerDescription: "Add, Edit and View Contact.",
     },
     {
       path: "contact-edit/",
       headerTitle: "Contact",
       headerDescription: "View Tasks",
     },*/
    {
      path: 'tassks',
      headerTitle: 'Tasks',
      headerDescription: 'Add, Edit and View Contact.'
    }
  ]
  const filteredCurrentPath = headerRoutesConfig.filter(data => location.pathname.substring(1).startsWith(data.path))

  const currentPath =
    filteredCurrentPath && filteredCurrentPath.length ? filteredCurrentPath : headerRoutesConfig.filter(data => data.path === ' ')

  const handleClick = e => {
    setMenuId(menuId === e ? '' : e)
  }
  let currentPlan = null
  // let currentPlan = monthlyPaymentArray.filter(item => item.priceId === (USER_DATA.subscription_id ? USER_DATA.subscription_id : USER_DATA.trial.split("-")[1]))
  // currentPlan = currentPlan.length ? currentPlan[0] : null
  return (
    <Box sx={{display: 'flex'}}>
      <CssBaseline />

      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open
        })}
      >
        <Toolbar>
          <Typography variant="h6" noWrap component="div">
            <div className="pageHeaderWrapper">
              <div className="pageHeaderText">
                <span className="pageHeaderTitleinner">{currentPath[0].headerTitle}</span>
              </div>
              <div className="headerRightSide">
                {/* {user.role !== "superadmin" && <Link to="/plans">
                  <p className="upgradebtn">{currentPlan && (USER_DATA.subscription_id ? currentPlan.name : `${currentPlan.name}: Free ( Upgrade Now )`)}
                  </p>
                </Link>} */}
                {/* <img
                  src={notificationsbell}
                  alt=""
                  width="auto"
                  height="24px"
                  onClick={() => {
                    if (!trialExpired) {
                      setOpenNotifications((prev) => !prev);
                      setOpenMessages(false);
                      setOpenProfile(false)
                    }
                  }} className="sidebarIcon notifications" />
                {
                  openNotifications && <HeaderNotifications />
                }
                <img
                  src={messagesbell}
                  alt=""
                  width="auto"
                  height="24px"
                  onClick={() => {
                    if (!trialExpired) {
                      setOpenMessages((prev) => !prev);
                      setOpenNotifications(false);
                      setOpenProfile(false)
                    }
                  }} className="sidebarIcon messages" />
                {
                  openMessages && <HeaderMessages />
                } */}
                <div
                  className="pageHeaderNamesWrapper"
                  onClick={() => {
                    if (!trialExpired) {
                      setOpenProfile(prev => !prev)
                      setOpenNotifications(false)
                      setOpenMessages(false)
                    }
                  }}
                >
                  {/* <Avatar scr={user.avatar} className="profileavatar" /> */}
                  <img src={companyLogo} className="companyLogo"></img>

                  <div className="profileNames">
                    <span className="profileTitle">{user.fullName}</span>
                    <span className="profileRole">{user.role}</span>
                  </div>
                  {openProfile && <Headermenus />}
                </div>
              </div>
            </div>
          </Typography>
        </Toolbar>
      </AppBar>

      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open
          })
        }}
      >
        <div className={classes.toolbar}>
          <img src={logo} alt="" width="auto" height="70px" className="sidebarlogo" style={!open ? {visibility: 'hidden'} : {}} />

          {open && (
            <>
              <IconButton onClick={handleDrawerClose}>{theme.direction === 'rtl' ? <Close /> : <Close />}</IconButton>
            </>
          )}

          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
              [classes.fullWidth]: !open
            })}
          >
            <img src={icon} alt="" width="auto" height="40px" className="kicon" />
          </IconButton>
        </div>

        <List className={classes.list + ' main-sidebar'}>
          {sidebarRoutes.map((route, index) => {
            if (Array.isArray(route.items) && route.items.length > 0) {
              return (
                <ExpandableMenuItem
                  config={route}
                  key={route.title}
                  currentPath={location.pathname}
                  menuId={menuId}
                  handleClick={e => handleClick(route.id)}
                />
              )
            } else {
              return <MenuItem config={route} key={route.title} currentPath={location.pathname} />
            }
          })}

          {/* <div className="MuiButtonBase-root MuiListItem-root MuiListItem-gutters MuiListItem-button filter-red" tabindex="0" role="button" aria-disabled="false" onClick={signOut} id="logoutid">
            <div className="MuiListItemIcon-root filter-red">
              <img src={logoutIcon} className="filter-red" />
            </div><span className="MuiTouchRipple-root"></span></div> */}

          {open && process.env.REACT_APP_REPUTATION && (
            <div className="infobar">
              <span className="info">Reputation</span>
              <span className="count">{process.env.REACT_APP_REPUTATION}%</span>
              <BorderLinearProgress variant="determinate" value={process.env.REACT_APP_REPUTATION} />
            </div>
          )}
        </List>
      </Drawer>
    </Box>
  )
}
