import './importcontacts.css'
import axios from '../../../../helpers/request'
import {useAppState} from '../../../../state'

import React, {useState, useEffect, Fragment} from 'react'
import {Grid} from '@mui/material'

import Swal from 'sweetalert2'

// Import as a module in your JS
import 'react-bootstrap-typeahead/css/Typeahead.css'
import {Typeahead, Menu, MenuItem, Highlighter} from 'react-bootstrap-typeahead'

export default function SelectContactList(props) {
  const [contactList, setContactList] = useState([])
  const [allContacts, setAllContacts] = useState([])
  const [parentTypes, setParentTypes] = useState([])
  const [parentList, setParentList] = useState([])
  const [filteredParentList, setFilteredParentList] = useState([])
  const [multiSelections, setMultiSelections] = useState([])
  const {user: loggedUser} = useAppState()
  const companyId = loggedUser.id

  const dropdownProps = {}
  dropdownProps.renderMenu = ([], {newSelectionPrefix, paginationText, renderMenuItemChildren, ...menuProps}, state) => {
    let index = 0
    const items = parentTypes.map(typeName => (
      <Fragment key={typeName}>
        {index !== 0 && <Menu.Divider />}
        <Menu.Header onClick={handleTypeClick} data-typename={typeName}>
          {typeName}
        </Menu.Header>
        {filteredParentList[typeName].map(i => {
          const item = (
            <MenuItem key={index} option={i} position={index}>
              <Highlighter search={state.text}>{i.name}</Highlighter>
            </MenuItem>
          )
          index += 1
          return item
        })}
      </Fragment>
    ))

    return <Menu {...menuProps}>{items}</Menu>
  }

  const handleOnSubmit = e => {
    e.preventDefault()
    if (!multiSelections.length) {
      Swal.fire({title: 'Select min one contact list', confirmButtonColor: '#3085d6'})
      return false
    }
    let selectedIDs = multiSelections.map(function (ele) {
      return ele.id
    })
    const contacts = allContacts.filter(ele => selectedIDs.includes(ele.listId))
    props.getUploadedContactData(contacts)
  }

  // Get table colms from db to display while edit table header
  const getAllContactList = () => {
    axios
      .get(`/get-all-contact-list/${companyId}`)
      .then(res => {
        let templist = []
        let templistIds = []
        let tempParentList = []
        let tempParentTypes = []

        let allData = res.data.filter(item => {
          if (props.componentCalledSource == 'emailChannel') {
            return item.list_type == 'email' || item.list_type == 'sms_email'
          } else if (props.componentCalledSource == 'smsChannel') {
            return item.list_type == 'sms' || item.list_type == 'sms_email'
          } else {
            return item.list_type == 'email' || item.list_type == 'sms' || item.list_type == 'sms_email'
          }
        })
        setAllContacts(allData)
        allData.map(item => {
          if (item.listId) {
            if (!templistIds.includes(item.listId)) {
              templistIds.push(item.listId)
              templist.push({id: item.listId, name: item.list_name})
              if (!tempParentTypes.includes(item.parent_type)) {
                tempParentList[item.parent_type] = []
              }
              let keyIndex = tempParentList[item.parent_type].length
              tempParentList[item.parent_type][keyIndex] = {id: item.listId, name: item.list_name}
              if (!tempParentTypes.includes(item.parent_type)) {
                tempParentTypes[tempParentTypes.length] = item.parent_type
              }
            }
          }
        })
        setFilteredParentList(tempParentList)
        setParentList(tempParentList)
        setContactList(templist)
        setParentTypes(tempParentTypes)
      })
      .catch(err => {
        console.error('errr', err)
      })
  }

  useEffect(() => {
    if (props.componentCalledSource !== 'contactList') {
      props.setUpdateModalSize(false)
    }
    getAllContactList()
  }, [props.browseFileData])

  const contactListChanged = event => {
    let newlist = []
    event.map(e => newlist.push(e))
    setMultiSelections(newlist)
    let tempSelectedIDs = newlist.map(value => {
      return value.id
    })

    let tempParentList = []
    let tempParentTypes = []
    let addedIdInList = []
    allContacts.map(item => {
      if (!tempSelectedIDs.includes(item.listId) && !addedIdInList.includes(item.listId)) {
        if (!tempParentTypes.includes(item.parent_type)) {
          tempParentList[item.parent_type] = []
        }
        let keyIndex = tempParentList[item.parent_type].length
        tempParentList[item.parent_type][keyIndex] = {id: item.listId, name: item.list_name}
        if (!tempParentTypes.includes(item.parent_type)) {
          tempParentTypes[tempParentTypes.length] = item.parent_type
        }
        addedIdInList.push(item.listId)
      }
    })
    setFilteredParentList(tempParentList)
    setParentTypes(tempParentTypes)
  }

  const handleTypeClick = async event => {
    let clickedtype = event.target.dataset.typename
    let tempMultiSelections = multiSelections
    let tempParentList = []
    let tempParentTypes = []
    let addedIdInList = []

    allContacts.map(item => {
      let tempSelectedIDs = tempMultiSelections.map(value => value.id)
      if (item.parent_type === clickedtype && !tempSelectedIDs.includes(item.listId)) {
        tempMultiSelections.push({id: item.listId, name: item.list_name})
      }
    })

    let tempSelectedIDs = tempMultiSelections.map(value => value.id)
    allContacts.map(item => {
      if (!tempSelectedIDs.includes(item.listId) && !addedIdInList.includes(item.listId)) {
        if (!tempParentTypes.includes(item.parent_type)) {
          tempParentList[item.parent_type] = []
        }
        let keyIndex = tempParentList[item.parent_type].length
        tempParentList[item.parent_type][keyIndex] = {id: item.listId, name: item.list_name}
        if (!tempParentTypes.includes(item.parent_type)) {
          tempParentTypes[tempParentTypes.length] = item.parent_type
        }
        addedIdInList.push(item.listId)
      }
    })
    setFilteredParentList(tempParentList)
    setParentTypes(tempParentTypes)
  }

  return (
    <>
      <span className="import-contact-header">Select contact lists</span>

      <Grid container className="mt-20">
        <Grid item xs={12} md={12} lg={12}>
          {/*<Typeahead
                        id="basic-typeahead-multiple"
                        labelKey="name"
                        multiple
                        onChange={setMultiSelections}
                        options={contactList}
                        placeholder="Choose options"
                        selected={multiSelections}
                    />*/}

          <Typeahead
            {...dropdownProps}
            id="rendering-example"
            labelKey="name"
            options={filteredParentList}
            placeholder="Choose options..."
            multiple
            onChange={contactListChanged}
            selected={multiSelections}
          />
        </Grid>
      </Grid>

      <button onClick={e => handleOnSubmit(e)} className="btn btn-primary text-white br-10 importsubmit">
        Submit
      </button>

      <button onClick={() => props.handleBackClick()} className="btn btn-white-red br-10 importsubmit ml-20">
        Back
      </button>
    </>
  )
}
